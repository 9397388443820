import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InputAdornment from '@mui/material/InputAdornment';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { format, parseISO } from 'date-fns';
import { getFormatedCurrency, RupeeSymbol } from '../components/util';

import { useLocation, useNavigate } from 'react-router-dom';
import { fetchOrderItemStatus, fetchOrderStatus } from '../store/actions/quotation';
import { fetchDeliveryReqDetail,updateDlryReqStatus, fetchDlryReqList,fetchDeliveryRequestStatus  } from '../store/actions/deliveryChallan';



export default function EditDeliveryRequestPage(props) {

  const [values, setValues] = useState({
    // SendPushNotification: false,
    // showcomment: true,
    DlryReqDetail: { DeliveryRequestItems: [], OrderItems: [] },
  });

  // const orderDetail = useSelector(state => (state.quotation.orderDetail || []));
  // const orderStatus = useSelector(state => (state.quotation.orderStatus || []));
  // const pmntMethods = useSelector(state => (state.quotation.paymentMethods || []));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const location = useLocation();
  const [deleteCommentId, setdeleteCommentId] = useState("");
  const [Commentdialog, setCommentdialog] = useState(false);


  const dlryReqDetail = useSelector(state => (state.deliveryChallan.deliveryRequestDetail || []));
  const DeliveryRequestStatus = useSelector(state => (state.deliveryChallan.DeliveryRequestStatus || []));



  // console.log("initial values");
  // console.log(values.DlryReqDetail);
  // console.log("initial status");
  // console.log(orderItemStatus);


  const mount = () => {
    // dispatch(fetchPaymentMethods());s
    // dispatch(fetchOrderDetail(location.state.orders.OrderId));
    dispatch(fetchDeliveryReqDetail(location.state.dlryReq));
    dispatch(fetchDeliveryRequestStatus());
    dispatch(updateDlryReqStatus());

  }
  // console.log("idddddddddddddd");
  // console.log(location.state.dlryReq);

  useLayoutEffect(mount, []);

  useEffect(() => {
    setValues({
      ...values,
      DlryReqDetail: { ...dlryReqDetail }
    });
  }, [dlryReqDetail]);

  // useEffect( () =>{

  //   if(location.state && location.state.orders){
  //     setValues({
  //       Id: (location.state.orders && location.state.orders.OrderId) || "",
  //       PONumber: (location.state.orders && location.state.orders.PONumber) || "",
  //       Status: (location.state.orders && location.state.orders.Status) || "",
  //     });
  //   }
  // }, [location]);

  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };

  // const handleSelectChange = (fieldname) => (event) => {
  //   if(fieldname === "Status"){
  //     setValues({...values, Status: event.target.value });
  //   }
  // };

  const handleSelectChange = (event) => {
    var dlryReq = values.DlryReqDetail;
    // console.log("before oder");
    // console.log(JSON.stringify(odr));
    dlryReq[event.target.name] = event.target.value;
    // console.log("updated values of oder");
    // console.log(JSON.stringify(odr));
    setValues({ ...values, DlryReqDetail: dlryReq });
    setErrorMsg(null);
  };



  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrorMsg(null);
  };

  const EditDeliveryRequestItem = (id) => {
    // var qtd = values.OrderDetail;
    // var itm = values.OrderDetail.QuotationItems[id];
    navigate("/editDeliveryRequestItem", { state: { DeliveryReqItemId: id } })
    // console.log("Idddddd");
    // console.log(id);
  }

  const handleSubItemDetail = (id) => {
    navigate("/editSubOrderItem", { state: { SubOrderId: id } })
  }

  // const handleSubmit = () => {
  //   var Order = {
  //     Id: values.OrderDetail.OrderId,
  //     Status: values.OrderDetail.Status,
  //     PONumber: values.OrderDetail.PONumber,
  //   }; 
  //   dispatch(updateOrder(Order)).then(r => dispatch(fetchAllOrders()));
  //   navigate("/orders");
  // }

  /*  const handleDeliveryChallan = () => {
     navigate("/deliveryChallan");
   }
 
   const handleInvoice = () => {
     navigate("/invoice");
   }
 
   const onTabViewClick = (selected) => {
     setValues({ ...values, showcomment: !values.showcomment })
   } */

  const GenerateOrderItem = () => {
    console.log(" enter in order item generate")
  }

  const UpdateDeliveryRequest = () => {
    var DlryReqest = {
      DeliveryRequestId: values.DlryReqDetail.Id,
      // DeliveryPONumber: values.OrderDetail.PONumber,
      Status: values.DlryReqDetail.Status,
    };
    dispatch(updateDlryReqStatus(DlryReqest)).then(r => dispatch(fetchDlryReqList()));
    // dispatch(updateOrder(Order)).then(r => dispatch(fetchAllOrders()));

    navigate("/deliveryRequest");
  }


  // console.log("status value");
  // console.log(dlryReqDetail.Status);

  return (
    <div>
      <Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardHeader title="Edit Delivery Request" />
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: 18 }}>
            <Button variant='contained' color="error" onClick={() => { navigate("/deliveryRequest") }}>Back</Button>
          </div>
        </div>
        <Divider />

        <CardContent>
          <div style={{ display: "flex", fontSize: 22, fontWeight: "bold", color: "#F7D200" }}>
            <CardHeader title="Customer Detail" />
          </div>
          <Grid container style={{ marginBottom: 20 }}>
            <Grid item xs={6} spacing={4}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Name: </Typography>
                <Typography variant="subtitle1" ml={2}>{(dlryReqDetail.CustomerFirstName) + " " + (dlryReqDetail.CustomerLastName)}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Email: </Typography>
                <Typography variant="subtitle1" ml={2}>{dlryReqDetail.CustomerEmail}</Typography>
              </div>
            </Grid>

            <Grid item xs={6} spacing={4}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Mobile: </Typography>
                <Typography variant="subtitle1" ml={2}>{dlryReqDetail.CustomerMobile}</Typography>
              </div>
            </Grid>
          </Grid>

          <Divider />
          <Divider orientation={'vertical'} />

          <div style={{ display: "flex", fontSize: 22, fontWeight: "bold", color: "#F7D200", marginTop: 10 }}>
            <CardHeader title="Delivery Request Detail" />
          </div>

          <Grid container style={{ marginBottom: 10 }}>

            <Grid item xs={6} spacing={4}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Delivery Request Date and Time: </Typography>
                <Typography variant="subtitle1" ml={2}>{(dlryReqDetail.DeliveryRequestDate) ? format(parseISO(dlryReqDetail.DeliveryRequestDate), "dd MMM yyyy 'at' hh:mm a") : null}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Delivery Expected Date: </Typography>
                <Typography variant="subtitle1" ml={2}>{(dlryReqDetail.DeliveryExpectedDate) ? format(parseISO(dlryReqDetail.DeliveryExpectedDate), "dd MMM yyyy 'at' hh:mm a") : null}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Total Amount: </Typography>
                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(dlryReqDetail.TotalAmount)}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Total Items: </Typography>
                <Typography variant="subtitle1" ml={2}>{dlryReqDetail.TotalDeliveryItem}</Typography>
              </div>
              {/* <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"space-between", marginTop:-8}}>
                  <Button variant='contained' color='secondary'>Generate invoice</Button>
                  <Button variant='contained' color='secondary'>Generate Delivery challan</Button>
              </div> */}
            </Grid>

            <Grid item xs={6} spacing={4}>
              <div className="div-infocontainer">
                <FormControl required sx={{ width: "100%" }}>
                  <InputLabel id="demo-controlled-open-select-label">Delivery Request Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={values && values.DlryReqDetail && values.DlryReqDetail.Status ? values.DlryReqDetail.Status : ""}
                    label="Delivery Request Status"
                    name="Status"
                    onChange={(e) => handleSelectChange(e)}
                    MenuProps={MenuProps}
                  >
                    {DeliveryRequestStatus.map((s, i) => {
                      return <MenuItem value={s.Name} key={i}>{s.Name}</MenuItem >
                    })}
                  </Select>
                </FormControl>
              </div>

              <div className="div-infocontainer" style={{ marginTop: 20 }} >
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  // required 
                  fullWidth label="Delivery PO Number" value={dlryReqDetail && dlryReqDetail.DeliveryPONumber ? dlryReqDetail.DeliveryPONumber : ""} name="DeliveryPONumber" onChange={(e) => handleSelectChange(e)} />
              </div>
            </Grid>
          </Grid>

        </CardContent>


        <Grid container>
          {((values && values.DlryReqDetail && values.DlryReqDetail.DeliveryRequestItems) || []).map((d, i) => {
            return (
              <div key={i} style={{ borderRadius: 5, backgroundColor: (d.OrderItemStatus === "CANLD" ? "#F0807D" : "#D9D9D9"), width: "100%", paddingInline: 15, margin: 20, marginTop: 0, borderWidth: 1.5, borderColor: "orange", borderStyle: 'solid' }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: 11, paddingBottom: 11 }} /*onClick={handleCommentShow} */ /* onClick={(e) => onTabViewClick("CmntClicked", e)} */  >
                  <Grid item xs={3.5} style={{ fontSize: 16, fontWeight: "bold", paddingTop: 5, paddingBottom: 5 }}>
                    <Typography variant="subtitle">{d.Product.ProductName + " - " + d.Product.BrandName + " (" + d.Product.ProductSize + ")"} </Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={1.8} style={{ textAlign: "center" }}>
                    <Typography variant="subtitle2">Amount: {getFormatedCurrency(d.DeliveryRequestQuantity * d.QuotationPrice)}</Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={1.8} style={{ textAlign: "center" }}>
                    <Typography variant="subtitle2">Quantity: {d.DeliveryRequestQuantity + " (" + d.Product.ProductSize + ")"}</Typography>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={1.8} style={{ textAlign: "center" }}>
                    <Typography variant="subtitle2">Status: {d.Status}</Typography>
                  </Grid>
                  {/* <Divider orientation="vertical" flexItem />
                    <Grid item xs={1.8} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">{d.OrderItemStatus != "CANLD" ? "Pending: " : "Cancelled: " }{d.Quantity-d.DispatchQuantity}</Typography>
                    </Grid> */}
                  {/* {(d.OrderItemStatus != "CANLD" && d.Quantity-d.DispatchQuantity > 0 ) ? */}
                  <div style={{ justifyContent: "flex-end" }}>
                    <Button variant='contained' color='secondary' onClick={() => EditDeliveryRequestItem(d.DeliveryRequestItemId)}>Process <ChevronRightIcon style={{ height: 25, width: 25 }} /></Button>
                  </div>
                  {/* : null} */}
                </div>










                {((values && values.DlryReqDetail && values.DlryReqDetail.OrderItems) || []).filter(f => f.DeliveryRequestItemId === d.DeliveryRequestItemId).map((o, j) => {
                  return (
                    <div key={j} style={{ width: "100%", backgroundColor: "white", marginBottom: 15, borderRadius: 5, }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <CardHeader title={o.Product.ProductName} />
                        {(d.OrderItemStatus != "CANLD") ?
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                              marginRight: 18,
                              justifyContent: "flex-end"
                            }} >
                            <Button variant='contained' color="secondary" onClick={() => handleSubItemDetail(o.OrderItemId)}>Edit</Button>
                          </div>
                          : null}
                      </div>
                      <Divider />
                      <Grid container spacing={2} display="flex" style={{ paddingBottom: 11, paddingRight: 20, paddingLeft: 20 }}>
                        <Grid item xs={12} spacing={2} container style={{ marginTop: 5, alignItems:"center" }}>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Quotation Price: </Typography>
                              <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.QuotationPrice)}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Our Price: </Typography>
                              <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.OrderItemPrice)}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Order Quantity: </Typography>
                              <Typography variant="subtitle1" ml={2}>{o.OrderQuantity}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Vendor Price: </Typography>
                              <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.VendorPrice)}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Vendor Name: </Typography>
                              <Typography variant="subtitle1" ml={2}>{o.VendorName}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Delivery Time: </Typography>
                              <Typography variant="subtitle1" ml={2}>{/* {(o.DeliveryDateTime) ? format(parseISO(o.DeliveryDateTime), "dd MMM yyyy 'at' hh:mm a") : null} */}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Status: </Typography>
                                <Typography variant="subtitle1" ml={2}>{o.Status}</Typography>
                              </div>
                            </Grid>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Discount: </Typography>
                              <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.Discount)}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Service Charges: </Typography>
                              <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.ServiceCharge)}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Delivery Charges: </Typography>
                              <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.DeliveryCharge)}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div className="div-infocontainer">
                              <Typography variant="subtitle2">Transportation: </Typography>
                              <Typography variant="subtitle1" ml={2}>{o.Transportation}</Typography>
                            </div>
                          </Grid>
                          {(o.DriverName) ?
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Driver Name: </Typography>
                                <Typography variant="subtitle1" ml={2}>{o.DriverName}</Typography>
                              </div>
                            </Grid>
                            : null}

                          {(o.DriverContact) ?
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Driver Contact: </Typography>
                                <Typography variant="subtitle1" ml={2}>{o.DriverContact}</Typography>
                              </div>
                            </Grid>
                            : null}

                          {(o.VehicleNumber) ?
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Vehicle Number: </Typography>
                                <Typography variant="subtitle1" ml={2}>{o.VehicleNumber}</Typography>
                              </div>
                            </Grid>
                            : null}
                        </Grid>
                      </Grid>
                    </div>
                  )
                })}






























              </div>
            )
          })}
        </Grid>






        <Divider />
        <div style={{ textAlign: "center", padding: 16 }}>
          <Button variant='contained' color='secondary' onClick={UpdateDeliveryRequest}>Update Delivery Request</Button>
        </div>
        {/* <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"space-between"}}>
          <Button variant='contained' color='secondary' onClick={GenerateOrderItem}>Generate Order Item</Button>
        
          <Button variant='contained' color='secondary' onClick={UpdateDeliveryRequest}>Update Delivery Request</Button>
        </div> */}
      </Card>











    </div>
  );
}