import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export default function ViewVendorPage(props) {

  const mount = () => {
    //dispatch(fetchProducts({}));
  }
  // eslint-disable-next-line
  useEffect(mount, []);

  
  return (    
    <div>
      <Card>
        <CardHeader title="View Vendor" />
        <Divider />
        <CardContent>
          <Typography variant="body2">
            Page Content
          </Typography>
        </CardContent>        
      </Card>
    </div>
  );
}