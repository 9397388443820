import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { format, parseISO } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchInvoiceDetail, fetchInvoiceStatus, updateInvoice } from '../store/actions/invoice';
import { getFormatedCurrency } from '../components/util';

export default function InvoiceDetailPage(props) {

  const [values, setValues] = useState({
    Comment:"",
    InvoiceDetail: { InvoiceItems: []},
  });

  // const isInvoiceDetailFetching = useSelector(state => (state.invoice.isInvoiceDetailFetching || []));
  const invoiceDetail = useSelector(state => (state.invoice.invoiceDetail || []));
  const invoiceStatus = useSelector(state => (state.invoice.invoiceStatus || []));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const customer = invoiceDetail && invoiceDetail.CustomerDetails ? invoiceDetail.CustomerDetails : {};


  const mount = () => {
    dispatch(fetchInvoiceDetail(location.state.invoiceId));
    dispatch(fetchInvoiceStatus());
  }
  // eslint-disable-next-line
  useLayoutEffect(mount, []);

  useEffect(() => {
    setValues({
      ...values,
      InvoiceDetail: { ...invoiceDetail }
    });
  }, [invoiceDetail]);


  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };

  const handleSelectChange = (event) => {
    var invoiceDtl = values.InvoiceDetail;
    invoiceDtl[event.target.name] = event.target.value;
    setValues({ ...values, InvoiceDetail: invoiceDtl });
  };

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
  };

  const UpdateInvoice = () => {
    var InvoiceParams = {
      Id: values.InvoiceDetail.Id,
      Status: values.InvoiceDetail.Status,
      Comment: values.Comment,
    };
    // console.log("updated values for invoice");
    // console.log(InvoiceParams);
    dispatch(updateInvoice(InvoiceParams))/* .then(r => dispatch(fetchInvoiceDetail(location.state.invoiceId))) */;
    navigate("/invoice");
  }

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Invoice Detail" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/invoice")}} >Back</Button>
          </div>
        </div>
        <Divider />


        <CardContent>
          <Grid container style={{marginBottom:20, display:"flex", justifyContent:"space-around"}}>
            <Grid item xs={5.5} spacing={4}>
              <div style={{ fontSize: 22, fontWeight:"bold", color:"#F7D200"}}>
                <CardHeader title="Customer Detail" />
              </div>
              <Grid item xs={12} spacing={4}>
                <div className="div-infocontainer">
                  <Typography variant="subtitle2">Customer Name: </Typography>
                  <Typography variant="subtitle1" ml={2}>{customer.FirstName +" "+ customer.LastName}</Typography>
                </div>
                <div className="div-infocontainer">
                  <Typography variant="subtitle2">Customer Email: </Typography>
                  <Typography variant="subtitle1" ml={2}>{customer.Email}</Typography>
                </div>
                <div className="div-infocontainer">
                  <Typography variant="subtitle2">Customer Mobile: </Typography>
                  <Typography variant="subtitle1" ml={2}>{customer.Mobile}</Typography>
                </div>
              </Grid>
            </Grid>

            <Divider orientation="vertical" flexItem />

            <Grid item xs={5.5} spacing={4}>              
              <div style={{ fontSize: 22, fontWeight:"bold", color:"#F7D200"}}>
                <CardHeader title="Invoice Detail" />
              </div>
              <Grid item xs={12} spacing={4}>
                <div className="div-infocontainer">
                  <Typography variant="subtitle2">Invoice No.: </Typography>
                  <Typography variant="subtitle1" ml={2}>{invoiceDetail.InvoiceNo}</Typography>
                </div>                   
                <div className="div-infocontainer">
                  <Typography variant="subtitle2">Invoice Date and Time: </Typography>
                  <Typography variant="subtitle1" ml={2}>{(invoiceDetail.InvoiceDate) ? format(parseISO(invoiceDetail.InvoiceDate), "dd MMM yyyy 'at' hh:mm a") : null}</Typography>
                </div> 
                  <div className="div-infocontainer">
                  <Typography variant="subtitle2">Status: </Typography>
                  <Typography variant="subtitle1" ml={2}>{invoiceDetail.Status + " (" + invoiceDetail.Comment + ") "}</Typography>
                </div>
                <div className="div-infocontainer">
                  <Typography variant="subtitle2">Total: </Typography>
                  <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(invoiceDetail.TotalAmount)}</Typography>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} spacing={4} style={{ paddingInline:15, marginTop:20}}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Billing Address: </Typography>
                <Typography variant="subtitle1" ml={2}>{invoiceDetail.BillingAddress}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Delivery Address: </Typography>
                <Typography variant="subtitle1" ml={2}>{invoiceDetail.DeliveryAddress}</Typography>
              </div>
            </Grid>
          </Grid>

          <Grid container style={{marginBottom:10}}> 
          <Grid item xs={6} spacing={4}>             
                  <div className="div-infocontainer">
                    <FormControl required sx={{ width: "100%" }}>
                      <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={values && values.InvoiceDetail && values.InvoiceDetail.Status ? values.InvoiceDetail.Status : ""}
                        label="Status"
                        name= "Status"
                        onChange={(e) => handleSelectChange(e)}
                        MenuProps={MenuProps}
                      >
                        {invoiceStatus.map((s, i) => {
                           return <MenuItem value={s.Name} key={i}>{s.Name}</MenuItem >
                        })}
                      </Select>
                    </FormControl>
                  </div>  
                </Grid>
              
                <Grid item xs={6} spacing={4}>             
                <div className="div-infocontainer" >
                    <TextField 
                      // required                    
                      fullWidth label="Add Comment" value={values.Comment} name="Comment" onChange={handleChange} 
                    />
                  </div>
                </Grid>
                </Grid>













          {(invoiceDetail && invoiceDetail.InvoiceItems && invoiceDetail.InvoiceItems.length > 0) ?
          <div>
            {/* <Divider /> */}

            <div style={{ display:"flex", fontSize: 22, fontWeight:"bold", color:"#F7D200"}}>
              <CardHeader title="Invoice Items" />
            </div>
          </div>
          : null}

          <Grid container>
            {((invoiceDetail && invoiceDetail.InvoiceItems) || []) .map((d, i) => {
              return  (                   
                <div key={i} style={{display:"flex", alignItems:"center", justifyContent:"space-between", paddingTop:11, paddingBottom:11, borderRadius:5 ,backgroundColor:"#D9D9D9", width:"100%", paddingInline:15, borderWidth:1, borderColor:"Gray", borderStyle:'solid', marginTop:10}} >
                  <Grid item xs={4} style={{fontSize: 16, fontWeight:"bold", paddingTop:5, paddingBottom: 5}}>
                      <Typography variant="subtitle">{d.Product.ProductName + " - " + d.Product.BrandName + " (" + d.Product.ProductSize + ")"} </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={2.7} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Item Price: {getFormatedCurrency(d.OrderItemPrice)}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={2.7} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Item Quantity: {d.OrderQuantity}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={2.7} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Total Amount: {getFormatedCurrency(d.ItemTotal)}</Typography>
                    </Grid>
                </div>
              )
              })}
            </Grid>
        </CardContent>
        <Divider />
       {/*  <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"center"}}>
          <a href={'https://ecsappapi.greenstoneent.com/api/ecsapp/invoice/generateinvoice/' + invoiceDetail.Id}>
            <Button variant='contained' color='secondary'>Download Invoice</Button>
          </a>
        </div> */}

        <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"space-between"}}>
        {/* <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"center"}}> */}
          <a href={'https://ecsappapi.greenstoneent.com/api/ecsapp/invoice/generateinvoice/' + invoiceDetail.Id}>
            <Button variant='contained' color='secondary'>Download Invoice</Button>
          </a>
        {/* </div> */}
          <Button variant='contained' color='secondary' onClick={UpdateInvoice}>Update Invoice</Button>
        </div>
      </Card>
    </div>
  );
}







