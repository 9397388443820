import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';


export default function NotificationPage(props) {

  const mount = () => {
  }
  useEffect(mount, []);

  
  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Notifications" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" /* onClick={() => { navigate("/serviceLocations") }} */>Back</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <Typography variant="body2">
            Page Content
          </Typography>
        </CardContent>        
      </Card>
    </div>
  );
}