import { 
    ALL_TRANSPORTERS_LIST_REQUEST, ALL_TRANSPORTERS_LIST_SUCCESS, ALL_TRANSPORTERS_LIST_FAILURE,
    DRIVERS_LIST_REQUEST, DRIVERS_LIST_SUCCESS, DRIVERS_LIST_FAILURE,
    VEHICLE_LIST_REQUEST, VEHICLE_LIST_SUCCESS, VEHICLE_LIST_FAILURE
} from '../actions/transporter';

export default (
    state = {
        error: undefined,
        isAllTransporterListFetching: false,
        isDriversFetching: false,
        isVehicleFaching: false,
       
        allTransporters: [],
        drivers: [],
        vehicles: [],
      
    },
    action
) => {
    switch (action.type) {

        /* All Transporter list */
        case ALL_TRANSPORTERS_LIST_REQUEST:
            return{
                ...state,
                isAllTransporterListFetching: true,
            };
        case ALL_TRANSPORTERS_LIST_SUCCESS:
            return{
                ...state,
                isAllTransporterListFetching: false,
                allTransporters: action.response,
                error: undefined
            };
        case ALL_TRANSPORTERS_LIST_FAILURE:
            return{
                ...state,
                isAllTransporterListFetching: false,
                error: action.error
            };

        /* Drivers list */
        case DRIVERS_LIST_REQUEST:
            return{
                ...state,
                isDriversFetching: true,
            };
        case DRIVERS_LIST_SUCCESS:
            console.log("sucess");
            console.log(action.response);
            return{
                ...state,
                isDriversFetching: false,
                drivers: action.response,
                error: undefined
            };
        case DRIVERS_LIST_FAILURE:
            return{
                ...state,
                isDriversFetching: false,
                error: action.error
            };
    
        /* Vehicles list */
        case VEHICLE_LIST_REQUEST:
            return{
                ...state,
                isVehicleFaching: true,
            };
        case VEHICLE_LIST_SUCCESS:
            return{
                ...state,
                isVehicleFaching: false,
                vehicles: action.response,
                error: undefined
            };
        case VEHICLE_LIST_FAILURE:
            return{
                ...state,
                isVehicleFaching: false,
                error: action.error
            };   

        default:
            return state; 
        }
    }