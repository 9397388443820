import { CALL_API } from '../api';

export const FETCHCUSTOMER_REQUEST = "FETCHCUSTOMER_REQUEST";
export const FETCHCUSTOMER_SUCCESS = "FETCHCUSTOMER_SUCCESS";
export const FETCHCUSTOMER_FAILURE = "FETCHCUSTOMER_FAILURE";

export const DELETECUSTOMER_REQUEST = "DELETECUSTOMER_REQUEST";
export const DELETECUSTOMER_SUCCESS = "DELETECUSTOMER_SUCCESS";
export const DELETECUSTOMER_FAILURE = "DELETECUSTOMER_FAILURE";

export const CUSTOMER_ADDRESS_REQUEST = "CUSTOMER_ADDRESS_REQUEST";
export const CUSTOMER_ADDRESS_SUCCESS = "CUSTOMER_ADDRESS_SUCCESS";
export const CUSTOMER_ADDRESS_FAILURE = "CUSTOMER_ADDRESS_FAILURE";

export const fetchCustomers = () => ({
    [CALL_API]: {
        types: [FETCHCUSTOMER_REQUEST, FETCHCUSTOMER_SUCCESS, FETCHCUSTOMER_FAILURE],
        method: 'GET',
        endpoint: 'customers/getcustomerlist'
    }
});

export const deleteCustomer = (id) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
          types: [DELETECUSTOMER_REQUEST, DELETECUSTOMER_SUCCESS, DELETECUSTOMER_FAILURE],
          method: 'GET',
          endpoint: 'customers/deletecustomer/' + id
      }
    });
};

export const fetchCustomerAddress = (custId) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
          types: [CUSTOMER_ADDRESS_REQUEST, CUSTOMER_ADDRESS_SUCCESS, CUSTOMER_ADDRESS_FAILURE],
          method: 'GET',
          endpoint: 'customers/getaddresslistbycustid/' + custId
      }
    });
};