import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { fetchVendors, deleteVendor } from '../store/actions/vendor'; 
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';


export default function BrandsPage(props) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteVendorId, setdeleteVendorId] = useState("");
  const vendors = useSelector(state => (state.vendor.vendors || []));
  const isVendorFetching = useSelector(state => (state.vendor.isVendorFetching ));
  
  const mount = () => {
    dispatch(fetchVendors());

  }
  useEffect(mount, []);

  // const handleEditClick = (id) => {
  //   var fltr_brand = Object.assign({}, ...brands.filter(b => { if(b.Id === id) return b; }));
  //   navigate("/editBrand", { state: { brand: fltr_brand }} );
  // };

  const handleDeleteClick = (id) => {
    console.log("clicked id for delete vendor");
    console.log(id);
    dispatch(deleteVendor(id)).then(r => dispatch(fetchVendors()));
    setdialog(false);
  };

  const handleCellClick = (params) => {
    if(params.field !== "actions"){
      navigate("/viewVendor", { state: { vendor: params.id }} );
    }
  };

  const columns = [
    { field: 'id', headerName: 'S.No', align: "left", flex:0.5 },
    { field: 'fullname', headerName: 'Full Name', align: "left", flex:1.2 },
    { field: 'email', headerName: 'Email', align: "left", flex:1.3 },
    { field: 'companyname', headerName: 'Company Name', align: "left", flex:0.8 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              // onClick={() => handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  setdeleteVendorId(id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"0.6" }} onClose={() => setdialog(false)} > 
              <DialogTitle >
                {"Delete Vendor"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  Are you sure you wish to delete this Vendor ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteVendorId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>,
        ];
      }
    }
  ];

  const rows = vendors.map(v => {
    return ({ 
      id: v.RowId,
      fullname: v.FullName,
      email: v.Email,
      companyname: v.CompanyName
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Vendors" />
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            // onClick={() => { navigate("/addBrand") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Vendor</Typography>
          </Button>
        </div>
        
        <Divider />
        
        {vendors && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={ isVendorFetching ? true : false }
              autoHeight={18}
              pageSize={pageSize}
              onCellClick={handleCellClick}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}
        
      </Card>
    </div>
  );
}