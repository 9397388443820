import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { fetchAllDChallan} from '../store/actions/deliveryChallan';


export default function DeliveryChallanPage(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);

  const allDChallan = useSelector(state => (state.deliveryChallan.allDChallan || []));
  const isAllDChallanFetching = useSelector(state => (state.deliveryChallan.isAllDChallanFetching ));

  const mount = () => {
    dispatch(fetchAllDChallan());
  }
  // eslint-disable-next-line
  useEffect(mount, []);

  const handleViewClick = (id) => () => {
    var fltr_DChallan = Object.assign({}, ...allDChallan.filter(c => c.Id === id));
    navigate("/deliveryChallanDetail", { state: { deliveryChallanId: fltr_DChallan.Id }} );
  };

  const columns = [

    { field: 'id', headerName: 'Id', align: "left", flex: 0.3 },
    { field: 'customername', headerName: 'Customer Name', align: "left",flex:  1.1},
    { field: 'ponumber', headerName: 'PONumber', align: "left",flex: 0.9 },
    { field: 'deliverychallanno', headerName: 'Delivery Challan No', align: "left",flex: 1.2 },
    { field: 'status', headerName: 'Status', align: "left",flex: 0.9 },
    { field: 'totalamount', headerName: 'Total Amount', align: "left", flex: 0.5},
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="View">
            <GridActionsCellItem
              icon={<RemoveRedEyeOutlinedIcon />}
              label="View"
              className="textPrimary"
              onClick={handleViewClick(id)}
              color="inherit"
            />
          </Tooltip>
       ];
      }
    }
  ];

  const rows = allDChallan.map(d => {
    return ({ 
      id: d.Id,
      customername: d.CustomerFirstName + " " + d.CustomerLastName,
      ponumber: d.PONumber,
      deliverychallanno: d.DeliveryChallanNo,
      status: d.Status,
      totalamount: d.TotalAmount,
    })
  });

return (    
  <div>
    <Card>
      <div style={{ display:"flex", flexDirection:"row" }}>
        <CardHeader title="Delivery Challan" />
          {/* <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}>
            <Button variant='contained' color="error" >Back</Button>
          </div> */}
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addDeliveryChallan") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Delivery Challan</Typography>
          </Button>

      </div>
      <Divider />
      
      {allDChallan && (
        <CardContent>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={ isAllDChallanFetching ? true : false }
            autoHeight={18}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
          />
        </CardContent>
      )}
 
    </Card>
  </div>
);
}









  
  