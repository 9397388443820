import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import { getFormatedCurrency } from '../components/util';
import { format, parseISO } from 'date-fns';

import { fetchDlryReqList} from '../store/actions/deliveryChallan';


import { useNavigate } from 'react-router-dom';

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
/* import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'; */
import Tooltip from '@mui/material/Tooltip';


export default function DeliveryRequestPage(props) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  // const [dialogOpen, setdialog] = useState(false);
  // const [deleteBrandId, setdeleteBrandId] = useState("");
  
  const dlryReqList = useSelector(state => (state.deliveryChallan.deliveryRequestList || []));
  const isDlryReqListFetching =useSelector(state => (state.deliveryChallan.isDeliveryReqListFetching ));


  const mount = () => {
    dispatch(fetchDlryReqList());
  }
  // eslint-disable-next-line
  useEffect(mount, []);

  /* const handleViewClick = (id) => () => {
    navigate("/viewBrand", { state: { brandId: id }});
  }; */

  const handleEditClick = (id) => {
    // var fltr_dlry = Object.assign({}, ...dlryReqList.filter(d => { if(d.Id === id) return d; }));
    var fltr_dlryReq = Object.assign({}, ...dlryReqList.filter(d => d.Id === id));
    console.log("filter id");
    // console.log(fltr_dlry);
    // console.log(fltr_dlryReq);


    navigate("/editDeliveryRequest", { state: { dlryReq: fltr_dlryReq.Id }} );
  };

/*   const handleDeleteClick = (id) => {
    //console.log("clicked id to delete");
    //console.log(id);
    dispatch(deleteBrand(id)).then(r => dispatch(fetchBrands()));
    setdialog(false);
  }; */

  const columns = [
    { field: 'id', headerName: 'Id', align: "left", flex:0.4  },
    { field: 'deliveryPONumber', headerName: 'Delivery PO Number', align: "left", flex:1 },
    { field: 'customername', headerName: 'Customer Name', align: "left", flex:1 },
    { field: 'status', headerName: 'Status', align: "left", flex:0.8 },
    { field: 'totalDeliveryItem', headerName: 'Total Item', align: "left", flex:0.5 },
    { field: 'deliveryRequestDate', headerName: 'Delivery Request Date', align: "left", flex:1.3},

    { field: 'totalAmount', headerName: 'Total Amount', align: "left", flex:0.8 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.6,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
       /*    <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  setdeleteBrandId(id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"0.2" }} onClose={() => setdialog(false)} > 
              <DialogTitle >
                {"Delete Delivery Request"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  Are you sure you wish to delete this Delivery Request ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteBrandId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>, */
        ];
      }
    }
  ];

  const rows = dlryReqList.map(d => {
    return ({ 
      id: d.Id,
      deliveryPONumber: d.DeliveryPONumber, 
      customername: d.CustomerFirstName + " " + d.CustomerLastName, 
      status: d.Status, 
      totalDeliveryItem: d.TotalDeliveryItem, 
      totalAmount: getFormatedCurrency(d.TotalAmount),
      deliveryRequestDate:  format(parseISO(d.DeliveryRequestDate), "dd MMM yyyy 'at' hh:mm a"),

    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Delivery Request" />
          {/* <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addBrand") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Brand</Typography>
          </Button> */}
        </div>
        
        <Divider />
        
        {dlryReqList && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={ isDlryReqListFetching ? true : false }
              autoHeight={18}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}
        
      </Card>
    </div>
  );
}