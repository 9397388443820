import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { login } from '../store/actions/session';
import Logo from '../images/logo-large.png';
import Box from '@mui/material/Box';



export default function LoginPage(props) {

  const [values, setValues] = useState({Username: '', Password: ''});
  const [errorMsg, setErrorMsg] = useState(null);
  const isAuthenticating = useSelector(state => (state.session.isAuthenticating || false));
  const sError = useSelector(state => (state.session.error || ""));
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const handleSubmit = () => {
    if(!values || !values.Username || values.Username === ""){
      setErrorMsg("Please enter Username");
    }
    else if(!values || !values.Password || values.Password === ""){
      setErrorMsg("Please enter Password");
    }
    else {
      dispatch(login({
				Username: values.Username,
				Password: values.Password
			}));
    }    
  } 
  

  return (    
    <div className="App">      
      <Box sx={{ display: 'flex' }}>
        <Grid container>
          <Grid item md={4}></Grid>
          <Grid item md={4} sx={{margin: "40px 0 20px", textAlign: "center"}}>
            <img src={Logo} alt="ECS" />
          </Grid>
          <Grid item md={4}></Grid>
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <Box sx={{maxWidth: 400, margin: "0 auto"}}>
              <div>
                <div>
                  <Card>
                    <CardContent sx={{p: 4}}>
                      <Grid container>
                        {((errorMsg && errorMsg !== "") || (sError && sError !== "")) ?
                        <Grid item md={12} sx={{marginBottom: 4}}>
                          {(errorMsg && errorMsg !== "") && <Typography  variant="body2" color='error' align='center'>{errorMsg}</Typography>}
                          {(sError && sError !== "") && <Typography  variant="body2" color='error' align='center'>{sError}</Typography>}
                        </Grid> : null
                        }
                        <Grid item md={12} sx={{marginBottom: 4}}>
                          <TextField fullWidth label="Username" name="Username" onChange={handleChange} />
                        </Grid>
                        <Grid item md={12}>
                          <TextField type="password" fullWidth label="Password" name="Password" onChange={handleChange} />
                        </Grid>            
                      </Grid>
                    </CardContent>
                    <Divider />
                    <div style={{textAlign: "center", padding: 16}}>
                      {isAuthenticating ? 
                        <Button variant='contained' color='secondary'>Logging in...</Button> :
                        <Button variant='contained' color='secondary' onClick={handleSubmit}>Login</Button>
                      }
                    </div>
                  </Card>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>          
      </Box>      
    </div>    
  );
}