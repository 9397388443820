import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import ListItemText from '@mui/material/ListItemText';
// import Box from '@mui/material/Box';
// import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { getFormatedCurrency, getFormatedDate, RupeeSymbol } from '../components/util';
import { format, parseISO } from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate, useLocation } from 'react-router-dom';
import "../css/editquotationpage.css";
import { 
  fetchQuotationDetail, addQuotationComment, updateQuotation, 
  fetchAllQuotations, fetchQuotationStatus, convertIntoOrder, 
  deleteQuotationItem, deleteQuotation, deleteComment,
  fetchPaymentMethods } from '../store/actions/quotation';
import { fetchVendors } from '../store/actions/vendor'; 


export default function EditCategoryPage(props) {
  
  const quotDetail = useSelector(state => (state.quotation.quotDetail || []));
  const [values, setValues] = useState({

    isSelected: false,
    Disable: false,
	  Comment: '',
    SendPushNotification: false,
    showproduct: true,
    showcomment: true,
    payments: ['ONLNE', 'CRDIT'],

    QuotDetail:{Items:[], Comments:[], Status:"", PONumber: "", PaymentMethod: []},
  });


  const [errorMsg, setErrorMsg] = useState(null);
  const [ItemdialogOpen, setItemdialog] = useState(false);
  const [dialogOpen, setdialog] = useState(false);
  const [Commentdialog, setCommentdialog] = useState(false);

  const [deleteQuotItemId, setdeleteQuotItemId] = useState("");
  const [deleteQuotationId, setdeleteQuotationId] = useState("");
  const [deleteCommentId, setdeleteCommentId] = useState("");

  const quotStatus = useSelector(state => (state.quotation.quotationStatus || []));
  const vendorList = useSelector(state => (state.vendor.vendors || []));
  const pmntMethods = useSelector(state => (state.quotation.paymentMethods || []));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const handleItemsAddressChange = (fieldname, event, idx) => {

    var qtd = values.QuotDetail;
    var itm = qtd.Items[idx];

    itm[event.target.name] = event.target.value;

    // var Sltd_Add = fieldname === "Delivery" ? 
    // Object.assign({}, ...itm.CustomerAddressBook.filter(a => { if(a.AddressId === itm.DeliveryAddressId) return a; })) :
    // Object.assign({}, ...itm.PickupAddress.filter(a => { if(a.AddressId === itm.PickupAddressId) return a; })) ;

    if(fieldname === "Transportation"){
      itm.DeliveryAddressId = 0;
      itm.PickupAddressId = 0;
      itm.DeliveryOrPickupAddress.AddressId = 0;
      itm.DeliveryOrPickupAddress.AddressName = "";
      itm.DeliveryOrPickupAddress.Address = "";
      itm.DeliveryOrPickupAddress.Landmark = "";
      itm.DeliveryOrPickupAddress.Area = "";
      itm.DeliveryOrPickupAddress.City = "";
      itm.DeliveryOrPickupAddress.State = "";
      itm.DeliveryOrPickupAddress.Pincode = "";
    }
    var Sltd_Add = {};
    if(fieldname === "Delivery"){
      Sltd_Add = Object.assign({}, ...itm.CustomerAddressBook.filter(a => { if(a.AddressId === itm.DeliveryAddressId) return a; }))
    }
    if(fieldname === "PickUp"){
      Sltd_Add = Object.assign({}, ...itm.PickupAddress.filter(a => { if(a.AddressId === itm.PickupAddressId) return a; }))
    }

    if(Sltd_Add && Sltd_Add.AddressId){
    itm.DeliveryOrPickupAddress.AddressId = Sltd_Add.AddressId;
    itm.DeliveryOrPickupAddress.AddressName = Sltd_Add.AddressName;
    itm.DeliveryOrPickupAddress.Address = Sltd_Add.Address;
    itm.DeliveryOrPickupAddress.Landmark = Sltd_Add.LandMark;
    itm.DeliveryOrPickupAddress.Area = Sltd_Add.Area;
    itm.DeliveryOrPickupAddress.City = Sltd_Add.City;
    itm.DeliveryOrPickupAddress.State = Sltd_Add.State;
    itm.DeliveryOrPickupAddress.Pincode = Sltd_Add.Pincode;
    }

    setValues({...values, QuotDetail: qtd});
    setErrorMsg(null);
  };

  const handleItemsValueChange = (event, idx) => {
    var qtd = values.QuotDetail;
    var itm = qtd.Items[idx];
    
    itm[event.target.name] = event.target.value;

    setValues({...values, QuotDetail: qtd});
    setErrorMsg(null);
  };

  const handleValueChange = (event) => {
    var qtd = values.QuotDetail;

    qtd[event.target.name] = event.target.value;

    setValues({...values, QuotDetail: qtd});
    setErrorMsg(null);
  };

 
  const handleSelectChange = (event) => {
    var qtd = values.QuotDetail;

    qtd[event.target.name] = event.target.value;
    
    setValues({ ...values, QuotDetail: qtd});
    console.log("changed value");
    console.log(values.QuotDetail);
  };

  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };


  const mount = () => {
    console.log("mount");
    dispatch(fetchQuotationDetail(location.state.quotation.Id));
    dispatch(fetchQuotationStatus());
    dispatch(fetchPaymentMethods());
    dispatch(fetchVendors());
    
  }
  
  useLayoutEffect(mount, []);

  useEffect( () =>{
    
    //All this info is there in quotDetail
   
      console.log("Quotation detail updated");
      setValues({
        ...values,
        QuotDetail: {...quotDetail}
      });

  }, [quotDetail]);


  const isInputValid = () => {
    let isInputValid = true;
    let invalidFieldName = "";
    let errorMsg = "";

    if(/* values.Area === "" || */ values.Comment === "" /* || values.IsDefault === ""  */){
      isInputValid = false;
      errorMsg = "Please fill all the * marked fields.";
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  }



  const onTabViewClick = (selected) =>{
    if(selected === "ProdClicked"){
      setValues({ ...values, showproduct: !values.showproduct})
    }
    else if(selected === "CmntClicked"){
      setValues({ ...values, showcomment: !values.showcomment})
    }
  }

  const handleSaveQuotation = () => {
    // if(isInputValid()){
      dispatch(updateQuotation(values.QuotDetail)).then(r => dispatch(fetchAllQuotations()));
      navigate("/quotations");
    // }
  }

  const handleOrderGenerate = () => {

    // var qtd = values.QuotDetail;
    // qtd.ConvertToOrder = true;
    // setValues({...values, QuotDetail: qtd});

    // console.log("quotation values");
    // console.log(quotDetail);
    // console.log("values.quotation");
    // console.log(values.QuotDetail);

    dispatch(updateQuotation(values.QuotDetail));
    dispatch(convertIntoOrder(values.QuotDetail.Id, values.QuotDetail.CustomerId)).then(r => dispatch(fetchAllQuotations()));
    navigate("/quotations");
  }

  const handleQuotationDeleteClick = (id) => {
    dispatch(deleteQuotation(id)).then(r => dispatch(fetchAllQuotations()));
    navigate("/quotations");
    setdialog(false);
  };

  const handleItemDeleteClick = (id) => {
    var qtd = values.QuotDetail;
    var itm = qtd.Items[id];
    dispatch(deleteQuotationItem(itm.QuotationItemId)).then(r => dispatch(fetchQuotationDetail(location.state.quotation.Id)));/* .then(r => dispatch(fetchQuotationDetail(location.state.quotation.Id))); */
    setItemdialog(false);
  };

  const handleCommentSubmit = () => {

    let isInputValid = true;
    let errorMsg = "";
    if(values.Comment === ""){
      isInputValid = false;
      errorMsg = "Please enter comment."
    }
    else{

      var Comment = {
          QuotationId: values.QuotDetail.Id,
          Comment: values.Comment,
          CommentBy: "Admin",
          SendPushNotification: values.SendPushNotification
      };
      dispatch(addQuotationComment(Comment)).then(r => dispatch(fetchQuotationDetail(location.state.quotation.Id)));
      setValues({...values, Comment: ''});
    }
    setErrorMsg( errorMsg );
  }
  
  const DeleteComment = (id) => {
    console.log("enter in delete comment");
    console.log(id);
    dispatch(deleteComment(id)).then(r => dispatch(fetchQuotationDetail(location.state.quotation.Id)));
    setCommentdialog(false);
  };

  const deliverytime = [
    { label: 'Today', value: 'Today' },
    { label: 'Tomorrow', value: 'Tomorrow' },
    { label: 'Within 1 week', value: '1 week' },
    { label: 'Within 15 days', value: '15 days' },
  ];

  const transportation = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];

  return(
    <div>

      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Edit Quotation" />
            <div
              style={{ display:"flex", 
                flexDirection:"row", 
                alignItems:"center", 
                justifyContent:"center", 
                marginLeft:"auto", 
                marginRight:18 
              }} >
              <Button variant='contained' color="error" onClick={() => { navigate("/quotations") }}>Back</Button>
            </div>
        </div>
        <Divider />

        <CardContent>
          <div style={{ display:"flex", fontSize: 22, fontWeight:"bold", color:"#F7D200"}}>
            <CardHeader title="Customer Detail" />
          </div>
          <Grid container style={{marginBottom:20}}>
            <Grid item xs={6} spacing={4}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Name: </Typography>
                <Typography variant="subtitle1" ml={2}>{(quotDetail.CustomerFirstName ) +" "+ (quotDetail.CustomerLastName)}</Typography>
              </div>

              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Mobile: </Typography>
                <Typography variant="subtitle1" ml={2}>{quotDetail.CustomerMobile}</Typography>
              </div>
              
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Email: </Typography>
                <Typography variant="subtitle1" ml={2}>{quotDetail.CustomerEmail}</Typography>
              </div>

            </Grid>
            <Grid item xs={6} spacing={4}>             
                <div className="div-infocontainer">
                <FormControl required sx={{ width: "100%" }}>
                  <InputLabel id="demo-controlled-open-select-label">Available Payment Methods</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={(values && values.QuotDetail && values.QuotDetail.PaymentMethod) ? values.QuotDetail.PaymentMethod : []}
                    label="Available Payment Methods"
                    name='PaymentMethod'
                    onChange={ (e) => handleSelectChange(e)}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                          var fltr_payment = Object.assign({}, ...pmntMethods.filter(p => {if(value == p.Code) return p;}));
                          var listitem = (fltr_payment) ? fltr_payment.Name : "";    
                          return (<Chip key={value} label={listitem} />);
                        })}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {pmntMethods.map((p, i) => {     
                      var listitem = (p.Name) ? p.Name : "";    
                      return (
                        <MenuItem value={p.Code} key={i}>
                          <Checkbox checked={(values && values.QuotDetail && values.QuotDetail.PaymentMethod && values.QuotDetail.PaymentMethod.indexOf(p.Code) > -1) ? true : false } />
                          <ListItemText primary={listitem} />
                        </MenuItem > 
                      )
                    })}
                  </Select>
                </FormControl>
              </div> 
            </Grid>
          </Grid>

          <Divider />

          <div style={{ display:"flex", fontSize: 22, fontWeight:"bold", color:"#F7D200", marginTop:10}}>
            <CardHeader title="Quotation Detail" />
          </div>
          
          <Grid container style={{marginBottom:10}}>
                
            <Grid item xs={6} spacing={4}>

              <div className="div-infocontainer">
                <Typography variant="subtitle2">Quotation Date and Time: </Typography>
                <Typography variant="subtitle1" ml={2}>{(quotDetail.QuotationDate) ? format(parseISO(quotDetail.QuotationDate), "dd MMM yyyy 'at' hh:mm a") : null}</Typography>
              </div>                   
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Total Amount: </Typography>
                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(quotDetail.TotalAmount)}</Typography>
              </div>  
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Total Items: </Typography>
                <Typography variant="subtitle1" ml={2}>{quotDetail.TotalItems}</Typography>
              </div>
                     
            </Grid>
            
            <Grid item xs={6} spacing={4}>             
              <div className="div-infocontainer">
                <FormControl required sx={{ width: "100%" }}>
                  <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={values.QuotDetail.Status}
                    label="Status"
                    name='Status'
                    onChange={ (e) => handleSelectChange(e)}
                    MenuProps={MenuProps}
                  >
                    {quotStatus.map((s, i) => {
                      return <MenuItem value={s.Name} key={i}>{s.Name}</MenuItem >
                    })}
                  </Select>
                </FormControl>
              </div>  

              <div className="div-infocontainer" style={{marginTop:20}} >
                <TextField 
                  required 
                  fullWidth label="PO Number" value={values.QuotDetail.PONumber} name="PONumber" onChange={ (e) => handleValueChange(e)} />
              </div>

            </Grid>     
          </Grid>

          <Grid container style={{marginBottom:15}}>
            <div style={{ borderRadius:5, backgroundColor: "#D9D9D9", width:"100%", marginRight:3, paddingInline:15}}>
              <div 
              onClick={(e) => onTabViewClick("ProdClicked", e)} 
              style={{display:"flex",  alignItems:"center", justifyContent:"space-between"}}>
                <div style={{fontSize: 22, fontWeight:"bold", marginLeft:-10}}>
                  <CardHeader title="Products Detail" />
                </div>
                <div style={{justifyContent:"flex-end"}}>
                  {!values.showproduct ?
                    <ExpandMoreIcon style={{height:25, width:25}}/>
                    : <ExpandLessIcon style={{height:25, width:25}}/>
                  }
                </div>
              </div>

              {values.showproduct ?
                <div>
                  {((values && values.QuotDetail && values.QuotDetail.Items) || [] ).map((p, i) => { 
                    return  (  
                      <Card key={i} style={{ width:"100%", backgroundColor:"white",  marginBottom:15, borderRadius:5,}}>
                        <div style={{ display:"flex", flexDirection:"row" }}>
                          <CardHeader title={p.Product.BrandName + " - " + p.Product.ProductName + " ("+ p.Product.ProductSize + ") "} />
                          
                            <div
                              style={{ display:"flex", 
                                alignItems:"center", 
                                marginLeft:"auto", 
                                marginRight:18 
                              }} >
                                <Button variant='contained' color="error"  onClick={() => {setdeleteQuotItemId(i); setItemdialog(true); }}>Delete</Button>
                                <div>
                                  <Dialog open={ItemdialogOpen} style={{ opacity:"1" }} onClose={() => setItemdialog(false)} >
                                    <DialogTitle >
                                      {"Delete Product"}
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText >
                                        Are you sure you wish to delete this Product from Quotation ?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={() => handleItemDeleteClick(deleteQuotItemId)}>Yes</Button>
                                      <Button onClick={() => setItemdialog(false)} autoFocus>
                                        No
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                            </div>
                        </div>
                        <Divider />

                        <div style={{ padding:20}}>
                          <Grid container spacing={2} key={i} display="flex">
                            <Grid item xs={3} container >
                              <img src={p.Product.ProductImage} alt={"Prodcutimg"} width="100%" height="100%" style={{borderRadius:5, borderStyle:'outset', borderWidth:1, borderColor:"#C4C4C4",}} />
                            </Grid>

                            <Grid item xs={9} spacing={2} container style={{marginTop:5}}>
                              <Grid item xs={4}>
                                <div className="div-infocontainer">
                                  <Typography variant="subtitle2">Product Name: </Typography>
                                  <Typography variant="subtitle1" ml={2}>{p.Product.ProductName}</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <div className="div-infocontainer">
                                  <Typography variant="subtitle2">Brand Name: </Typography>
                                  <Typography variant="subtitle1" ml={2}>{p.Product.BrandName}</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <div className="div-infocontainer">
                                  <Typography variant="subtitle2">Unit: </Typography>
                                  <Typography variant="subtitle1" ml={2}>{p.Product.ProductSize}</Typography>
                                </div>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField 
                                  required 
                                  disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                  }}
                                  fullWidth label="Our Price" type="number" value={p.QuotationPrice} name="QuotationPrice" onChange={ (e) => handleItemsValueChange(e,i)} />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField 
                                  required 
                                  disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                  }}
                                  fullWidth label="Vendor Price" type="number" value={p.VendorPrice} name="VendorPrice" onChange={ (e) => handleItemsValueChange(e,i)} />
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl required sx={{ width: "100%" }}>
                                  <InputLabel id="demo-controlled-open-select-label">Vendor Name</InputLabel>
                                  <Select
                                    disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={p.VendorId}
                                    label="Vendor Name"
                                    name='VendorId'
                                    onChange={ (e) => handleItemsValueChange(e,i)}
                                    MenuProps={MenuProps}
                                  >
                                    {vendorList.map((s, i) => {
                                      return <MenuItem value={s.Id} key={i}>{s.FullName}</MenuItem >
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField 
                                  required 
                                  disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                  fullWidth label="Quantity" type="number" value={p.Quantity} name="Quantity" onChange={ (e) => handleItemsValueChange(e,i)} />
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl required sx={{ width: "100%" }}>
                                  <InputLabel id="demo-controlled-open-select-label">Transportation</InputLabel>
                                  <Select
                                    disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={p.Transportation}
                                    label="Transportation"
                                    name='Transportation'
                                    onChange={ (e) => handleItemsAddressChange("Transportation",e,i)}
                                    MenuProps={MenuProps}
                                  >
                                    {transportation.map((t, i) => {
                                      return <MenuItem value={t.value} key={i}>{t.label}</MenuItem >
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl required sx={{ width: "100%" }}>
                                  <InputLabel id="demo-controlled-open-select-label">Delivery Time</InputLabel>
                                  <Select
                                    disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={p.DeliveryTime}
                                    label="DeliveryTime"
                                    name='DeliveryTime'
                                    onChange={ (e) => handleItemsValueChange(e,i)}
                                    MenuProps={MenuProps}
                                  >
                                    {deliverytime.map((d, i) => {
                                      return <MenuItem value={d.value} key={i}>{d.label}</MenuItem >
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} spacing={2} container>
                              <Grid item xs={3}>
                                <TextField 
                                  required 
                                  disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                  }}
                                  fullWidth label="Discount" type="number" value={p.Discount} name="Discount" onChange={ (e) => handleItemsValueChange(e,i)} />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField 
                                  required 
                                  disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                  }}
                                  fullWidth label="Service Charge" type="number" value={p.ServiceCharge} name="ServiceCharge" onChange={ (e) => handleItemsValueChange(e,i)} />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField 
                                  required 
                                  disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                  }}
                                  fullWidth label="Delivery Charges" type="number" value={p.DeliveryCharge} name="DeliveryCharge" onChange={ (e) => handleItemsValueChange(e,i)} />
                              </Grid>
                              <Grid item xs={3}>
                                <FormControl required sx={{ width: "100%" }}>
                                  <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                                  <Select
                                    disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={p.Status}
                                    label="Status"
                                    name='Status'
                                    onChange={ (e) => handleItemsValueChange(e,i)}
                                    MenuProps={MenuProps}
                                  >
                                    {quotStatus.map((s, i) => {
                                      return <MenuItem value={s.Name} key={i}>{s.Name}</MenuItem >
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} spacing={2} container>
                              <Grid item xs={3}>
                                <CardHeader title="Address Detail" />
                              </Grid>
                              {(p && p.Transportation === 'Yes') ?
                                <Grid item xs={6}>
                                    <FormControl required sx={{ width: "100%" }}>
                                      <InputLabel id="demo-controlled-open-select-label">Delivery Address</InputLabel>
                                      <Select
                                        disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={p.DeliveryAddressId}
                                        label="Delivery Address"
                                        name='DeliveryAddressId'
                                        onChange={ (e) => handleItemsAddressChange("Delivery", e,i)}
                                        MenuProps={MenuProps}
                                      >
                                        {((p && p.CustomerAddressBook) || []).map((a, i) => {
                                          return <MenuItem value={a.AddressId} key={i}>{a.AddressName}</MenuItem >
                                        })}
                                      </Select>
                                    </FormControl>
                                </Grid>
                                :
                                <Grid item xs={6}>
                                  <FormControl required sx={{ width: "100%" }}>
                                    <InputLabel id="demo-controlled-open-select-label">PickUp Address</InputLabel>
                                    <Select
                                      disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      value={p.PickupAddressId}
                                      label="PickUp Address"
                                      name='PickupAddressId'
                                      onChange={ (e) => handleItemsAddressChange("PickUp", e,i)}
                                      MenuProps={MenuProps}
                                    >
                                      {(p && p.PickupAddress || []).map((a, i) => {
                                        return <MenuItem value={a.AddressId} key={i}>{a.AddressName}</MenuItem >
                                      })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              }
                              <Grid item xs={3}>
                                <TextField 
                                  required 
                                  // disabled = {true}
                                  inputProps={
                                    { readOnly: true, }
                                  }
                                  fullWidth label="Address" value={p.DeliveryOrPickupAddress.Address}/>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} spacing={2} container>
                              <Grid item xs={3}>
                                <TextField 
                                  required 
                                  // disabled = {true}
                                  inputProps={
                                    { readOnly: true, }
                                  }
                                  fullWidth label="Area" value={p.DeliveryOrPickupAddress.Area}/>
                              </Grid>
                              <Grid item xs={3}>
                                <TextField 
                                  required 
                                  // disabled = {true}
                                  inputProps={
                                    { readOnly: true, }
                                  }
                                  fullWidth label="City & State" value={p.DeliveryOrPickupAddress.City !== "" ? p.DeliveryOrPickupAddress.City + ", " + p.DeliveryOrPickupAddress.State : ""}/>
                              </Grid>
                              <Grid item xs={3}>
                                <TextField 
                                  required 
                                  // disabled = {true}
                                  inputProps={
                                    { readOnly: true, }
                                  }
                                  fullWidth label="Pin Code" type="number" value={p.DeliveryOrPickupAddress.Pincode}/>
                              </Grid>
                            </Grid>

                          </Grid>
                        </div>
                      </Card>
                    )
                  })}
                </div>
                : null 
              }
            </div>

          </Grid>
        
        </CardContent>
        <Divider />

        {/* {(values.QuotDetail.Status != "Confirmed") ?
          <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"space-between"}}>
            <Button variant='contained' color='secondary' onClick={() => { navigate("/quotations") }}>Back</Button>
            <Button variant='contained' color='secondary' onClick={handleSaveQuotation}>Save Quotation</Button>
            <Button variant='contained' color='secondary' onClick={handleOrderGenerate}>Generate Order</Button>
          </div>
          : 
          <div style={{textAlign: "center", padding: 16}}>
            <Button variant='contained' color='secondary' onClick={() => { navigate("/quotations") }}>Back</Button>        
          </div>
        } */}
        <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"space-between"}}>
          <Button variant='contained' color='secondary' onClick={() => { navigate("/quotations") }}>Back</Button>
          <Button variant='contained' color='secondary' onClick={handleSaveQuotation}>Save Quotation</Button>
          <Button variant='contained' color='secondary' onClick={handleOrderGenerate}>Generate Order</Button>
          <Button variant='contained' color="error" onClick={() => {setdeleteQuotationId(values.QuotDetail.Id); setdialog(true); }}>Delete Quotation</Button>
        </div>
            <div>
              <Dialog open={dialogOpen} style={{ opacity:"1" }} onClose={() => setdialog(false)} >
                <DialogTitle >
                  {"Delete Quotation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText >
                    Are you sure you wish to delete this Quotation ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleQuotationDeleteClick(deleteQuotationId)}>Yes</Button>
                  <Button onClick={() => setdialog(false)} autoFocus>
                    No
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
      </Card>

      <Card style={{ marginTop: 25}} >
        <div style={{display:"flex",justifyContent:"space-between"}}>
        <CardHeader title="Nodes Section" />
        <Grid item xs={6} style={{marginTop:10, marginRight:15}} >
          <FormGroup aria-label="position" row >
            <FormControlLabel control={<Checkbox defaultChecked={false} checked={values.SendPushNotification} onChange={(e) => { setValues({...values, SendPushNotification: e.target.checked}) }} />} 
              labelPlacement="start" 
              label="Check for Push Notification"
            />
          </FormGroup>
        </Grid>
        </div>
        <Divider />
        <Grid container style={{marginbottom:15}}>
          <div style={{ borderRadius:5, backgroundColor: "#D9D9D9", width:"100%", paddingInline:15, margin:20}}>
            <div onClick={(e) => onTabViewClick("CmntClicked", e)} style={{display:"flex",  alignItems:"center", justifyContent:"space-between"}}>
              <div style={{fontSize: 22, fontWeight:"bold", marginLeft:-10}}>
                <CardHeader title="Show Nodes" />
              </div>
             
              <div style={{justifyContent:"flex-end"}}>
                {!values.showcomment ?
                  <ExpandMoreIcon style={{height:25, width:25}}/>
                  : <ExpandLessIcon style={{height:25, width:25}}/>
                }
              </div>
            </div>

            {values.showcomment && values.QuotDetail && values.QuotDetail.Comments && values.QuotDetail.Comments.length != 0 ?
              <div style={{ width:"100%", backgroundColor:"white",  marginBottom:15, borderRadius:5, paddingInline:10, padding:10}}>

                {values.QuotDetail.Comments.map((c, i) => {
                  return  ( 
                    <div>
                      <Grid container spacing={4} key={i} display="flex">
                        
                        <Grid item xs={3} container>
                          <div className="div-infocontainer">
                            <Typography variant="subtitle1" ml={2}>{c.CommentBy + ": "}</Typography>
                            <Typography style={{fontSize:11, color:"#7C7C7C"}} ml={2}>{"(" + format(parseISO(c.CreatedOn), "dd MMM yyyy 'at' hh:mm a") + ")"}</Typography>

                          </div>                           
                        </Grid>

                        {/* <Grid item xs={2} container>
                          <div className="div-infocontainer">
                            <Typography style={{fontSize:10, color:"#D9D9D9"}} ml={2}>{"(" + format(parseISO(c.CreatedOn), "dd MMM yyyy 'at' hh:mm a") + ")"}</Typography>
                          </div>                           
                        </Grid> */}

                        <Grid item xs={8} container>
                          <div className="div-infocontainer">
                            <Typography variant="subtitle1" ml={2}>{c.Comment}</Typography>
                          </div>
                        </Grid>

                        <Grid item xs={1} container>
                          <div className="div-infocontainer" >
                            <Tooltip title="Delete">
                            <Typography onClick={() => {setdeleteCommentId(c.Id); setCommentdialog(true); }} variant="subtitle1" ml={2}>{<DeleteIcon />}</Typography>
                            </Tooltip>
                            <div>
                              <Dialog open={Commentdialog} style={{ opacity:"1" }} onClose={() => setCommentdialog(false)} >
                                <DialogTitle >
                                  {"Delete Node"}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText >
                                    Are you sure you wish to delete this Node from Quotation ?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={() => DeleteComment(deleteCommentId)}>Yes</Button>
                                  <Button onClick={() => setCommentdialog(false)} autoFocus>
                                    No
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>
                        </Grid>

                      </Grid>
                      {(i != values.QuotDetail.Comments.length-1) ? 
                      <Divider/>
                      : <span></span>}
                    </div>
                  )
                })}
              </div>
              : null 
            }
          </div>
        </Grid>

        <CardContent sx={{ paddingX: 2.5, paddingY: 2 }}>
          <Grid item xs={12} >
            <TextField fullWidth multiline size="medium" label="Add node" value={values.Comment} name="Comment" onChange={handleChange} />
          </Grid>
        </CardContent>
        
        <Divider />

        <div style={{textAlign: "center", padding: 16}}>
          {(errorMsg && errorMsg !== "") && (
            <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
          )}
          <Button variant='contained' color='secondary' onClick={handleCommentSubmit}>Add Node</Button>
        </div>
      </Card>

      </div>
  )
}
                  

                    