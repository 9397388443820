// import { REACT_APP_CustomizationOptions } from '@env';
export const RupeeSymbol = '₹';

export function getFormatedCurrency(num) {
    if (isNaN(num)) return "";
    //var n = Math.round(num);
    //return "₹ " + n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    //var n = parseFloat(num);
    var n = parseFloat(num);
    n = n.toFixed(2);
    n = n.replace(".00","");
    return "₹ " + n.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getFormatedNumber(num) {    
    if (isNaN(num)) return "";
    var n = Math.round(num);
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getFormatedDate(date, format) {
    var dt = new Date(date);
    var fDt = format.replace(/yyyy/g, dt.getFullYear()).replace(/mmm/g, (dt.getMonth()+1)).replace(/dd/g, dt.getDate());
    return fDt;
}