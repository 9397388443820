import { CALL_API } from '../api';

/* Delivery Challan List */
export const ALL_DCHALLAN_LIST_REQUEST = "ALL_DCHALLAN_LIST_REQUEST";
export const ALL_DCHALLAN_LIST_SUCCESS = "ALL_DCHALLAN_LIST_SUCCESS";
export const ALL_DCHALLAN_LIST_FAILURE = "ALL_DCHALLAN_LIST_FAILURE";

/* Order Item list for Delivery Challan */
export const ODRITEM_LISTFOR_DCHALLAN_REQUEST = "ODRITEM_LISTFOR_DCHALLAN_REQUEST";
export const ODRITEM_LISTFOR_DCHALLAN_SUCCESS = "ODRITEM_LISTFOR_DCHALLAN_SUCCESS";
export const ODRITEM_LISTFOR_DCHALLAN_FAILURE = "ODRITEM_LISTFOR_DCHALLAN_FAILURE";

/* List out Delivery Challan */
export const LISTOUT_DCHALLAN_REQUEST = "LISTOUT_DCHALLAN_REQUEST";
export const LISTOUT_DCHALLAN_SUCCESS = "LISTOUT_DCHALLAN_SUCCESS";
export const LISTOUT_DCHALLAN_FAILURE = "LISTOUT_DCHALLAN_FAILURE";

/* Delivery Challan Detail */
export const DCHALLAN_DETAIL_REQUEST = "DCHALLAN_DETAIL_REQUEST";
export const DCHALLAN_DETAIL_SUCCESS = "DCHALLAN_DETAIL_SUCCESS";
export const DCHALLAN_DETAIL_FAILURE = "DCHALLAN_DETAIL_FAILURE";

/* Generate Delivery Challan */
export const GENERATE_DCHALLAN_REQUEST = "GENERATE_DCHALLAN_REQUEST";
export const GENERATE_DCHALLAN_SUCCESS = "GENERATE_DCHALLAN_SUCCESS";
export const GENERATE_DCHALLAN_FAILURE = "GENERATE_DCHALLAN_FAILURE";

/* Delivery Request List */
export const DELIVERY_REQUEST_LIST_REQUEST = "DELIVERY_REQUEST_LIST_REQUEST";
export const DELIVERY_REQUEST_LIST_SUCCESS = "DELIVERY_REQUEST_LIST_SUCCESS";
export const DELIVERY_REQUEST_LIST_FAILURE = "DELIVERY_REQUEST_LIST_FAILURE";

/* Delivery Request Detail */
export const DELIVERY_REQUEST_DETAIL_REQUEST = "DELIVERY_REQUEST_DETAIL_REQUEST";
export const DELIVERY_REQUEST_DETAIL_SUCCESS = "DELIVERY_REQUEST_DETAIL_SUCCESS";
export const DELIVERY_REQUEST_DETAIL_FAILURE = "DELIVERY_REQUEST_DETAIL_FAILURE";

/* Delivery Request Item Detail */
export const DELIVERY_REQUEST_ITEM_DETAIL_REQUEST = "DELIVERY_REQUEST_ITEM_DETAIL_REQUEST";
export const DELIVERY_REQUEST_ITEM_DETAIL_SUCCESS = "DELIVERY_REQUEST_ITEM_DETAIL_SUCCESS";
export const DELIVERY_REQUEST_ITEM_DETAIL_FAILURE = "DELIVERY_REQUEST_ITEM_DETAIL_FAILURE";

/* Update Delivery Request Status */
export const UPDATE_DELIVERY_REQUEST_STATUS_REQUEST = "UPDATE_DELIVERY_REQUEST_STATUS_REQUEST";
export const UPDATE_DELIVERY_REQUEST_STATUS_SUCCESS = "UPDATE_DELIVERY_REQUEST_STATUS_SUCCESS";
export const UPDATE_DELIVERY_REQUEST_STATUS_FAILURE = "UPDATE_DELIVERY_REQUEST_STATUS_FAILURE";

/* Update Delivery Request Item Status */
export const UPDATE_DELIVERY_REQUEST_ITEM_STATUS_REQUEST = "UPDATE_DELIVERY_REQUEST_ITEM_STATUS_REQUEST";
export const UPDATE_DELIVERY_REQUEST_ITEM_STATUS_SUCCESS = "UPDATE_DELIVERY_REQUEST_ITEM_STATUS_SUCCESS";
export const UPDATE_DELIVERY_REQUEST_ITEM_STATUS_FAILURE = "UPDATE_DELIVERY_REQUEST_ITEM_STATUS_FAILURE";

/* Get Delivery Request Status */
export const DELIVERY_REQUEST_STATUS_REQUEST = "DELIVERY_REQUEST_STATUS_REQUEST";
export const DELIVERY_REQUEST_STATUS_SUCCESS = "DELIVERY_REQUEST_STATUS_SUCCESS";
export const DELIVERY_REQUEST_STATUS_FAILURE = "DELIVERY_REQUEST_STATUS_FAILURE";


/* Delivery Challan List */
export const fetchAllDChallan = (body) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [ALL_DCHALLAN_LIST_REQUEST, ALL_DCHALLAN_LIST_SUCCESS, ALL_DCHALLAN_LIST_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'deliverychallan/getdeliverychallanlist'
      }
    });
};

/* Order Item list for Delivery Challan */
export const fetchOdrItemListForDChallan = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [ODRITEM_LISTFOR_DCHALLAN_REQUEST, ODRITEM_LISTFOR_DCHALLAN_SUCCESS, ODRITEM_LISTFOR_DCHALLAN_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'deliverychallan/getorders'
    }
  });
};

/* List out Delivery Challan */
export const ListOutDChallan = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [LISTOUT_DCHALLAN_REQUEST, LISTOUT_DCHALLAN_SUCCESS, LISTOUT_DCHALLAN_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'deliverychallan/save'
    }
  });
};

/* Delivery Challan Detail */
export const fetchDChallanDetail = (dChallanId) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [DCHALLAN_DETAIL_REQUEST, DCHALLAN_DETAIL_SUCCESS, DCHALLAN_DETAIL_FAILURE],
      method: 'GET',
      endpoint: 'deliverychallan/getdeliverychallandetail/' + dChallanId
      }
  });
};

/* Generate Delivery Challan */
export const DownloadDChallan = (dChallanId) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [GENERATE_DCHALLAN_REQUEST, GENERATE_DCHALLAN_SUCCESS, GENERATE_DCHALLAN_FAILURE],
      method: 'GET',
      endpoint: 'deliverychallan/generatedeliverychallan/' + dChallanId
      }
  });
};

/* Delivery Request List*/
export const fetchDlryReqList = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [DELIVERY_REQUEST_LIST_REQUEST, DELIVERY_REQUEST_LIST_SUCCESS, DELIVERY_REQUEST_LIST_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'deliveryrequest/getlist'
    }
  });
};

/* Delivery Request Detail */
export const fetchDeliveryReqDetail = (delvRequestId) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [DELIVERY_REQUEST_DETAIL_REQUEST, DELIVERY_REQUEST_DETAIL_SUCCESS, DELIVERY_REQUEST_DETAIL_FAILURE],
      method: 'GET',
      endpoint: 'deliveryrequest/getdeliveryrequestbyid/' + delvRequestId
      }
  });
};

/* Delivery Request item Detail */
export const fetchDeliveryReqItemDetail = (delvRequestItemId) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [DELIVERY_REQUEST_ITEM_DETAIL_REQUEST, DELIVERY_REQUEST_ITEM_DETAIL_SUCCESS, DELIVERY_REQUEST_ITEM_DETAIL_FAILURE],
      method: 'GET',
      endpoint: 'deliveryrequest/getitemdetail/' + delvRequestItemId
      }
  });
};

/* Delivery Request Status Update */
export const updateDlryReqStatus = (body) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [UPDATE_DELIVERY_REQUEST_STATUS_REQUEST, UPDATE_DELIVERY_REQUEST_STATUS_SUCCESS, UPDATE_DELIVERY_REQUEST_STATUS_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'deliveryrequest/updatestatus'
      }
  });
};

/* Delivery Request Item Status Update */
export const updateDlryReqItemStatus = (body) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [UPDATE_DELIVERY_REQUEST_ITEM_STATUS_REQUEST, UPDATE_DELIVERY_REQUEST_ITEM_STATUS_SUCCESS, UPDATE_DELIVERY_REQUEST_ITEM_STATUS_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'deliveryrequest/updateitemstatus'
      }
  });
};

/* Delivery Request Item Status */
export const fetchDeliveryRequestStatus = () => ({
  [CALL_API]: {
      types: [DELIVERY_REQUEST_STATUS_REQUEST, DELIVERY_REQUEST_STATUS_SUCCESS, DELIVERY_REQUEST_STATUS_FAILURE],
      method: 'GET',
      endpoint: 'deliveryrequest/getdeliveryrequeststatus'
  }
});