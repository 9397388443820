import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { getFormatedCurrency } from '../components/util';
import { format, parseISO } from 'date-fns';
import { RupeeSymbol } from '../components/util';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate, useLocation } from 'react-router-dom';
import "../css/editquotationpage.css";
import { fetchQuotationDetail, addQuotationComment } from '../store/actions/quotation';

export default function ViewQuotationPage(props) {

  const [values, setValues] = useState({
	  Comment: '',
    showproduct: true,
    showcomment: true,
    QuotDetail:{Items:[], Comments:[]},
  });

  const [errorMsg, setErrorMsg] = useState(null);
  const quotDetail = useSelector(state => (state.quotation.quotDetail || []));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };


  const mount = () => {
    dispatch(fetchQuotationDetail(location.state.quotation));
  }

  useLayoutEffect(mount, []);

  useEffect( () =>{
    
    //All this info is there in quotDetail
   
      console.log("Quotation detail updated");
      setValues({
        ...values,
        QuotDetail: {...quotDetail}
      });

  }, [quotDetail]);

  useEffect( () =>{
    
    //All this info is there in quotDetail
    if(location.state && location.state.quotation){
      
      setValues({
        ...values,
      });

    }
  }, [location]);


  /* Expand and Collasp  */
  const onTabViewClick = (selected) =>{
    if(selected === "ProdClicked"){
      setValues({ ...values, showproduct: !values.showproduct})
    }
    else if(selected === "CmntClicked"){
      setValues({ ...values, showcomment: !values.showcomment})
    }
  }

  /* Adding Comment */
  const handleCommentSubmit = () => {

    let isInputValid = true;
    let errorMsg = "";
    if(values.Comment === ""){
      isInputValid = false;
      errorMsg = "Please enter comment."
    }
    else{
      var Comment = {
          QuotationId: values.QuotDetail.Id,
          Comment: values.Comment,
          // CommentBy: "string"
      };
      dispatch(addQuotationComment(Comment)).then(r => dispatch(fetchQuotationDetail(location.state.quotation)));
      navigate("/viewQuotation");
      setValues({...values, Comment: ''});
    }
    setErrorMsg( errorMsg );
  }



  return(
    <div>

      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="View Quotation" />
            <div
              style={{ display:"flex", 
                flexDirection:"row", 
                alignItems:"center", 
                justifyContent:"center", 
                marginLeft:"auto", 
                marginRight:18 
              }} >
              <Button variant='contained' color="error" onClick={() => { navigate("/quotations") }}>Back</Button>
            </div>
        </div>
        <Divider />

        <CardContent>
          <div style={{ display:"flex", fontSize: 22, fontWeight:"bold", color:"#F7D200"}}>
            <CardHeader title="Customer Detail" />
          </div>
          <Grid container style={{marginBottom:20}}>
            <Grid item xs={6} spacing={4}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Name: </Typography>
                <Typography variant="subtitle1" ml={2}>{(quotDetail.CustomerFirstName ) +" "+ (quotDetail.CustomerLastName)}</Typography>
              </div>
            
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Email: </Typography>
                <Typography variant="subtitle1" ml={2}>{quotDetail.CustomerEmail}</Typography>
              </div>
            </Grid>
            <Grid item xs={6} spacing={4}>               
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Mobile: </Typography>
                <Typography variant="subtitle1" ml={2}>{quotDetail.CustomerMobile}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Credit available: </Typography>
                <Typography variant="subtitle1" ml={2}>{quotDetail.CreditAvailable === true ? "Yes" : "No"}</Typography>
              </div>
            </Grid>
          </Grid>
          <Divider />

          <div style={{ display:"flex", fontSize: 22, fontWeight:"bold", color:"#F7D200", marginTop:10}}>
            <CardHeader title="Quotation Detail" />
          </div>
          <Grid container style={{marginBottom:10}}>
          
            <Grid item xs={6} spacing={4}>
            <div className="div-infocontainer">
                <Typography variant="subtitle2">QuotationDate: </Typography>
                <Typography variant="subtitle1" ml={2}>{(quotDetail.QuotationDate) ? format(parseISO(quotDetail.QuotationDate), "dd MMM yyyy 'at' hh:mm a") : null}</Typography>
              </div>
            
            
          
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Total Amount: </Typography>
                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(quotDetail.TotalAmount)}</Typography>
              </div>
            </Grid>

            <Grid item xs={6} spacing={4}>
            
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Total Items: </Typography>
                <Typography variant="subtitle1" ml={2}>{quotDetail.TotalItems}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Status: </Typography>
                <Typography variant="subtitle1" ml={2}>{quotDetail.Status}</Typography>
              </div>
            </Grid>
        </Grid>  




          <Grid container style={{marginBottom:15}}>
            <div style={{ borderRadius:5, backgroundColor: "#D9D9D9", width:"100%", marginRight:3, paddingInline:15}}>
              <div 
              // onClick={handleProductShow}
              onClick={(e) => onTabViewClick("ProdClicked", e)} 
              style={{display:"flex",  alignItems:"center", justifyContent:"space-between"}}>
                <div style={{fontSize: 22, fontWeight:"bold", marginLeft:-10}}>
                  <CardHeader title="Products Detail" />
                </div>
                <div style={{justifyContent:"flex-end"}}>
                  {!values.showproduct ?
                    <ExpandMoreIcon style={{height:25, width:25}}/>
                    : <ExpandLessIcon style={{height:25, width:25}}/>
                  }
                </div>
              </div>

              {values.showproduct ?
                <div>
                  {(values.QuotDetail.Items).map((p, i) => {
                    return  (  
                      <Card style={{ width:"100%", backgroundColor:"white",  marginBottom:15, borderRadius:5,}}>
                        <div style={{ display:"flex", flexDirection:"row" }}>
                          <CardHeader title={p.Product.BrandName + " - " + p.Product.ProductName} />
                        </div>
                        <Divider />

                        <div style={{ padding:20}}>
                        <Grid container spacing={2} key={i} display="flex">
                           <Grid item xs={3} container >
                             <img src={p.Product.ProductImage} alt={"Prodcutimg"} width="100%" height="100%" style={{borderRadius:5, borderStyle:'outset', borderWidth:1, borderColor:"#C4C4C4",}} />
                           </Grid>
                           <Grid item xs={9} spacing={2} container>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Product Name: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.Product.ProductName}</Typography>
                               </div>
                             </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Brand Name: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.Product.BrandName}</Typography>
                               </div>
                             </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Unit: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.Product.ProductSize}</Typography>
                               </div>
                            </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Our Price: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(p.QuotationPrice)}</Typography>
                               </div>
                             </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Vendor Price: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(p.VendorPrice)}</Typography>
                             </div>
                             </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Vender Name: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.VendorName}</Typography>
                               </div>
                             </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Quantity: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.Quantity}</Typography>
                               </div>
                             </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Transportation: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.Transportation}</Typography>
                               </div>
                             </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                <Typography variant="subtitle2">Delivery Time: </Typography>
                                <Typography variant="subtitle1" ml={2}>{p.DeliveryTime}</Typography>
                              </div>
                             </Grid>
                           {/* </Grid> */}
                          {/* <Grid item xs={12} spacing={2} container> */}
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Discount: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(p.Discount)}</Typography>
                               </div>
                             </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Service Charges: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(p.ServiceCharge)}</Typography>
                               </div>
                             </Grid>
                             <Grid item xs={4}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Delivery Charges: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(p.DeliveryCharge)}</Typography>
                               </div>
                             </Grid>
                           </Grid>
                             <Grid item xs={3}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Status: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.Status}</Typography>
                               </div>
                             </Grid>
                            <Grid item xs={3}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Address Name: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.DeliveryOrPickupAddress.AddressName}</Typography>
                               </div>
                             </Grid>
                             <Grid item xs={3}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Address: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.DeliveryOrPickupAddress.Address}</Typography>
                               </div> 
                             </Grid>
                             <Grid item xs={3}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Area: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.DeliveryOrPickupAddress.Area}</Typography>
                               </div>   
                             </Grid>
                             <Grid item xs={3}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">City & State: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.DeliveryOrPickupAddress.City + ", " + p.DeliveryOrPickupAddress.State}</Typography>
                               </div>   
                             </Grid>
                             <Grid item xs={3}>
                               <div className="div-infocontainer">
                                 <Typography variant="subtitle2">Pin Code: </Typography>
                                 <Typography variant="subtitle1" ml={2}>{p.DeliveryOrPickupAddress.Pincode}</Typography>
                               </div> 
                             </Grid>
                         </Grid>
                        </div>
                      </Card>
                    )
                  })}
                </div>
                : null 
              }
            </div>

          </Grid>
        
        </CardContent>
      </Card>

      <Card style={{ marginTop: 25}}>
        <CardHeader title="Nodes Section" />
        <Divider />
        <Grid container style={{marginbottom:15}}>
          <div style={{ borderRadius:5, backgroundColor: "#D9D9D9", width:"100%", paddingInline:15, margin:20}}>
            <div 
            // onClick={handleCommentShow}
            onClick={(e) => onTabViewClick("CmntClicked", e)} 
            style={{display:"flex",  alignItems:"center", justifyContent:"space-between"}}>
              <div style={{fontSize: 22, fontWeight:"bold", marginLeft:-10}}>
                <CardHeader title="Show Nodes" />
              </div>
              <div style={{justifyContent:"flex-end"}}>
                {!values.showcomment ?
                  <ExpandMoreIcon style={{height:25, width:25}}/>
                  : <ExpandLessIcon style={{height:25, width:25}}/>
                }
              </div>
            </div>
            {values.showcomment && values.QuotDetail.Comments.length != 0 ?
              <div style={{ width:"100%", backgroundColor:"white",  marginBottom:15, borderRadius:5, paddingInline:10, padding:10}}>
                
                {values.QuotDetail.Comments.map((c, i) => {
                  return  ( 
                    <Grid container spacing={2} key={i} display="flex">
                      <Grid item xs={2} container >
                        <div className="div-infocontainer">
                          <Typography variant="subtitle1" ml={2}>{c.CommentBy + ": "}</Typography>
                        </div>                           
                      </Grid>

                      <Grid item xs={10} container>
                        <div className="div-infocontainer">
                          <Typography variant="subtitle1" ml={2}>{c.Comment}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  )
                })}
              </div>
              : null 
            }
          </div>
        </Grid>

        <CardContent sx={{ paddingX: 2.5, paddingY: 2 }}>
          <Grid item xs={12} >
            <TextField fullWidth multiline size="medium" label="Add node" value={values.Comment} name="Comment" onChange={handleChange} />
          </Grid>
        </CardContent>
        
        <Divider />

        <div style={{textAlign: "center", padding: 16}}>
          {(errorMsg && errorMsg !== "") && (
            <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
          )}
          <Button variant='contained' color='secondary' onClick={handleCommentSubmit}>Add Node</Button>
        </div>
      </Card>

      </div>
  )
}
                  

                    