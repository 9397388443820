import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

export default function AddQuotationPage(props) {

  const navigate = useNavigate();

  const mount = () => {
    //dispatch(fetchProducts({}));
  }
  // eslint-disable-next-line
  useEffect(mount, []);

  
  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Add Quotation" />
            <div
              style={{ display:"flex", 
                flexDirection:"row", 
                alignItems:"center", 
                justifyContent:"center", 
                marginLeft:"auto", 
                marginRight:18 
              }} >
              <Button variant='contained' color="error" onClick={() => { navigate("/quotations") }}>Back</Button>
            </div>
        </div>
        <Divider />
        <CardContent>
          <Typography variant="body2">
            Page Content
          </Typography>
        </CardContent>        
      </Card>
    </div>
  );
}