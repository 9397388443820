import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { getFormatedCurrency, getFormatedDate } from '../components/util';
import { fetchCustomers } from '../store/actions/customer'; 
import { fetchOdrItemListForDChallan, ListOutDChallan } from '../store/actions/deliveryChallan';
import { fetchAllTransporters, fetchDrivers, fetchVehicles } from '../store/actions/transporter';
import { fetchCustomerAddress } from '../store/actions/customer'; 



export default function AddDeliveryChallanPage(props) {

  const [values, setValues] = useState({

    CustomerId:"",
    OrderPONumber:"",
    OrderItemIds: [],
    DeliveryChallanDate: new Date(),
    DeliveryDate: new Date(),
    TransporterId: "",
    VehicleId: "", 
    DriverId: "",
    ReceiverName: "",
    ReceiverMobile: "", 
    BilliingAdrId:"",
    DeliveryAdrId:"",
  });

  // console.log("delivery challan date");
  // console.log(values.DeliveryDate);
  // console.log(format(values.DeliveryDate, "yyyy-MM-dd HH:mm:ss"));
  // console.log(values.DeliveryDate);
  // console.log(values.TransporterId);
  // console.log(values.VehicleId);
  // console.log(values.DriverId);
  // console.log(values.ReceiverName);
  // console.log(values.ReceiverMobile);
  // console.log(values.BilliingAdrId);
  // console.log(values.DeliveryAdrId);
  // console.log(values);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorMsgOnAdd, setErrorMsgOnAdd] = useState(null);
  const customers = useSelector(state => (state.customer.customers || []));
  // const isCustomerFetching = useSelector(state => (state.customer.isCustomerFetching ));
  const orderItemList = useSelector(state => (state.deliveryChallan.orderItemList || []));
  // const isOdrItemListFetching = useSelector(state => (state.deliveryChallan.isOdrItemListFetching ));
  const isOrderItemListFetched = useSelector(state => (state.deliveryChallan.isOrderItemListFetched));
  /* Transporters */
  // const isAllTransporterListFetching = useSelector(state => (state.transporter.isAllTransporterListFetching ));
  const allTransporters = useSelector(state => (state.transporter.allTransporters || []));
  // const isDriversFetching = useSelector(state => (state.transporter.isDriversFetching ));
  const drivers = useSelector(state => (state.transporter.drivers || []));
  // const isVehicleFaching = useSelector(state => (state.transporter.isVehicleFaching ));
  const vehicles = useSelector(state => (state.transporter.vehicles || []));
  const customerAddress = useSelector(state => (state.customer.customerAddress || []));


  // console.log("data fachedddddddd");
  // console.log(orderItemList && orderItemList.length > 0 ? orderItemList[0].CustomerId : values.CustomerId);

  const mount = () => {
    dispatch(fetchCustomers());
    dispatch(fetchOdrItemListForDChallan());
    dispatch(fetchAllTransporters());
    // dispatch(fetchDrivers("733cfa30-abcc-412f-9076-afdf01781a33"));
    // dispatch(fetchVehicles(values.TransporterId));
  }

  useLayoutEffect(mount, []);

  useEffect( () =>{
    /* return () => {

    } */
    
    //All this info is there in quotDetail
   
    console.log("order detail updated");
    if(orderItemList && orderItemList.length > 0) {
      setValues({
        ...values,
        ReceiverName : orderItemList && orderItemList.length > 0 ? orderItemList[0].ReceiverName : values.ReceiverName,
        ReceiverMobile : orderItemList && orderItemList.length > 0 ? orderItemList[0].ReceiverMobile : values.ReceiverMobile,
        CustomerId : orderItemList && orderItemList.length > 0 ?  orderItemList[0].CustomerId : values.CustomerId,
        BilliingAdrId :  orderItemList && orderItemList.length > 0 && orderItemList[0].BillingAddress ? orderItemList[0].BillingAddress.AddressId : values.BilliingAdrId,
        DeliveryAdrId :  orderItemList && orderItemList.length > 0 && orderItemList[0].OrderItems && orderItemList[0].OrderItems.length > 0 ? orderItemList[0].OrderItems[0].DeliveryAddressId : values.DeliveryAdrId,
      });
    }
  }, [orderItemList]);


  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };

  const isInputValid = () => {
    let isInputValid = true;
    let invalidFieldName = "";
    let errorMsg = "";

    if(values.OrderPONumber === "" && values.CustomerId === "" || values.CustomerId === null){
      isInputValid = false;
      errorMsg = "Please Enter the Order PONumber or Select Customer.";
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  }

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
    setErrorMsgOnAdd(null);

  };

  const handleTrspChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsgOnAdd(null);
    const search = { 
      TransporterId: event.target.value 
    };
    dispatch(fetchDrivers(search));
    dispatch(fetchVehicles(search));
    
  };

  const hamdleSearch = () => {
    if(isInputValid()){

      var SearchParams = {
        OrderPONumber: values.OrderPONumber,
        CustomerId: values.CustomerId,
      }
      dispatch(fetchOdrItemListForDChallan(SearchParams));
      dispatch(fetchCustomerAddress(values.CustomerId));
      // setValues({ ...values, CustomerId: orderItemList.CustomerId, })
    }
  }

  const handleAddDChallan = () => {
    let isInputValid = true;
    let errorMsgOnAdd = "";

    if( values.DeliveryChallanDate === null || values.DeliveryDate === null || values.TransporterId === "" || 
        values.VehicleId === "" || values.DriverId === "" || values.BilliingAdrId === "" || values.DeliveryAdrId === "" || !values.OrderItemIds.length){
      isInputValid = false;
      errorMsgOnAdd = "Please fill all the * marked fields.";
      setErrorMsgOnAdd( errorMsgOnAdd );
    }
    else {
      const sendParams = {
        OrderItemId: values.OrderItemIds,
        DeliveryChallanDate: format(values.DeliveryChallanDate, "yyyy-MM-dd HH:mm:ss"),
        DeliveryDate: format(values.DeliveryDate, "yyyy-MM-dd HH:mm:ss"),
        TransporterId: values.TransporterId,
        VehicleId: values.VehicleId,
        DriverId: values.DriverId,
        ReceiverName: values.ReceiverName,
        ReceiverMobile: values.ReceiverMobile,
        BillingAddressId: values.BilliingAdrId,
        DeliveryAddressId: values.DeliveryAdrId,
      }
      // DeliveryDate: new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: '2-digit', day: '2-digit',  hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(values.DeliveryDate),
      // DeliveryChallanDate: new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(values.DeliveryChallanDate),

      console.log("updated values for pass");
      console.log(sendParams);
      dispatch(ListOutDChallan(sendParams));
      navigate("/deliveryChallan");
    }
    
  }

  const handleCheckBoxClick = (e) => {
    const { value, checked } = e.target;
    const { OrderItemIds } = values;

    if(checked){
      OrderItemIds.push(parseInt(value));
    }
    else {
      const index = OrderItemIds.indexOf(parseInt(value));
      OrderItemIds.splice(index, 1);
    }
  }

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Add Delivery Challan" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/deliveryChallan") }}>Back</Button>
          </div>
        </div>
        <Divider />
        <CardContent>

          <Grid container style={{marginBottom:10, justifyContent:"space-between", alignItems:"center", marginTop:20}}>
            
            <Grid item xs={5.2} spacing={4} >
              <div className="div-infocontainer" >
                <TextField 
                  // required                    
                  fullWidth label="Search by Order PONumber" value={values.OrderPONumber} name="OrderPONumber" onChange={handleChange} 
                />
              </div>
            </Grid>
            
            <Grid item xs={5.2} spacing={4} >             
              <div className="div-infocontainer">
                <FormControl /* required */ sx={{ width: "100%" }}>
                  <InputLabel id="demo-controlled-open-select-label">Search by Customer</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    // value={values && values.CustomerId && values.CustomerId === "" && orderItemList && orderItemList.length > 0 ? orderItemList[0].CustomerId : values.CustomerId }
                    // value={orderItemList && orderItemList.length > 0 ? orderItemList[0].CustomerId : values.CustomerId}
                    value={values.CustomerId}
                    label="Search by Customer"
                    name= "CustomerId"
                    onChange={handleChange}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={""}> <em>None</em> </MenuItem>
                    {customers.map((c, i) => {
                      return <MenuItem value={c.Id} key={"cus_" +i}>{c.FirstName + " " + c.LastName}</MenuItem >
                    })}
                  </Select>
                </FormControl>
              </div>  
            </Grid>   

            <Grid item xs={1.5} spacing={4}> 
              <div style={{ display:"flex", justifyContent:"center"}}>
                <Button variant='contained' color='secondary' onClick={hamdleSearch} >Search</Button>
              </div>
            </Grid>

          </Grid>
          {(errorMsg && errorMsg != null) ?    
            <div style={{textAlign: "center", paddingTop: 5}}>
              {(errorMsg && errorMsg !== "") && (
                <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
              )}
            </div> 
          :null}

          {(orderItemList && orderItemList.length <= 0 && isOrderItemListFetched === true/*  && isOrderItemListFetched === true */) ?    
            <div style={{textAlign: "center", paddingTop: 5}}>
                <Typography mb={2} variant="body2" color='error' >No Date Available on Selected Details</Typography>
            </div> 
          :null}

        </CardContent>        

        {(orderItemList && orderItemList.length > 0) ?

          <Grid container>
            {orderItemList.map((o, i) => {
              return  ( 
                <div key={"odr_itm_list_" +i}  style={{ borderRadius:5, backgroundColor:"#D9D9D9", width:"100%", paddingInline:15, margin:20, marginTop:0, borderWidth:1.5, borderColor:"orange", borderStyle:'solid', paddingTop:5, paddingBottom:5}}>
                  <div style={{display:"flex",  alignItems:"center", justifyContent:"space-between", paddingTop:11, paddingBottom:11}}>
                   
                    <Grid item xs={4} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Order Date: {(o.OrderDate) ? format(parseISO(o.OrderDate), "dd MMM yyyy") : null}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={4} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Order PONumber: {o.OrderPONumber}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={4} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">PONumber: {o.PONumber}</Typography>
                    </Grid>
                    
                  </div>
                  {o && o.OrderItems && o.OrderItems.length > 0 ?
                    <div  style={{width:"100%", backgroundColor:"white",  marginBottom:15, borderRadius:5,}}>
                      {(o.OrderItems || []).map((h, j) => {
                        return (
                          <div key={"odr_itms_" + j}>
                            <div style={{display:"flex",padding:8, paddingInline:12, alignContent:"center"}}>
                              <Grid item xs={0.5} style={{alignSelf:"center"}}>
                                <Checkbox
                                  name='languages'
                                  value={h.OrderItemId}
                                  // onChange={(e) => handleCheckBoxClick(e,i,j)}
                                  onChange={handleCheckBoxClick}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </Grid>
                              <Grid item xs={3.2} style={{fontSize: 13, fontWeight:"bold", paddingTop:5, paddingBottom: 5, alignSelf:"center"}}>
                                <Typography variant="subtitle">{h.Product.ProductName + " - " + h.Product.BrandName + " (" + h.Product.ProductSize + ")"} </Typography>
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid item xs={2.2} style={{textAlign:"center",  alignSelf:"center"}}>
                                <Typography variant="subtitle2">Status: {h.Status}</Typography>
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid item xs={2.2} style={{textAlign:"center",  alignSelf:"center"}}>
                                <Typography variant="subtitle2">Quantity: {h.OrderQuantity}</Typography>
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid item xs={2.2} style={{textAlign:"center",  alignSelf:"center"}}>
                                <Typography variant="subtitle2">Price: {getFormatedCurrency(h.OrderItemPrice)}</Typography>
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid item xs={2.2} style={{textAlign:"center",  alignSelf:"center"}}>
                                <Typography variant="subtitle2">Amount: {getFormatedCurrency(h.OrderQuantity*h.OrderItemPrice)}</Typography>
                              </Grid>
                            </div>
                            <Divider />
                          </div>                    
                        )
                      })} 
                    </div>
                  : null}
                </div>
              )
            })}
                
            <Grid container style={{marginBottom:10, justifyContent:"space-between", alignItems:"center", margin:20, marginTop:0,}}>
              <Grid container style={{marginBottom:10, alignItems:"center"}}>
                <Grid item xs={2} spacing={4} >
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">Delivery Challan Date:* </Typography>
                  </div>    
                </Grid>
                <Grid item xs={4} spacing={4}>
                  <DatePicker
                    required  
                    selected={values.DeliveryChallanDate}
                    showIcon
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    popperClassName="date-popper"
                    onChange={(date) => setValues({ ...values, DeliveryChallanDate: date})}
                    className="dt-picker-grid4"
                    name='DeliveryChallanDate'
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={60}
                    placeholderText="Delivery Challan Date"
                  />
                </Grid>
                <Grid item xs={2} spacing={4}>
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">Expected Delivery Date:* </Typography>
                  </div>    
                </Grid>
                <Grid item xs={4} spacing={4}>
                  <DatePicker  
                    required
                    selected={values.DeliveryDate}
                    showIcon
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    popperClassName="date-popper"
                    onChange={(date) => setValues({...values, DeliveryDate : date})}
                    className="dt-picker-grid4"
                    name='DeliveryDate'
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={60}
                    placeholderText="Expected Delivery Date"
                  />
                </Grid>

              </Grid>
              <Grid container style={{marginBottom:10}}> 
                <Grid item xs={4} spacing={4}>             
                  <div className="div-infocontainer">
                    <FormControl required sx={{ width: "100%" }}>
                      <InputLabel id="demo-controlled-open-select-label">Select Transporter</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={values.TransporterId}
                        label="Select Transporter"
                        name= "TransporterId"
                        onChange={handleTrspChange}
                        MenuProps={MenuProps}
                      >
                        {allTransporters.map((t, i) => {
                          return <MenuItem value={t.Id} key={"all_trns_" +i}>{t.FirstName + " " + t.LastName}</MenuItem >
                        })}
                      </Select>
                    </FormControl>
                  </div>  
                </Grid>
                <Grid item xs={4} spacing={4}>             
                  <div className="div-infocontainer">
                    <FormControl required sx={{ width: "100%" }}>
                      <InputLabel id="demo-controlled-open-select-label">Select Vehicle</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={values.VehicleId}
                        label="Select Vehicle"
                        name= "VehicleId"
                        onChange={handleChange}
                        MenuProps={MenuProps}
                      >
                        {vehicles.map((v, i) => {
                          return <MenuItem value={v.Id} key={"Vehicle_" +i}>{v.VehicleNo}</MenuItem >
                        })}
                      </Select>
                    </FormControl>
                  </div>  
                </Grid>
              
                <Grid item xs={4} spacing={4}>             
                  <div className="div-infocontainer">
                    <FormControl required sx={{ width: "100%" }}>
                      <InputLabel id="demo-controlled-open-select-label">Select Driver</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={values.DriverId}
                        label="Select Driver"
                        name= "DriverId"
                        onChange={handleChange}
                        MenuProps={MenuProps}
                      >
                        {drivers.map((d, i) => {
                          return <MenuItem value={d.Id} key={"drvr_" +i}>{d.FirstName + " " + d.LastName}</MenuItem >
                        })}
                      </Select>
                    </FormControl>
                  </div>  
                </Grid>
              </Grid>
              <Grid container style={{marginBottom:10, alignItems:"center"}}>
                <Grid item xs={4} spacing={4}>
                  <div className="div-infocontainer" >
                    <TextField 
                      // required                    
                      fullWidth label="Receiver Name" value={values.ReceiverName || ""} name="ReceiverName" onChange={handleChange} 
                    />
                  </div>
                </Grid>
                <Grid item xs={8} spacing={4}>
                  <div className="div-infocontainer" >
                    <FormControl required sx={{ width: "100%" }}>
                      <InputLabel id="demo-controlled-open-select-label">Billing Address</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={values.BilliingAdrId}
                        label="Billing Address"
                        name='BilliingAdrId'
                        onChange={handleChange}
                        MenuProps={MenuProps}
                      >
                        {(customerAddress || []).map((a, i) => {
                          return <MenuItem value={a.AddressId} key={"cus_Add_bill_" + i}>{a.AddressName + ", " + a.Address + ", " + a.Landmark + ", " + a.Area + ", " + a.City + ", " + a.State + ", " + a.Pincode}</MenuItem >
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
              <Grid container style={{marginBottom:10, alignItems:"center"}}>
                <Grid item xs={4} spacing={4}>
                  <div className="div-infocontainer" >
                    <TextField 
                      // required
                      // maxLength={5}    
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                      }}                
                      fullWidth label="Receiver Mobile Number" type="number" value={values.ReceiverMobile || ""} name="ReceiverMobile" onChange={handleChange} 
                    />
                  </div>
                </Grid>
                <Grid item xs={8} spacing={4}>
                  <div className="div-infocontainer" >
                    <FormControl required sx={{ width: "100%" }}>
                      <InputLabel id="demo-controlled-open-select-label">Delivery Address</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={values.DeliveryAdrId}
                        label="Delivery Address"
                        name='DeliveryAdrId'
                        onChange={handleChange}
                        MenuProps={MenuProps}
                      >
                        {(customerAddress || []).map((a, i) => {
                          return <MenuItem value={a.AddressId} key={"cus_Add_deli_" + i}>{a.AddressName + ", " + a.Address + ", " + a.Landmark + ", " + a.Area + ", " + a.City + ", " + a.State + ", " + a.Pincode}</MenuItem >
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
             
            </Grid>   


          </Grid> 
        : null} 
       

        {(orderItemList && orderItemList != "") ?
          <div>
            <Divider />
            <div style={{textAlign:"center", padding: 16}}>
                {(errorMsgOnAdd && errorMsgOnAdd != null) ?    
                  <div style={{textAlign: "center", padding: 16}}>
                    {(errorMsgOnAdd && errorMsgOnAdd !== "") && (
                      <Typography mb={2} variant="body2" color='error' >{errorMsgOnAdd}</Typography>
                    )}
                  </div> 
                :null}
              <Button variant='contained' color='secondary' onClick={handleAddDChallan} >Add Delivery Challan</Button>
            </div>
          </div>
        : null}
      </Card>
    </div>
  );
}








