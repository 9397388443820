import { combineReducers } from 'redux';
import session from './session';
import product from './product';
import services from './services';
import addresses from './addresses';
import customer from './customer';
import vendor from './vendor';
import quotation from './quotation';
import invoice from './invoice';
import deliveryChallan from './deliveryChallan';
import transporter from './transporter';
import { AUTH_LOGOUT } from '../actions/session';

const appReducer = combineReducers({
  session,
  product,
  services,
  addresses,
  customer,
  vendor,
  quotation,
  invoice,
  deliveryChallan,
  transporter
  
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;
