import React, { useState, useEffect, useLayoutEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ReplayOutlined } from '@mui/icons-material';
import { concat } from 'lodash-es';

import { useNavigate, useLocation } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProductTypes, fetchBrands, fetchProductSizes } from '../store/actions/services';
import { fetchServiceLocations } from '../store/actions/addresses';
import { fetchProductDetail, deleteBannerImage, deleteSmallImage, updateProduct, fetchProducts } from '../store/actions/product';
import { RupeeSymbol } from '../components/util';

import "../css/editproductpage.css";



export default function EditProductPage(props) {

  const [values, setValues] = useState({
    Id: '',
    Bannerimg: [],
    Smallimg: '', 
    Name: '', 
    BrandId: '', 
    ListPrice: '', 
    OriginalPrice: '', 
    ProductTypeId: '',
    Description:'',
    Size: '',
    Information: '',
    Features: '',
    Disclaimer: '',
    DelInstruction: '',
    TaxAmount: '',
    Offertxt: '',
    PackingCharge: '',
    DelCharge: '',
    DelDays: '',
    ServiceLocId: [],
    sltd_Loc: 0,
    MinimumOrderQuantity: '', 
    MinimumBulkOrderQuantity: '',
    VehicleCapacity:''
  });
  const [ editimgflags, seteditimgflags ] = useState({ 
    editBannerimg: false,
    editSmallimg: false
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const [OpenBIMG_dialog, setBIMG_dialog] = useState(false);
  const [OpenSIMG_dialog, setSIMG_dialog] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentProduct = useSelector(state => (state.product.currentProduct || {}));
  const productTypes = useSelector(state => (state.services.productTypes || []));
  const brands = useSelector(state => (state.services.brands || []));
  const serviceLocations = useSelector(state => (state.addresses.serviceLocations || []));
  const productSizes = useSelector(state => (state.services.productSizes || []));
  const isPRUpdating = useSelector(state => (state.product.isPRUpdating));
  var bannerimgs;

  const location = useLocation();

  const mount = () => {
    dispatch(fetchProductTypes());
    dispatch(fetchBrands());
    // dispatch(fetchProductDetail(location.state.productId));
    dispatch(fetchProductDetail({Id: location.state.productId, ServiceLocationId: values.sltd_Loc}))
    dispatch(fetchProductSizes());
    dispatch(fetchServiceLocations());
  }
  // eslint-disable-next-line
  useLayoutEffect(mount, []);

  useEffect( () =>{
    
    if(currentProduct){

      if(currentProduct && currentProduct.ImageURL && currentProduct.ImageURL.BannerImage && currentProduct.ImageURL.BannerImage.length){
        bannerimgs = currentProduct.ImageURL.BannerImage;
      }
        
      //console.log("banner images");
      //console.log(bannerimgs);
      
      setValues({ 
        Id: (currentProduct && currentProduct.Id) || "", 
        Bannerimg: bannerimgs || [],
        Smallimg: (currentProduct && currentProduct.ImageURL && currentProduct.ImageURL.SmallImage && currentProduct.ImageURL.SmallImage.length) ? currentProduct.ImageURL.SmallImage : "", 
        Name: (currentProduct && currentProduct.Name) || "",
        BrandId: (currentProduct && currentProduct.BrandId) || "",
        ListPrice: (currentProduct && currentProduct.ListPrice) || "",
        OriginalPrice: (currentProduct && currentProduct.OriginalPrice) || "",
        ProductTypeId: (currentProduct && currentProduct.ProductTypeId) || "",
        Description: (currentProduct && currentProduct.Description) || "", 
        Size: (currentProduct && currentProduct.Size) || "",
        Information: (currentProduct && currentProduct.Information) || "",
        Features: (currentProduct && currentProduct.Features) || "",
        Disclaimer: (currentProduct && currentProduct.Disclaimer) || "",
        DelInstruction: (currentProduct && currentProduct.DeliveryInstruction) || "",
        TaxAmount: (currentProduct && currentProduct.TaxAmount) || "",
        Offertxt: (currentProduct && currentProduct.OfferText) || "",
        PackingCharge: (currentProduct && currentProduct.PackingCharge) || "",
        DelCharge: (currentProduct && currentProduct.DeliveryCharge) || "",
        DelDays: (currentProduct && currentProduct.DeliveryDays) || "",
        /* ServiceLocId: (currentProduct && currentProduct.ServiceLocId) || [], */
        MinimumOrderQuantity: (currentProduct && currentProduct.MinOrderQuantity) || "",
        MinimumBulkOrderQuantity: (currentProduct && currentProduct.MinBulkOrderQuantity) || "",
        VehicleCapacity:(currentProduct && currentProduct.VehicleCapacity) || ""
      });

      //console.log("values")
      //console.log(values);
    }
  }, [currentProduct]);

  
  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);

  const handleUploadImgBtn = (fieldname) => {
    //method to Link FileInput element
    if(fieldname === "BannerImg"){
      hiddenFileInput1.current.click();
    }else{
      hiddenFileInput2.current.click();
    }
  };

  const handleEditImg = (imgname) => (event) =>{
    //edit img
    if(imgname === "bannerimg"){
      //var img = event.target.files[0];
      let files = event.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = (e) => {
        // console.log("bannerimg base64")
        // console.log(e.target.result)
        var imgarray = [].concat(values.Bannerimg).concat([e.target.result]);
        setValues({ ...values, Bannerimg: imgarray });
      }
    }
    else if(imgname === "smallimg"){
      //var img = event.target.files[0];
      let files = event.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = (e) => {
        // console.log("smallimg base64")
        // console.log(e.target.result)
        setValues({ ...values, Smallimg: e.target.result });
      }
    }
  }

  const deleteBannerImg = (productId, bannerImgId) => {
    dispatch(deleteBannerImage(productId, bannerImgId)).then(r => dispatch(fetchProductDetail(location.state.productId)));
    setBIMG_dialog(false);
  };

  const deleteSmallImg = (fieldname, productId) => {
    if(fieldname === "SmallImg"){
      dispatch(deleteSmallImage(productId)).then(r => dispatch(fetchProductDetail(location.state.productId)));
      setSIMG_dialog(false);
    }
  };

  const handleSelectChange = (fieldname) => (event) => {
    if(fieldname === "ProductType"){
      setValues({ ...values, ProductTypeId: event.target.value });
    }
    if(fieldname === "Brand"){
      setValues({ ...values, BrandId: event.target.value });
    }
    if(fieldname === "Size"){
      setValues({ ...values, Size: event.target.value });
    }
    if(fieldname === "ServiceLocId"){
      setValues({ ...values, ServiceLocId: event.target.value });
    }
  };

  const isInputValid = () => {
    let isInputValid = true;
    let invalidFieldName = "";
    let errorMsg = "";

    if(!values.Bannerimg.length || values.Smallimg === "" || values.Name === "" || values.ListPrice === "" || values.OriginalPrice === "" ||
    values.ProductTypeId === "" || values.Size === "" || values.Description === "" || values.DelDays === "" /*|| values.ServiceLocId.length == 0*/ /* ||
    values.MinimumOrderQuantity === ""  *//* || values.MinimumBulkOrderQuantity === "" */){
      isInputValid = false;
      errorMsg = "Please fill all the * marked fields.";
      // invalidFieldName = "BannerImg";
    }
    else if(parseInt(values.ListPrice) > parseInt(values.OriginalPrice)){
      isInputValid = false;
      errorMsg = "Original Price should be more than List Price.";
    }
    setErrorMsg( errorMsg );
    //setinvalidFieldName( invalidFieldName );

    return isInputValid ;
  };

  const handleSubmit = () => {
    
    if(isInputValid()){
      var product;
 
      function getProduct (product) {
        if( document.getElementById("BannerImgFInput").files.length == 0 && document.getElementById("SmallImgFInput").files.length == 0 ){
          return product = {
            Id: values.Id,
            Name: values.Name,
            BrandId: values.BrandId,
            ListPrice: values.ListPrice,
            OriginalPrice: values.OriginalPrice,
            ProductTypeId: values.ProductTypeId,
            Description: values.Description,
            Size: values.Size,
            Information: values.Information,
            Features: values.Features,
            Disclaimer: values.Disclaimer,
            DeliveryInstruction: values.DelInstruction,
            OfferText: values.Offertxt,
            TaxAmount: values.TaxAmount,
            PackingCharge: values.PackingCharge, 
            DeliveryCharge: values.DelCharge, 
            DeliveryDays: values.DelDays,
            MinOrderQuantity: values.MinimumOrderQuantity,
            MinBulkOrderQuantity: values.MinimumBulkOrderQuantity,
            VehicleCapacity: values.VehicleCapacity
            //ServiceLocationId: values.ServiceLocId
          };
        }
        if( document.getElementById("BannerImgFInput").files.length == 0 ){
          return product = {
            Id: values.Id,
            SmallImage: values.Smallimg,
            Name: values.Name,
            BrandId: values.BrandId,
            ListPrice: values.ListPrice,
            OriginalPrice: values.OriginalPrice,
            ProductTypeId: values.ProductTypeId,
            Description: values.Description,
            Size: values.Size,
            Information: values.Information,
            Features: values.Features,
            Disclaimer: values.Disclaimer,
            DeliveryInstruction: values.DelInstruction,
            OfferText: values.Offertxt,
            TaxAmount: values.TaxAmount,
            PackingCharge: values.PackingCharge, 
            DeliveryCharge: values.DelCharge, 
            DeliveryDays: values.DelDays,
            MinOrderQuantity: values.MinimumOrderQuantity,
            MinBulkOrderQuantity: values.MinimumBulkOrderQuantity,
            VehicleCapacity: values.VehicleCapacity,
            //ServiceLocationId: values.ServiceLocId
          };
        }
        if( document.getElementById("SmallImgFInput").files.length == 0 ){
          return product = {
            Id: values.Id,
            BannerImage: values.Bannerimg,
            Name: values.Name,
            BrandId: values.BrandId,
            ListPrice: values.ListPrice,
            OriginalPrice: values.OriginalPrice,
            ProductTypeId: values.ProductTypeId,
            Description: values.Description,
            Size: values.Size,
            Information: values.Information,
            Features: values.Features,
            Disclaimer: values.Disclaimer,
            DeliveryInstruction: values.DelInstruction,
            OfferText: values.Offertxt,
            TaxAmount: values.TaxAmount,
            PackingCharge: values.PackingCharge, 
            DeliveryCharge: values.DelCharge, 
            DeliveryDays: values.DelDays,
            MinOrderQuantity: values.MinimumOrderQuantity,
            MinBulkOrderQuantity: values.MinimumBulkOrderQuantity,
            VehicleCapacity: values.VehicleCapacity
            //ServiceLocationId: values.ServiceLocId
          };
        }
        if( document.getElementById("BannerImgFInput").files.length != 0 && document.getElementById("SmallImgFInput").files.length != 0){
          return product = {
            Id: values.Id,
            BannerImage: values.Bannerimg,
            SmallImage: values.Smallimg,
            Name: values.Name,
            BrandId: values.BrandId,
            ListPrice: values.ListPrice,
            OriginalPrice: values.OriginalPrice,
            ProductTypeId: values.ProductTypeId,
            Description: values.Description,
            Size: values.Size,
            Information: values.Information,
            Features: values.Features,
            Disclaimer: values.Disclaimer,
            DeliveryInstruction: values.DelInstruction,
            OfferText: values.Offertxt,
            TaxAmount: values.TaxAmount,
            PackingCharge: values.PackingCharge, 
            DeliveryCharge: values.DelCharge, 
            DeliveryDays: values.DelDays,
            MinOrderQuantity: values.MinimumOrderQuantity,
            MinBulkOrderQuantity: values.MinimumBulkOrderQuantity,
            VehicleCapacity: values.VehicleCapacity
            //ServiceLocationId: values.ServiceLocId
          };
        }
      };
      //console.log("product");
      //console.log(getProduct(product));
      dispatch(updateProduct(getProduct(product))).then(r => dispatch(fetchProducts({})));
      navigate('/products');
      
    }
  };

  //console.log("small img")
  //console.log(values.Smallimg)

  console.log("");
  console.log();
  console.log("");
  console.log();


  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Edit Product" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/products") }}>Cancel</Button>
          </div>
        </div>
        
        <Divider />
        
        <CardContent>
          <div>
            <Card>
              {currentProduct && (
                  <CardContent sx={{p: 4}}>
                    <Grid container /* spacing={2} */ >
                      <Grid container xs={6} alignItems="center" justifyContent="center" flexDirection="column" >
                        
                        <Typography variant="h5" >Banner Image*</Typography>
                        <Typography variant="subtitle1" mb={2} >Image should be (1024 x 410px)</Typography>
                        
                        {(values.Bannerimg && values.Bannerimg.length) ?
                          <ImageList sx={{ width: 500 }} cols={2} rowHeight={164}> 
                              {values.Bannerimg.map((b_img, i) => (
                                <ImageListItem key={i}>
                                  <img src={ (b_img.ImageURL) ? b_img.ImageURL : b_img }
                                    width={300} 
                                    height={225}
                                    style={{ objectFit: "contain"}}
                                    loading="lazy"
                                  />
                                  <ImageListItemBar sx={{ background:"transparent" }}
                                    position="top"
                                    actionIcon={
                                      <IconButton
                                        sx={{ backgroundColor: "white" }}
                                        onClick={() => setBIMG_dialog(true)}
                                      >
                                        <DeleteIcon color="error" />
                                      </IconButton>
                                    }
                                  />
                                  <Dialog open={OpenBIMG_dialog} onClose={() => setBIMG_dialog(false)} >
                                    <DialogTitle >
                                      {"Delete Image"}
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText >
                                        Are you sure you wish to delete this Image ?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={() => deleteBannerImg(values.Id, b_img.Id)}>Yes</Button>
                                      <Button onClick={() => setBIMG_dialog(false)} autoFocus>
                                        No
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </ImageListItem>
                                ))}
                          </ImageList> 
                          : null
                        }

                        {/* This is masked Button for hiddenFileInput */}
                        <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn("BannerImg")}>
                          Upload Image
                        </Button>
                        <input ref={hiddenFileInput1} id="BannerImgFInput" style={{display:'none'}} required className="choosefile-input" type="file" onChange={handleEditImg("bannerimg")} />

                        {/* <Button style={{ marginTop: 14 }} variant='contained' color="error" onClick={() => deleteSmallImg("BannerImg", values.Id)}>Delete All Images</Button> */}
                      </Grid>
                      
                      <Grid container xs={6} alignItems="center" justifyContent="center" flexDirection="column" >
                        <Typography variant="h5" >Small Image*</Typography>
                        <Typography variant="subtitle1" mb={2} >Image should be (512 x 477px)</Typography>
                        
                        {/* <img src={values.Smallimg} width="170px" height="170px" /> */}
                        {(values.Smallimg && values.Smallimg.length) && (
                          <div style={{ position:"relative", marginTop: "12px"}}>
                            <IconButton
                              style={{ position:"absolute", right: 0 }}
                              sx={{ backgroundColor:"white" }}
                              onClick={() => setSIMG_dialog(true)}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                            <img src={values.Smallimg} width={175} height={175}  />
                          </div>
                        )}
                        
                        {/* This is masked Button for hiddenFileInput */}
                        {(!  values.Smallimg ) && (
                          <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn("SmallImg")}>
                            Upload Image
                          </Button>
                        )}
                        <input ref={hiddenFileInput2} id="SmallImgFInput" style={{display:'none'}} required className="choosefile-input" type="file" onChange={handleEditImg("smallimg")} />
                        
                        {/* {(values.Smallimg) && (
                          <Button style={{ marginTop: 14 }} variant='contained' color="error" onClick={() => setSIMG_dialog(true)}>Delete Image</Button>  
                        )} */}
                        <Dialog open={OpenSIMG_dialog} onClose={() => setSIMG_dialog(false)} >
                          <DialogTitle >
                            {"Delete Image"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText >
                              Are you sure you wish to delete this Image ?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => deleteSmallImg("SmallImg", values.Id)}>Yes</Button>
                            <Button onClick={() => setSIMG_dialog(false)} autoFocus>
                              No
                            </Button>
                          </DialogActions>
                        </Dialog>
                        
                      </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 5}} spacing={3}>
                      <Grid item xs={6} >
                        <TextField required fullWidth label="Name" value={values.Name} name="Name" onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={6} >
                        <FormControl required sx={{ width: "100%" }}>
                          <InputLabel id="demo-controlled-open-select-label">Product Type</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={values.ProductTypeId}
                            defaultValue=""
                            label="Product Type"
                            onChange={handleSelectChange("ProductType")}
                            MenuProps={MenuProps}
                          >
                            {productTypes.map((pt, i) => {
                              return <MenuItem  value={pt.Id} key={i}>{pt.Name}</MenuItem >
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      
                      <Grid item xs={6} >
                        <TextField required 
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                          }}
                          fullWidth 
                          label="List Price"
                          type="number"
                          value={values.ListPrice} 
                          name="ListPrice" 
                          onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={6} >
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-controlled-open-select-label">Brand</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={values.BrandId}
                            defaultValue=""
                            label="Brand"
                            onChange={handleSelectChange("Brand")}
                            MenuProps={MenuProps}
                          >
                            {brands.map((b, i) => {
                              return <MenuItem  value={b.Id} key={i}>{b.Name}</MenuItem >
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      
                      <Grid item xs={6} >
                        <TextField required
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                          }}
                          fullWidth
                          error={parseInt(values.ListPrice) > parseInt(values.OriginalPrice)}
                          helperText={parseInt(values.ListPrice) > parseInt(values.OriginalPrice) ? "Original Price should be more than List Price." : ""}
                          type="number"
                          label="Original Price" 
                          value={values.OriginalPrice} 
                          name="OriginalPrice" 
                          onChange={handleChange} />
                      </Grid>

                      <Grid item xs={6}  >
                        <FormControl required sx={{ width: "100%" }}>
                          <InputLabel id="demo-controlled-open-select-label">Size</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={values.Size}
                            defaultValue=""
                            label="Size"
                            onChange={handleSelectChange("Size")}
                            MenuProps={MenuProps}
                          >
                            {productSizes.map((ps, i) => {
                              return <MenuItem  value={ps} key={i}>{ps}</MenuItem >
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} >
                        <TextField 
                        /* required */ 
                          InputProps={{
                            endAdornment: <InputAdornment position="start">{values.Size}</InputAdornment>,
                          }}
                          fullWidth 
                          label="Minimum Order Quantity"
                          // error={parseInt(values.ListPrice) > parseInt(values.OriginalPrice)}
                          // helperText={parseInt(values.ListPrice) > parseInt(values.OriginalPrice) ? "Original Price should be more than List Price." : ""}
                          // type="number" 
                          value={values.MinimumOrderQuantity}
                          name="MinimumOrderQuantity" 
                          onChange={handleChange} />
                      </Grid>

                      <Grid item xs={6} >
                        <TextField
                        /* required */
                          InputProps={{
                            endAdornment: <InputAdornment position="start">{values.Size}</InputAdornment>,
                          }}
                          // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                          fullWidth 
                          label="Minimum Bulk Order Quantity"
                          // error={parseInt(values.ListPrice) > parseInt(values.OriginalPrice)}
                          // helperText={parseInt(values.ListPrice) > parseInt(values.OriginalPrice) ? "Original Price should be more than List Price." : ""}
                          type="number" 
                          value={values.MinimumBulkOrderQuantity} 
                          name="MinimumBulkOrderQuantity" 
                          onChange={handleChange} />
                      </Grid>

                      <Grid item xs={6} >
                        <TextField  
                          fullWidth 
                          placeholder="Enter comma separated value"
                          label="Vehicle Capacity"
                          // type="number" 
                          value={values.VehicleCapacity} 
                          name="VehicleCapacity" 
                          onChange={handleChange} 
                          />
                      </Grid>




                      {/* /////////////////// */}

                      {/* <Grid item xs={6} >
                        <FormControl required sx={{ width: "100%" }}>
                          <InputLabel id="demo-controlled-open-select-label">Service Locations</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={(values && values.ServiceLocId) ? values.ServiceLocId : []}
                            // defaultValue=""
                            label="Service Locations"
                            onChange={handleSelectChange("ServiceLocId")}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => {
                                  var fltr_sl = Object.assign({}, ...serviceLocations.filter(sl => {if(value == sl.Id) return sl;}));
                                  var Area = (fltr_sl.Area) ? fltr_sl.Area+", " : "";
                                  var City = (fltr_sl.City) ? fltr_sl.City+", " : "";
                                  var State = (fltr_sl.State) ? fltr_sl.State : "";
                                  var listitem = Area + City + State;    
                                  return (<Chip key={value} label={listitem} />);
                                })}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {serviceLocations.map((sl, i) => {
                              var Area = (sl.Area) ? sl.Area+", " : "";
                              var City = (sl.City) ? sl.City+", " : "";
                              var State = (sl.State) ? sl.State : "";
                              var listitem = Area + City + State;
                              return (
                                <MenuItem  value={sl.Id} key={i}>
                                  <Checkbox checked={(values && values.ServiceLocId && values.ServiceLocId.indexOf(sl.Id) > -1) ? true : false } />
                                  <ListItemText primary={listitem} />
                                </MenuItem > 
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Grid>  */}


                      {/* /////////////////// */}
                      <Grid item xs={12} >
                        <TextField 
                          required
                          fullWidth
                          multiline
                          label="Description" 
                          //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                          value={values.Description}
                          minRows="3"
                          name="Description" 
                          onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={12} >
                        <TextField 
                          fullWidth
                          multiline
                          label="Information" 
                          //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                          value={values.Information}
                          minRows="3"
                          name="Information" 
                          onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={12} >
                        <TextField 
                          fullWidth
                          multiline
                          label="Features" 
                          //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                          value={values.Features}
                          minRows="3"
                          name="Features" 
                          onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={12} >
                        <TextField 
                          fullWidth
                          multiline
                          label="Disclaimer" 
                          //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                          value={values.Disclaimer}
                          minRows="3"
                          name="Disclaimer" 
                          onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={12} >
                        <TextField 
                          fullWidth
                          multiline
                          label="Delivery Instruction" 
                          //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                          value={values.DelInstruction}
                          minRows="3"
                          name="DelInstruction" 
                          onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={12} >
                        <TextField 
                          fullWidth 
                          multiline 
                          minRows="3" 
                          label="Offer Text" 
                          value={values.Offertxt} 
                          name="Offertxt" onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={6} >
                        <TextField fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                          }}
                          type="number"
                          label="Tax Amount" 
                          value={values.TaxAmount} 
                          name="TaxAmount" 
                          onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={6} >
                        <TextField fullWidth 
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                          }}
                          type="number"
                          label="Packing Charge" 
                          value={values.PackingCharge} 
                          name="PackingCharge" 
                          onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={6} >
                        <TextField fullWidth 
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                          }}
                          type="number"
                          label="Delivery Charge" 
                          value={values.DelCharge} 
                          name="DelCharge" 
                          onChange={handleChange} />
                      </Grid>
                      
                      <Grid item xs={6} >
                        <TextField required type="number" fullWidth label="Delivery Days" value={values.DelDays} name="DelDays" onChange={handleChange} />
                      </Grid>
                    </Grid>
                  </CardContent>
              )}
                  
              <Divider />
              
              <div style={{textAlign: "center", padding: 16}}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                {(isPRUpdating) ?
                  <Button variant='contained' color='secondary' >Updating...</Button> :
                  <Button variant='contained' color='secondary' onClick={handleSubmit}>Update</Button>
                }
              </div>
            </Card>
          </div>
        </CardContent>        
      </Card>
    </div>
  );
}