import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useLocation, useNavigate } from 'react-router-dom';
import { RupeeSymbol } from '../components/util';
import { fetchVendors } from '../store/actions/vendor'; 
import { 
  fetchSubOrderDetail, fetchOrderItemStatus, 
  deleteOrderItem, updateSubOrderitem } from '../store/actions/quotation'; 

export default function EditSubOrderItemPage(props) {

  const [values, setValues] = useState({
    // Id: '',
    // Status: subOrderItemDetail.Status,
    SubOrderItemDetail: {}
  });
  
  const subOrderItemDetail = useSelector(state => (state.quotation.subOrderItemDetail || {}));
  const orderItemStatus = useSelector(state => (state.quotation.orderItemStatus || []));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleItemsValueChange = (event) => {
    var odr = values.SubOrderItemDetail;
    // console.log("before oder");
    // console.log(JSON.stringify(odr));
    odr[event.target.name] = event.target.value;
    // console.log("updated values of oder");
    // console.log(JSON.stringify(odr));
    setValues({...values, SubOrderItemDetail: odr});
  };


  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };

  const mount = () => {
    dispatch(fetchSubOrderDetail(location.state.SubOrderId));
    dispatch(fetchOrderItemStatus());
    dispatch(fetchVendors());
    dispatch(deleteOrderItem());
  }
  useLayoutEffect(mount, []);
 

useEffect( () =>{
    
    //All this info is there in quotDetail
   
      // console.log("order detail updated");
      setValues({
        ...values,
        SubOrderItemDetail: {...subOrderItemDetail}
      });

  }, [subOrderItemDetail]); 

  const UpdateOrderItem = () => {
    var updatedvalue = {
      Id: values.SubOrderItemDetail.OrderItemId,
      Status: values.SubOrderItemDetail.Status
    }
    dispatch(updateSubOrderitem(updatedvalue));
    navigate("/editOrder", { state: { orders: {OrderId: subOrderItemDetail.OrderId}}});
  }

  const Prod = subOrderItemDetail.Product;
  const DeliveryAddress = subOrderItemDetail.DeliveryOrPickupAddress;

  return (    
    <div>
      <Card>
      <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Edit Sub-Order Item" />
          <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}>
            <Button variant='contained' color="error" onClick={() => { navigate("/editOrder", { state: { orders: {OrderId: subOrderItemDetail.OrderId}}})}}>Back</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
         <Grid container>
            <div style={{ borderRadius:5, backgroundColor: "#D9D9D9", width:"100%", marginRight:3, paddingInline:15}}>
              <div 
              style={{display:"flex",  alignItems:"center", justifyContent:"space-between"}}>
                <div style={{fontSize: 22, fontWeight:"bold", marginLeft:-10}}>
                  <CardHeader title="Sub-Order Item Detail" />
                </div>
                <div style={{justifyContent:"flex-end"}}>
                  {/* {!values.showproduct ?
                    <ExpandMoreIcon style={{height:25, width:25}}/>
                    : <ExpandLessIcon style={{height:25, width:25}}/>
                  } */}
                  <ExpandLessIcon style={{height:25, width:25}}/>
                </div>
              </div>

              {/* {values.showproduct ? */}
                <div>
                
                      <Card style={{ width:"100%", backgroundColor:"white",  marginBottom:15, borderRadius:5,}}>
                        <div style={{ display:"flex", flexDirection:"row" }}>
                          {/* <CardHeader title={o.Product.BrandName + " - " + o.Product.ProductName} /> */}
                          <CardHeader title={(Prod) ? Prod.BrandName + " - " + Prod.ProductName + " (" + Prod.ProductSize + ") " : "" } />
                            {/* <div
                              style={{ display:"flex", 
                                // flexDirection:"row", 
                                alignItems:"center", 
                                // justifyContent:"center", 
                                marginLeft:"auto", 
                                marginRight:18 
                              }} >
                                <Button variant='contained' color="error" onClick={() => {setdialog(true)}}>Delete</Button>
                                <div>
                                <Dialog open={dialogOpen} style={{ opacity:"1" }} onClose={() => setdialog(false)} >
                                  <DialogTitle >
                                    {"Delete Product"}
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText >
                                      Are you sure you wish to delete this Product from Order ?
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleItemDeleteClick}>Yes</Button>
                                    <Button onClick={() => setdialog(false)} autoFocus>
                                      No
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                                </div>
                            </div> */}
                        </div>
                        <Divider />

                        <div style={{padding:20}}>
                        <Grid container spacing={2} display="flex">
                          <Grid item xs={3} container >
                            <img src={Prod ? Prod.ProductImage  : null} alt={"Product img"} width="100%" height="100%" style={{borderRadius:5, borderStyle:'outset', borderWidth:1, borderColor:"#C4C4C4",}} />
                          </Grid>

                          <Grid item xs={9} spacing={2} container style={{marginTop:5}}>
                          <Grid item xs={4}>
                              <TextField 
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                }}
                                fullWidth label="Quotation Price" type="number" value={subOrderItemDetail.QuotationPrice} name="QuotationPrice" />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField 
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                }}
                                fullWidth label="Our Price" type="number" value={subOrderItemDetail.OrderItemPrice} name="QuotationPrice" />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField 
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                }}
                                fullWidth label="Vendor Price" type="number" value={subOrderItemDetail.VendorPrice} name="VendorPrice" />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField 
                                inputProps={{ readOnly: true }}
                                fullWidth label="VendorName" value={subOrderItemDetail && subOrderItemDetail.VendorName ? subOrderItemDetail.VendorName : ""} name="VendorName" />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField 
                                inputProps={{ readOnly: true }}
                                fullWidth label="Order Quantity" type="number" value={subOrderItemDetail && subOrderItemDetail.OrderQuantity ? subOrderItemDetail.OrderQuantity : ""} name="Quantity" />
                            </Grid>
                           {/*  <Grid item xs={4}>
                                <FormControl required sx={{ width: "100%" }}>
                                  <InputLabel id="demo-controlled-open-select-label">Expected Delivery Time</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={subOrderItemDetail && subOrderItemDetail.DeliveryTime ? subOrderItemDetail.DeliveryTime : ""}
                                    label="Expected DeliveryTime"
                                    name='DeliveryTime'
                                    onChange={ (e) => handleItemsValueChange(e)}
                                    MenuProps={MenuProps}
                                  >
                                    {deliverytime.map((d, i) => {
                                      return <MenuItem value={d.value} key={i}>{d.label}</MenuItem >
                                    })}
                                  </Select>
                                </FormControl>
                              
                            </Grid> */}
                            <Grid item xs={4}>
                              <TextField 
                                inputProps={{ readOnly: true }}
                                fullWidth label="Expected Delivery Time" value={subOrderItemDetail && subOrderItemDetail.DeliveryDateTime ? subOrderItemDetail.DeliveryDateTime : ""} />
                            </Grid>
                            <Grid item xs={4}>
                                  <FormControl required sx={{ width: "100%" }}>
                                  <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={values && values.SubOrderItemDetail && values.SubOrderItemDetail.Status ? values.SubOrderItemDetail.Status : ""}
                                    label="Status"
                                    name='Status'
                                    // onChange={handleSelectChange("Status")}
                                    onChange={ (e) => handleItemsValueChange(e)}
                                    MenuProps={MenuProps}
                                  >
                                    {orderItemStatus.map((s, i) => {
                                      return <MenuItem value={s.Name} key={i}>{s.Name}</MenuItem >
                                    })}
                                  </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField 
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                }}
                                fullWidth label="Discount" type="number" value={subOrderItemDetail.Discount} name="Discount" />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField 
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                }}
                                fullWidth label="Service Charge" type="number" value={subOrderItemDetail.ServiceCharge} name="ServiceCharge" />
                            </Grid>
                            {/* <Grid item xs={4}>
                              <TextField 
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                }}
                                fullWidth label="Delivery Charges" type="number" value={subOrderItemDetail.DeliveryCharge} name="DeliveryCharge" />
                            </Grid> */}
                          </Grid>

                          <Grid item xs={12} spacing={2} container>
                          <Grid item xs={3}>
                              <TextField 
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                                }}
                                fullWidth label="Delivery Charges" type="number" value={subOrderItemDetail.DeliveryCharge} name="DeliveryCharge" />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField 
                                inputProps={{ readOnly: true }}
                                fullWidth label="Transportation" value={subOrderItemDetail && subOrderItemDetail.Transportation ? subOrderItemDetail.Transportation : ""} name="Transportation" />
                            </Grid>

                            <Grid item xs={3}>
                              <TextField 
                                inputProps={{ readOnly: true }}
                                fullWidth label={subOrderItemDetail.Transportation === "Yes" ? "Delivery Address" : "Pickup Address"} value={DeliveryAddress ? DeliveryAddress.AddressName : ""} name="AddressName" />
                            </Grid>
                               
                            <Grid item xs={3}>
                              <TextField 
                                inputProps={{ readOnly: true }}
                                fullWidth label="Address" value={DeliveryAddress ? DeliveryAddress.Address : ""} name="Address" />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField 
                                inputProps={{ readOnly: true }}
                                fullWidth label="Area" value={DeliveryAddress ? DeliveryAddress.Area : ""} name="Area" />
                            </Grid>

                            <Grid item xs={3}>
                              <TextField 
                                inputProps={{ readOnly: true }}
                                fullWidth label="City & State" value={DeliveryAddress ? DeliveryAddress.City  + ", " + DeliveryAddress.State: ""} name="City" />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField 
                                inputProps={{ readOnly: true }}
                                fullWidth label="Pin Code" type="number" value={DeliveryAddress ? DeliveryAddress.Pincode : ""}/>
                            </Grid>
                                          
                            {(subOrderItemDetail.Transportation === "Yes") ?
                              // <div>
                                <Grid item xs={3}>
                                  <TextField 
                                    inputProps={{ readOnly: true }}
                                    fullWidth label="Driver Name" value={subOrderItemDetail.DriverName} name="DriverName" />
                                </Grid> 
                              : null 
                              }
                               {(subOrderItemDetail.Transportation === "Yes") ?
                              // <div>
                                <Grid item xs={3}>
                                  <TextField 
                                   inputProps={{ readOnly: true }}
                                    fullWidth label="Driver Contact Number" type="number" value={subOrderItemDetail.DriverContact} name="DriverContact" />
                                </Grid> 
                              : null 
                              }
                               {(subOrderItemDetail.Transportation === "Yes") ?
                              // <div>
                                <Grid item xs={3}>
                                  <TextField 
                                    inputProps={{ readOnly: true }}
                                    fullWidth label="Vehicle Number" value={subOrderItemDetail.VehicleNumber} name="VehicleNumber" />
                                </Grid> 
                              : null 
                              }
                               </Grid>
                          {/* </Grid> */}

                        </Grid>

                        </div>
                      </Card>


                </div>        
                {/* : null  */}
              {/* } */}
            </div>

          </Grid>
        </CardContent>  
        <Divider />

        {/* <div style={{textAlign: "center", padding: 16}}> */}
          {/* {(errorMsg && errorMsg !== "") && (
            <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
          )} */}
          {/* <Button variant='contained' color='secondary' onClick={handleSubmit}>Update Order Item</Button> */}
        {/* </div>       */}

          <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"center"}}>
            <Button variant='contained' color='secondary' onClick={UpdateOrderItem}>Update</Button>
          </div>

      </Card>
    </div>
  );
}