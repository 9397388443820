import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InputAdornment from '@mui/material/InputAdornment';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { format, parseISO } from 'date-fns';
import { getFormatedCurrency,RupeeSymbol } from '../components/util';

import { useLocation, useNavigate } from 'react-router-dom';
import { fetchOrderDetail, fetchOrderStatus, updateOrder, fetchAllOrders, addQuotationComment, deleteComment, fetchPaymentMethods } from '../store/actions/quotation'; 

export default function EditOrderPage(props) {

  const [values, setValues] = useState({
    SendPushNotification: false,
    showcomment: true,
    OrderDetail:{OrderItems:[], QuotationItems:[], Comments: []},
  });

  const orderDetail = useSelector(state => (state.quotation.orderDetail || []));
  const orderStatus = useSelector(state => (state.quotation.orderStatus || []));
  const pmntMethods = useSelector(state => (state.quotation.paymentMethods || []));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const location = useLocation();
  const [deleteCommentId, setdeleteCommentId] = useState("");
  const [Commentdialog, setCommentdialog] = useState(false);



  const mount = () => {
    dispatch(fetchPaymentMethods());
    dispatch(fetchOrderDetail(location.state.orders.OrderId));
    dispatch(fetchOrderStatus());
    dispatch(updateOrder());
  }

  useLayoutEffect(mount, []);

  useEffect( () =>{
      setValues({
        ...values,
        OrderDetail: {...orderDetail}
      });
  }, [orderDetail]);

  // useEffect( () =>{
    
  //   if(location.state && location.state.orders){
  //     setValues({
  //       Id: (location.state.orders && location.state.orders.OrderId) || "",
  //       PONumber: (location.state.orders && location.state.orders.PONumber) || "",
  //       Status: (location.state.orders && location.state.orders.Status) || "",
  //     });
  //   }
  // }, [location]);

  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };
  

  const handleSelectChange = (event) => {
    var odr = values.OrderDetail;
    // console.log("before oder");
    // console.log(JSON.stringify(odr));
    odr[event.target.name] = event.target.value;
    // console.log("updated values of oder");
    // console.log(JSON.stringify(odr));
    setValues({...values, OrderDetail: odr});
    setErrorMsg(null);
  };



  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const handleItemDetail = (id) => {
    var qtd = values.OrderDetail;
    var itm = values.OrderDetail.QuotationItems[id];
    navigate("/editOrderItem", { state: {OrderId: qtd.OrderId, QuotationItemId:  itm.QuotationItemId}})
  }

  const handleSubItemDetail = (id) => {
    // var qtd = values.OrderDetail;
    // var itm = values.OrderDetail.QuotationItems[id];
    navigate("/editSubOrderItem", { state: {SubOrderId: id}})
  }

  const handleSubmit = () => {
    var Order = {
      Id: values.OrderDetail.OrderId,
      Status: values.OrderDetail.Status,
      PONumber: values.OrderDetail.PONumber,
    }; 
    dispatch(updateOrder(Order)).then(r => dispatch(fetchAllOrders()));
    navigate("/orders");
  }

  const handleDeliveryChallan = () => {
    navigate("/deliveryChallan");
  }

  const handleInvoice = () => {
    navigate("/invoice");
  }

  const onTabViewClick = (selected) =>{
      setValues({ ...values, showcomment: !values.showcomment})
  }

  const handleCommentSubmit = () => {

    let isInputValid = true;
    let errorMsg = "";
    if(values.Comment === ""){
      isInputValid = false;
      errorMsg = "Please enter comment."
    }
    else{

      var Comment = {
          QuotationId: values.OrderDetail.QuotationId,
          Comment: values.Comment,
          CommentBy: "Admin",
          SendPushNotification: values.SendPushNotification
      };
      dispatch(addQuotationComment(Comment)).then(r => dispatch(fetchOrderDetail(location.state.orders.OrderId)));
      setValues({...values, Comment: ''});
    }
    setErrorMsg( errorMsg );
  }

  const DeleteComment = (id) => {
    dispatch(deleteComment(id)).then(r => dispatch(fetchOrderDetail(location.state.orders.OrderId)));
    setCommentdialog(false);
  };

  var AvailablePmnt = "";
  if (orderDetail && orderDetail.PaymentMethod) { 
      orderDetail.PaymentMethod.map ((p) => {
        var Fltr_Pmnt = pmntMethods.filter(a => p === a.Code);
          if (Fltr_Pmnt && Fltr_Pmnt.length > 0 ){
            AvailablePmnt = (AvailablePmnt != "") ? (AvailablePmnt + ", " + Fltr_Pmnt[0].Name) : Fltr_Pmnt[0].Name;
          }
      })
  }

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Edit Order" />
          <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}>
            <Button variant='contained' color="error" onClick={() => { navigate("/orders") }}>Back</Button>
          </div>
        </div>
        <Divider />

        <CardContent>
          <div style={{ display:"flex", fontSize: 22, fontWeight:"bold", color:"#F7D200"}}>
            <CardHeader title="Customer Detail" />
          </div>
          <Grid container style={{marginBottom:20}}>
            <Grid item xs={6} spacing={4}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Name: </Typography>
                <Typography variant="subtitle1" ml={2}>{(orderDetail.CustomerFirstName ) +" "+ (orderDetail.CustomerLastName)}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Email: </Typography>
                <Typography variant="subtitle1" ml={2}>{orderDetail.CustomerEmail}</Typography>
              </div>
            </Grid>

            <Grid item xs={6} spacing={4}>              
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Mobile: </Typography>
                <Typography variant="subtitle1" ml={2}>{orderDetail.CustomerMobile}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Available Payment Type: </Typography>
                <Typography variant="subtitle1" ml={2}>{AvailablePmnt}</Typography>
              </div>
            </Grid>
          </Grid>

          <Divider />
          <Divider orientation={'vertical'} />

          <div style={{ display:"flex", fontSize: 22, fontWeight:"bold", color:"#F7D200", marginTop:10}}>
            <CardHeader title="Order Detail" />
          </div>
          
          <Grid container style={{marginBottom:10}}>
                
            <Grid item xs={6} spacing={4}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Order Date and Time: </Typography>
                <Typography variant="subtitle1" ml={2}>{(orderDetail.OrderDate) ? format(parseISO(orderDetail.OrderDate), "dd MMM yyyy 'at' hh:mm a") : null}</Typography>
              </div>                   
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Total Amount: </Typography>
                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(orderDetail.TotalAmount)}</Typography>
              </div>  
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Total Items: </Typography>
                <Typography variant="subtitle1" ml={2}>{orderDetail.TotalItems}</Typography>
              </div>
                {/* <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"space-between", marginTop:-8}}>
                  <Button variant='contained' color='secondary'>Generate invoice</Button>
                  <Button variant='contained' color='secondary'>Generate Delivery challan</Button>
              </div> */}
            </Grid>
            
            <Grid item xs={6} spacing={4}>             
              <div className="div-infocontainer">
                <FormControl required sx={{ width: "100%" }}>
                  <InputLabel id="demo-controlled-open-select-label">Order Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={values && values.OrderDetail && values.OrderDetail.Status ? values.OrderDetail.Status : ""}
                    label="Order Status"
                    name= "Status"
                    onChange={(e) => handleSelectChange(e)}
                    MenuProps={MenuProps}
                  >
                    {orderStatus.map((s, i) => {
                      return <MenuItem value={s.Name} key={i}>{s.Name}</MenuItem >
                    })}
                  </Select>
                </FormControl>
              </div>  

              <div className="div-infocontainer" style={{marginTop:20}} >
                <TextField 
                  required 
                  fullWidth label="PO Number" value={values && values.OrderDetail && values.OrderDetail.PONumber ? values.OrderDetail.PONumber : ""} name="PONumber" onChange={(e) => handleSelectChange(e)} />
              </div>
            </Grid>     
          </Grid>
          
          </CardContent>
          <Grid container>
            {((values && values.OrderDetail && values.OrderDetail.QuotationItems) || []) .map((q, i) => {
              return  ( 
                <div key={i}  style={{ borderRadius:5, backgroundColor: (q.OrderItemStatus === "CANLD" ? "#F0807D" : "#D9D9D9"), width:"100%", paddingInline:15, margin:20, marginTop:0, borderWidth:1.5, borderColor:"orange", borderStyle:'solid'}}>
                  <div style={{display:"flex",  alignItems:"center", justifyContent:"space-between", paddingTop:11, paddingBottom:11}} /*onClick={handleCommentShow} */ /* onClick={(e) => onTabViewClick("CmntClicked", e)} */  >
                    <Grid item xs={3.5} style={{fontSize: 16, fontWeight:"bold", paddingTop:5, paddingBottom: 5}}>
                      <Typography variant="subtitle">{q.Product.ProductName + " - " + q.Product.BrandName + " (" + q.Product.ProductSize + ")"} </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={1.8} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Amount: {getFormatedCurrency(q.Quantity*q.QuotationPrice)}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={1.8} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Quantity: {q.Quantity}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={1.8} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Delivered: {q.DispatchQuantity}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={1.8} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">{q.OrderItemStatus != "CANLD" ? "Pending: " : "Cancelled: " }{q.Quantity-q.DispatchQuantity}</Typography>
                    </Grid>
                    {(q.OrderItemStatus != "CANLD" && q.Quantity-q.DispatchQuantity > 0 ) ?
                    <div style={{justifyContent:"flex-end"}}>
                      <Button variant='contained' color='secondary' onClick={() => handleItemDetail(i)}>Process <ChevronRightIcon style={{height:25, width:25}}/></Button>
                    </div>
                    : null}
                  </div>
                  {((values && values.OrderDetail && values.OrderDetail.OrderItems) || []).filter(f => f.QuotationItemId === q.QuotationItemId).map((o, j) => {
                    return (
                      <div key={j} style={{width:"100%", backgroundColor:"white",  marginBottom:15, borderRadius:5,}}>
                        <div style={{ display:"flex", flexDirection:"row" }}>
                          <CardHeader title={o.Product.ProductName} />
                            {(q.OrderItemStatus != "CANLD") ?
                              <div
                                style={{ display:"flex", 
                                  alignItems:"center", 
                                  marginLeft:"auto", 
                                  marginRight:18, 
                                  justifyContent:"flex-end"
                                }} >
                                  <Button variant='contained' color="secondary" onClick={() => handleSubItemDetail(o.OrderItemId)}>Edit</Button>
                              </div>
                            : null}
                        </div>
                        <Divider />                    
                        <Grid container spacing={2} display="flex" style={{paddingBottom:11, paddingRight:20, paddingLeft:20}}>
                          <Grid item xs={12} spacing={2} container style={{marginTop:5}}>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Quotation Price: </Typography>
                                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.QuotationPrice)}</Typography>
                              </div> 
                            </Grid>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Our Price: </Typography>
                                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.OrderItemPrice)}</Typography>
                              </div> 
                            </Grid>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Order Quantity: </Typography>
                                <Typography variant="subtitle1" ml={2}>{o.OrderQuantity}</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Vendor Price: </Typography>
                                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.VendorPrice)}</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Vendor Name: </Typography>
                                <Typography variant="subtitle1" ml={2}>{o.VendorName}</Typography>
                              </div>
                            </Grid>                  
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Delivery Time: </Typography>
                                <Typography variant="subtitle1" ml={2}>{(o.DeliveryDateTime) ? format(parseISO(o.DeliveryDateTime), "dd MMM yyyy 'at' hh:mm a") : null}</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Status: </Typography>
                                <Typography variant="subtitle1" ml={2}>{o.Status}</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Discount: </Typography>
                                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.Discount)}</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Service Charges: </Typography>
                                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.ServiceCharge)}</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Delivery Charges: </Typography>
                                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(o.DeliveryCharge)}</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <div className="div-infocontainer">
                                <Typography variant="subtitle2">Transportation: </Typography>
                                <Typography variant="subtitle1" ml={2}>{o.Transportation}</Typography>
                              </div>
                            </Grid>
                            {(o && o.Transportation && o.Transportation === "Yes") ?
                              <Grid item xs={3}>
                                <div className="div-infocontainer">
                                  <Typography variant="subtitle2">Driver Name: </Typography>
                                  <Typography variant="subtitle1" ml={2}>{o.DriverName}</Typography>
                                </div>
                              </Grid>
                            : null }

                            {(o && o.Transportation && o.Transportation === "Yes") ?
                              <Grid item xs={3}>
                                <div className="div-infocontainer">
                                  <Typography variant="subtitle2">Driver Contact: </Typography>
                                  <Typography variant="subtitle1" ml={2}>{o.DriverContact}</Typography>
                                </div>
                              </Grid>
                            : null }
                            
                            {(o && o.Transportation && o.Transportation === "Yes") ?
                              <Grid item xs={3}>
                                <div className="div-infocontainer">
                                  <Typography variant="subtitle2">Vehicle Number: </Typography>
                                  <Typography variant="subtitle1" ml={2}>{o.VehicleNumber}</Typography>
                                </div>
                              </Grid>
                            : null}
                          </Grid>
                        </Grid>
                      </div>
                    )
                  })}
               
                </div>
              )
            })}
        </Grid>
        
        <Divider />
        <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"space-between"}}>
          <Button variant='contained' color='secondary' onClick={handleInvoice}>Generate invoice</Button>
          <Button variant='contained' color='secondary'onClick={handleDeliveryChallan}>Generate Delivery challan</Button>
          <Button variant='contained' color='secondary' onClick={handleSubmit}>Save Order</Button>
        </div>
      </Card>

      <Card style={{ marginTop: 25}} >
        <div style={{display:"flex",justifyContent:"space-between"}}>
          <CardHeader title="Nodes Section" />
            <Grid item xs={6} style={{marginTop:10, marginRight:15}} >
              <FormGroup aria-label="position" row >
                <FormControlLabel control={<Checkbox defaultChecked={false} checked={values.SendPushNotification} onChange={(e) => { setValues({...values, SendPushNotification: e.target.checked}) }} />} 
                  labelPlacement="start" 
                  label="Check for Push Notification"
                />
              </FormGroup>
            </Grid>
        </div>
        <Divider />
        <Grid container style={{marginbottom:15}}>
          <div style={{ borderRadius:5, backgroundColor: "#D9D9D9", width:"100%", paddingInline:15, margin:20}}>
            <div onClick={(e) => onTabViewClick("CmntClicked", e)} style={{display:"flex",  alignItems:"center", justifyContent:"space-between"}}>
              <div style={{fontSize: 22, fontWeight:"bold", marginLeft:-10}}>
                <CardHeader title="Show Nodes" />
              </div>
             
              <div style={{justifyContent:"flex-end"}}>
                {!values.showcomment ?
                  <ExpandMoreIcon style={{height:25, width:25}}/>
                  : <ExpandLessIcon style={{height:25, width:25}}/>
                }
              </div>
            </div>

            {values.showcomment && values.OrderDetail && values.OrderDetail.Comments && values.OrderDetail.Comments.length != 0 ?
              <div style={{ width:"100%", backgroundColor:"white",  marginBottom:15, borderRadius:5, paddingInline:10, padding:10}}>

                {values.OrderDetail.Comments.map((c, i) => {
                  return  ( 
                    <div>
                      <Grid container spacing={4} key={i} display="flex">
                        
                        <Grid item xs={3} container>
                          <div className="div-infocontainer">
                            <Typography variant="subtitle1" ml={2}>{c.CommentBy + ": "}</Typography>
                            <Typography style={{fontSize:11, color:"#7C7C7C"}} ml={2}>{"(" + format(parseISO(c.CreatedOn), "dd MMM yyyy 'at' hh:mm a") + ")"}</Typography>

                          </div>                           
                        </Grid>

                        {/* <Grid item xs={2} container>
                          <div className="div-infocontainer">
                            <Typography style={{fontSize:10, color:"#D9D9D9"}} ml={2}>{"(" + format(parseISO(c.CreatedOn), "dd MMM yyyy 'at' hh:mm a") + ")"}</Typography>
                          </div>                           
                        </Grid> */}

                        <Grid item xs={8} container>
                          <div className="div-infocontainer">
                            <Typography variant="subtitle1" ml={2}>{c.Comment}</Typography>
                          </div>
                        </Grid>

                        <Grid item xs={1} container /* style={{backgroundColor:"orange"}} */>
                          <div className="div-infocontainer" >
                            <Tooltip title="Delete">
                            <Typography onClick={() => {setdeleteCommentId(c.Id); setCommentdialog(true); }} variant="subtitle1" ml={2}>{<DeleteIcon />}</Typography>
                            </Tooltip>
                            <div>
                              <Dialog open={Commentdialog} style={{ opacity:"1" }} onClose={() => setCommentdialog(false)} >
                                <DialogTitle >
                                  {"Delete Node"}
                                </DialogTitle>
                                <DialogContent>
                                  <DialogContentText >
                                    Are you sure you wish to delete this Node from Quotation ?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={() => DeleteComment(deleteCommentId)}>Yes</Button>
                                  <Button onClick={() => setCommentdialog(false)} autoFocus>
                                    No
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>
                        </Grid>

                      </Grid>
                      {(i != values.OrderDetail.Comments.length-1) ? 
                      <Divider/>
                      : <span></span>}

                    </div>
                  )
                })}
              </div>
              : null 
            }
          </div>
        </Grid>

        <CardContent sx={{ paddingX: 2.5, paddingY: 2 }}>
          <Grid item xs={12} >
            <TextField fullWidth multiline size="medium" label="Add node" value={values.Comment} name="Comment" onChange={handleChange} />
          </Grid>
        </CardContent>
        
        <Divider />

        <div style={{textAlign: "center", padding: 16}}>
          {(errorMsg && errorMsg !== "") && (
            <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
          )}
          <Button variant='contained' color='secondary' onClick={handleCommentSubmit}>Add Node</Button>
        </div>
      </Card>









    </div>
  );
}