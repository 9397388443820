import { 
    FETCHVENDOR_REQUEST, FETCHVENDOR_SUCCESS, FETCHVENDOR_FAILURE,
    DELETEVENDOR_REQUEST, DELETEVENDOR_SUCCESS, DELETEVENDOR_FAILURE

} from '../actions/vendor';

export default (
    state = {
        error: undefined,
        isVendorFetching: false,
        isVendorDeleting: false,
        vendors: [],
       
    },
    action
) => {
    switch (action.type) {
        case FETCHVENDOR_REQUEST:
            return{
                ...state,
                isVendorFetching: true
            };
        case FETCHVENDOR_SUCCESS:
            return{
                ...state,
                isVendorFetching: false,
                vendors: action.response,
                error: undefined
            };
        case FETCHVENDOR_FAILURE:
            return{
                ...state,
                isVendorFetching: false,
                error: action.error
            };
        case DELETEVENDOR_REQUEST:
            return{
                ...state,
                isVendorDeleting: true
            }
        case DELETEVENDOR_SUCCESS:
            return{
                ...state,
                isVendorDeleting: false,
                error: undefined
            }
        case DELETEVENDOR_FAILURE:
            return{
                ...state,
                isVendorDeleting: false,
                error: action.error
            }

            default:
                return state;
    }
}
          
        