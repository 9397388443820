import { truncate } from 'lodash-es';
import {
    FETCHPICKUPADDRESS_REQUEST, FETCHPICKUPADDRESS_SUCCESS, FETCHPICKUPADDRESS_FAILURE,
    SERVICELOC_REQUEST, SERVICELOC_SUCCESS, SERVICELOC_FAILURE,
    ADDPLOC_REQUEST, ADDPLOC_SUCCESS, ADDPLOC_FAILURE,
    UPLOC_REQUEST, UPLOC_SUCCESS, UPLOC_FAILURE,
    DELPLOC_REQUEST, DELPLOC_SUCCESS, DELPLOC_FAILURE,
    ADDSERLOCATION_REQUEST, ADDSERLOCATION_SUCCESS, ADDSERLOCATION_FAILURE,
    UPDTSERLOCATION_REQUEST, UPDTSERLOCATION_SUCCESS, UPDTSERLOCATION_FAILURE,
    DELSERLOCATION_REQUEST, DELSERLOCATION_SUCCESS, DELSERLOCATION_FAILURE,
    CITY_LIST_REQUEST, CITY_LIST_SUCCESS, CITY_LIST_FAILURE,
    ADD_CITY_REQUEST, ADD_CITY_SUCCESS, ADD_CITY_FAILURE,
    UPDATE_CITY_REQUEST, UPDATE_CITY_SUCCESS, UPDATE_CITY_FAILURE,
    DELETE_CITY_REQUEST, DELETE_CITY_SUCCESS, DELETE_CITY_FAILURE,
    STATE_LIST_REQUEST, STATE_LIST_SUCCESS, STATE_LIST_FAILURE,
    ADD_STATE_REQUEST, ADD_STATE_SUCCESS, ADD_STATE_FAILURE,
    UPDATE_STATE_REQUEST, UPDATE_STATE_SUCCESS, UPDATE_STATE_FAILURE,
    DELETE_STATE_REQUEST, DELETE_STATE_SUCCESS, DELETE_STATE_FAILURE,
    POSTCODE_LIST_REQUEST, POSTCODE_LIST_SUCCESS, POSTCODE_LIST_FAILURE,
    ADD_POSTCODE_REQUEST,ADD_POSTCODE_SUCCESS,ADD_POSTCODE_FAILURE,
    UPDATE_POSTCODE_REQUEST,UPDATE_POSTCODE_SUCCESS,UPDATE_POSTCODE_FAILURE,
    DELETE_POSTCODE_REQUEST, DELETE_POSTCODE_SUCCESS, DELETE_POSTCODE_FAILURE    

} from '../actions/addresses';

export default (
    state = {
        error: undefined,
        isPickupAddrFetching: false,
        isPLOCAdding: false,
        isPLOCUpdating: false,
        isPLOCDeleting: false,
        pickupAddresses: [],
        isSERLOCFetching: false,
        isServiceLocationAdding: false,
        isServiceLocationsUpdating: false,
        isServiceLocationsDeleting: false,
        pickupAddresses: [],
        serviceLocations: [],
        isCityListFatching: false,
        CityList: [],
        isCityAdding: false,
        isCityUpdating: false,
        isCityDeleting: false,
        isStateListFatching: false,
        StateList: [],
        isStateAdding: false,
        isStateUpdating: false,
        isStateDeleting: false,
        isPostalCodelistFetching: false,
        PostalCodeList: [],
        isPostalCodeAdding: false,
        isPostalCodeUpdating: false,
        isPostalCodeDeleting: false,



    },
    action
) => {
    switch (action.type) {
        case FETCHPICKUPADDRESS_REQUEST:
            return {
                ...state,
                isPickupAddrFetching: true
            };
        case FETCHPICKUPADDRESS_SUCCESS:
            return {
                ...state,
                isPickupAddrFetching: false,
                pickupAddresses: action.response,
                error: undefined
            };
        case FETCHPICKUPADDRESS_FAILURE:
            return {
                ...state,
                isPickupAddrFetching: false,
                error: action.error
            };
        case SERVICELOC_REQUEST:
            return {
                ...state,
                isSERLOCFetching: true,
            };
        case SERVICELOC_SUCCESS:
            return {
                ...state,
                isSERLOCFetching: false,
                serviceLocations: action.response,
                error: undefined
            };
        case SERVICELOC_FAILURE:
            return {
                ...state,
                isSERLOCFetching: false,
                error: action.error
            };
        case ADDPLOC_REQUEST:
            return {
                ...state,
                isPLOCAdding: true
            };
        case ADDPLOC_SUCCESS:
            return {
                ...state,
                isPLOCAdding: false,
                error: undefined
            };
        case ADDPLOC_FAILURE:
            return {
                ...state,
                isPLOCAdding: false,
                error: action.error
            };
        case UPLOC_REQUEST:
            return {
                ...state,
                isPLOCUpdating: true
            };
        case UPLOC_SUCCESS:
            return {
                ...state,
                isPLOCUpdating: false,
                error: undefined
            };
        case UPLOC_FAILURE:
            return {
                ...state,
                isPLOCUpdating: false,
                error: action.error
            };
        case DELPLOC_REQUEST:
            return {
                ...state,
                isPLOCDeleting: true
            };
        case DELPLOC_SUCCESS:
            return {
                ...state,
                isPLOCDeleting: false,
                error: undefined
            };
        case DELPLOC_FAILURE:
            return {
                ...state,
                isPLOCDeleting: false,
                error: action.error
            };
        case ADDSERLOCATION_REQUEST:
            return {
                ...state,
                isServiceLocationAdding: true
            }
        case ADDSERLOCATION_SUCCESS:
            return {
                ...state,
                isServiceLocationAdding: false,
                error: undefined
            }
        case ADDSERLOCATION_FAILURE:
            return {
                ...state,
                isServiceLocationAdding: false,
                error: action.error
            }
        case UPDTSERLOCATION_REQUEST:
            return {
                ...state,
                isServiceLocationsUpdating: true
            }
        case UPDTSERLOCATION_SUCCESS:
            return {
                ...state,
                isServiceLocationsUpdating: false,
                error: undefined
            }
        case UPDTSERLOCATION_FAILURE:
            return {
                ...state,
                isServiceLocationsUpdating: false,
                error: action.error
            }
        case DELSERLOCATION_REQUEST:
            return {
                ...state,
                isServiceLocationsDeleting: true
            }
        case DELSERLOCATION_SUCCESS:
            return {
                ...state,
                isServiceLocationsDeleting: false,
                error: undefined
            }
        case DELSERLOCATION_FAILURE:
            return {
                ...state,
                isServiceLocationsDeleting: false,
                error: action.error
            }

        /* Get City List */
        case CITY_LIST_REQUEST:
            return {
                ...state,
                isCityListFatching: true
            };
        case CITY_LIST_SUCCESS:
            return {
                ...state,
                isCityListFatching: false,
                CityList: action.response,
                error: undefined
            };
        case CITY_LIST_FAILURE:
            return {
                ...state,
                isCityListFatching: false,
                error: action.error
            };

        /* Add City */
        case ADD_CITY_REQUEST:
            return {
                ...state,
                isCityAdding: true
            };
        case ADD_CITY_SUCCESS:
            return {
                ...state,
                isCityAdding: false,
                error: undefined
            };
        case ADD_CITY_FAILURE:
            return {
                ...state,
                isCityAdding: false,
                error: action.error
            };

        /* Update City */
        case UPDATE_CITY_REQUEST:
            return {
                ...state,
                isCityUpdating: true
            };
        case UPDATE_CITY_SUCCESS:
            return {
                ...state,
                isCityUpdating: false,
                error: undefined
            };
        case UPDATE_CITY_FAILURE:
            return {
                ...state,
                isCityUpdating: false,
                error: action.error
            };

        /* Delete City */
        case DELETE_CITY_REQUEST:
            return {
                ...state,
                isCityDeleting: true
            };
        case DELETE_CITY_SUCCESS:
            return {
                ...state,
                isCityDeleting: false,
                error: undefined
            };
        case DELETE_CITY_FAILURE:
            return {
                ...state,
                isCityDeleting: false,
                error: action.error
            };

        /* Cet State List */
        case STATE_LIST_REQUEST:
            return {
                ...state,
                isStateListFatching: true
            };
        case STATE_LIST_SUCCESS:
            return {
                ...state,
                isStateListFatching: false,
                StateList: action.response,
                error: undefined
            };
        case STATE_LIST_FAILURE:
            return {
                ...state,
                isStateListFatching: false,
                error: action.error
            };

        /* Add State */
        case ADD_STATE_REQUEST:
            return {
                ...state,
                isStateAdding: true
            };
        case ADD_STATE_SUCCESS:
            return {
                ...state,
                isStateAdding: false,
                error: undefined
            };
        case ADD_STATE_FAILURE:
            return {
                ...state,
                isStateAdding: false,
                error: action.error
            };

        /* Update State */
        case UPDATE_STATE_REQUEST:
            return {
                ...state,
                isStateUpdating: true
            };
        case UPDATE_STATE_SUCCESS:
            return {
                ...state,
                isStateUpdating: false,
                error: undefined
            };
        case UPDATE_STATE_FAILURE:
            return {
                ...state,
                isStateUpdating: false,
                error: action.error
            };

        /* Delete State */
        case DELETE_STATE_REQUEST:
            return {
                ...state,
                isStateDeleting: true
            };
        case DELETE_STATE_SUCCESS:
            return {
                ...state,
                isStateDeleting: false,
                error: undefined
            };
        case DELETE_STATE_FAILURE:
            return {
                ...state,
                isStateDeleting: false,
                error: action.error
            };

        /* Postal Code list */
        case POSTCODE_LIST_REQUEST:
            return {
                ...state,
                isPostalCodelistFetching: true,
                error: undefined,
            };
        case POSTCODE_LIST_SUCCESS:
            return {
                ...state,
                isPostalCodelistFetching: false,
                PostalCodeList: action.response,
            };
        case POSTCODE_LIST_FAILURE:
            return {
                ...state,
                isPostalCodelistFetching: false,
                error: action.error,
            };

        /* Add Postal Code */
        case ADD_POSTCODE_REQUEST:
            return {
                ...state,
                isPostalCodeAdding: true,
            };
        case ADD_POSTCODE_SUCCESS:
            return {
                ...state,
                isPostalCodeAdding: false,
                error: undefined
            };
        case ADD_POSTCODE_FAILURE:
            return {
                ...state,
                isPostalCodeAdding: false,
                error: action.error
            };

        /* Update Postal Code */
        case UPDATE_POSTCODE_REQUEST:
            return {
                ...state,
                isPostalCodeUpdating: true,
            };
        case UPDATE_POSTCODE_SUCCESS:
            return {
                ...state,
                isPostalCodeUpdating: false,
                error: undefined
            };
        case UPDATE_POSTCODE_FAILURE:
            return {
                ...state,
                isPostalCodeUpdating: false,
                error: action.error
            };

        /* Delete Postal Code */
        case DELETE_POSTCODE_REQUEST:
            return {
                ...state,
                isPostalCodeDeleting: true,
            };
        case DELETE_POSTCODE_SUCCESS:
            return {
                ...state,
                isPostalCodeDeleting: false,
                error: undefined
            };
        case DELETE_POSTCODE_FAILURE:
            return {
                ...state,
                isPostalCodeDeleting: false,
                error: action.error
            };














        default:
            return state;
    }
}

