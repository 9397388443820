import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { GetStateList, DeleteState } from '../store/actions/addresses';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Tooltip from '@mui/material/Tooltip';

export default function StateListPage(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteStateId, setdeleteStateId] = useState("");
  const StateList = useSelector(state => (state.addresses.StateList || []));
  const isStateListFatching = useSelector(state => (state.addresses.isStateListFatching ));

  const mount = () => {
    dispatch(GetStateList());
  }
  // eslint-disable-next-line
  useEffect(mount, []);

  const handleEditClick = (id) => () => {
    var fltr_state = Object.assign({}, ...StateList.filter(c => { if(c.Id === id) return c; }));
    console.log("state detail", fltr_state);
    navigate("/editState", { state: { StateDetail: fltr_state }} );
  };

  const handleDeleteClick = (id) => {
    dispatch(DeleteState(id)).then(r => dispatch(GetStateList({})));
    setdialog(false);

  };

  const columns = [

    { field: 'id', headerName: 'Id', align: "left", flex: 0.1 },
    { field: 'name', headerName: 'Name', align: "left",flex: 1 },
    { field: 'status', headerName: 'Status', align: "left", flex: 0.5},
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Delete">
            <div>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  setdeleteStateId(id);
                  setdialog(true);
                }}
                color="inherit"
              />
              <Dialog open={dialogOpen} style={{ opacity:"1" }} onClose={() => setdialog(false)} >
                <DialogTitle >
                  {"Delete Service Location"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText >
                    Are you sure you wish to delete this State ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleDeleteClick(deleteStateId)}>Yes</Button>
                  <Button onClick={() => setdialog(false)} autoFocus>
                    No
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Tooltip>,
        ];
      }
    }
  ];

  const rows = StateList.map(s => {
    return ({ 
      id: s.Id,
      name: s.Name,
      status: s.Status,
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="State List" />
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addState") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add State</Typography>
          </Button>
        </div>
        <Divider />
        
        {StateList && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={ isStateListFatching ? true : false }
              autoHeight={18}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}
   
      </Card>
    </div>
  );
}