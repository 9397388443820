import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DatePicker from "react-datepicker";
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { getFormatedCurrency } from '../components/util';
import { fetchCustomers } from '../store/actions/customer'; 
import { fetchOdrItemListForInvoice, ListOutInvoice  } from '../store/actions/invoice';
import { fetchCustomerAddress } from '../store/actions/customer'; 

export default function AddInvoicePage(props) {

  const [values, setValues] = useState({

    OrderItemIds:[],
    OrderPONumber: "",
    CustomerId: "",
    BilliingAdrId: "",
    InvoiceDate: new Date(),
  });

const navigate = useNavigate();
const dispatch = useDispatch();
const [errorMsg, setErrorMsg] = useState(null);
const [errorMsgOnAdd, setErrorMsgOnAdd] = useState(null);

// const [startDate, setStartDate] = useState(new Date());
const customers = useSelector(state => (state.customer.customers || []));
// const isCustomerFetching = useSelector(state => (state.customer.isCustomerFetching ));
// const isOdrItemListFetching = useSelector(state => (state.invoice.isOdrItemListFetching ));
const isOrderItemListFetched = useSelector(state => (state.invoice.isOrderItemListFetched));

const orderItemList = useSelector(state => (state.invoice.orderItemList || []));
// const isCustomerAdrsFerching = useSelector(state => (state.customer.isCustomerAdrsFerching ));
const customerAddress = useSelector(state => (state.customer.customerAddress || []));

const MenuProps = {
  style: {
    maxHeight: 300,
  }
};

const mount = () => {
  dispatch(fetchCustomers());
}
  // eslint-disable-next-line
  useLayoutEffect(mount, []);

  useEffect( () =>{
    /* return () => {

    } */
    
    //All this info is there in quotDetail
   
    // console.log("order detail updated");
    if(orderItemList && orderItemList.length > 0) {
      setValues({
        ...values,
        CustomerId : orderItemList && orderItemList.length > 0 ?  orderItemList[0].CustomerId : values.CustomerId,
        BilliingAdrId :  orderItemList && orderItemList.length > 0 && orderItemList[0].BillingAddress ? orderItemList[0].BillingAddress.AddressId : ""
      });
    }
  }, [orderItemList]);


  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
    setErrorMsgOnAdd(null);
  };

  const isInputValid = () => {
    let isInputValid = true;
    let invalidFieldName = "";
    let errorMsg = "";

    if(values.OrderPONumber === "" && values.CustomerId === "" || values.CustomerId === null){
      isInputValid = false;
      errorMsg = "Please Enter the Order PONumber or Select Customer.";
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  }

  const hamdleSearch = () => {
    if(isInputValid()){
      var SearchParams = {
        OrderPONumber: values.OrderPONumber,
        CustomerId: values.CustomerId
      }
    dispatch(fetchOdrItemListForInvoice(SearchParams));
    dispatch(fetchCustomerAddress(values.CustomerId));
  }
  }

  const handleAddInvoice = () => {
    let isInputValid = true;
    let errorMsgOnAdd = "";

    if( values.InvoiceDate === null || values.BilliingAdrId === "" || !values.OrderItemIds.length){
      isInputValid = false;
      errorMsgOnAdd = "Invoice Date and Billing Address should not be empty.";
      setErrorMsgOnAdd( errorMsgOnAdd );
    }
    else {   
      const sendParams = {
        OrderItemId : values.OrderItemIds,
        BillingAddressId: values.BilliingAdrId,
        InvoiceDate : format(values.InvoiceDate, "yyyy-MM-dd HH:mm:ss")
      }
      dispatch(ListOutInvoice(sendParams));
      navigate("/invoice");
    }
   }
 
   const handleCheckBoxClick = (e) => {
    const { value, checked } = e.target;
    const { OrderItemIds } = values;

    if(checked){
      OrderItemIds.push(parseInt(value));
    }
    else {
      const index = OrderItemIds.indexOf(parseInt(value));
      OrderItemIds.splice(index, 1);
    }
  }

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Add Invoice" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/invoice")}} >Back</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
        <Grid container style={{marginBottom:10, justifyContent:"space-between", alignItems:"center", marginTop:20}}>
            
            <Grid item xs={5.2} spacing={4} >
              <div className="div-infocontainer" >
                <TextField 
                  // required                    
                  fullWidth label="Search by Order PONumber" value={values.OrderPONumber} name="OrderPONumber" onChange={handleChange} 
                />
              </div>
            </Grid>
            
            <Grid item xs={5.2} spacing={4} >             
              <div className="div-infocontainer">
                <FormControl /* required */ sx={{ width: "100%" }}>
                  <InputLabel id="demo-controlled-open-select-label">Search by Customer</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={values.CustomerId}
                    label="Search by Customer"
                    name= "CustomerId"
                    onChange={handleChange}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={""}> <em>None</em> </MenuItem>
                    {customers.map((c, i) => {
                      return <MenuItem value={c.Id} key={i}>{c.FirstName + " " + c.LastName}</MenuItem >
                    })}
                  </Select>
                </FormControl>
              </div>  
            </Grid>   

            <Grid item xs={1.5} spacing={4}> 
              <div style={{ display:"flex", justifyContent:"center"}}>
                <Button variant='contained' color='secondary' onClick={hamdleSearch} >Search</Button>
              </div>
            </Grid>

          </Grid>

          {(errorMsg && errorMsg != null) ?    
            <div style={{textAlign: "center", paddingTop: 5}}>
              {(errorMsg && errorMsg !== "") && (
                <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
              )}
            </div> 
          :null}

          {(orderItemList && orderItemList.length <= 0 && isOrderItemListFetched === true) ?    
            <div style={{textAlign: "center", paddingTop: 5}}>
                <Typography mb={2} variant="body2" color='error' >No Date Available on Selected Details</Typography>
            </div> 
          :null}

        </CardContent>        
        {(orderItemList && orderItemList.length > 0) ?

          <Grid container>
            {orderItemList.map((o, i) => {
              return  ( 
                <div key={i}  style={{ borderRadius:5, backgroundColor:"#D9D9D9", width:"100%", paddingInline:15, margin:20, marginTop:0, borderWidth:1.5, borderColor:"orange", borderStyle:'solid', paddingTop:5, paddingBottom:5}}>
                  <div style={{display:"flex",  alignItems:"center", justifyContent:"space-between", paddingTop:11, paddingBottom:11}}>
                  
                    <Grid item xs={4} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Order Date: {(o.OrderDate) ? format(parseISO(o.OrderDate), "dd MMM yyyy") : null}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={4} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Order PONumber: {o.OrderPONumber}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={4} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">PONumber: {o.PONumber}</Typography>
                    </Grid>
          
                  </div>
                  {o && o.OrderItems && o.OrderItems.length > 0 ?
                    <div  style={{width:"100%", backgroundColor:"white",  marginBottom:15, borderRadius:5,}}>
                      {(o.OrderItems || []).map((h, j) => {
                        return (
                          <div>
                            <div key={j} style={{display:"flex",padding:8, paddingInline:12, alignContent:"center"}}>
                              <Grid item xs={0.5} style={{alignSelf:"center"}}>
                                <Checkbox
                                  name='languages'
                                  value={h.OrderItemId}
                                  onChange={handleCheckBoxClick}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </Grid>
                              <Grid item xs={3.2} style={{fontSize: 13, fontWeight:"bold", paddingTop:5, paddingBottom: 5, alignSelf:"center"}}>
                                <Typography variant="subtitle">{h.Product.ProductName + " - " + h.Product.BrandName + " (" + h.Product.ProductSize + ")"} </Typography>
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid item xs={2.2} style={{textAlign:"center",  alignSelf:"center"}}>
                                <Typography variant="subtitle2">Status: {h.Status}</Typography>
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid item xs={2.2} style={{textAlign:"center",  alignSelf:"center"}}>
                                <Typography variant="subtitle2">Quantity: {h.OrderQuantity}</Typography>
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid item xs={2.2} style={{textAlign:"center",  alignSelf:"center"}}>
                                <Typography variant="subtitle2">Price: {getFormatedCurrency(h.OrderItemPrice)}</Typography>
                              </Grid>
                              <Divider orientation="vertical" flexItem />
                              <Grid item xs={2.2} style={{textAlign:"center",  alignSelf:"center"}}>
                                <Typography variant="subtitle2">Amount: {getFormatedCurrency(h.OrderQuantity*h.OrderItemPrice)}</Typography>
                              </Grid>
                            </div>
                            <Divider />
                          </div>                    
                        )
                      })} 
                    </div>
                  : null}
      
                </div>
              )
            })}

            <Grid container style={{marginBottom:10, justifyContent:"space-between", alignItems:"center", margin:20, marginTop:0}}>
              <Grid item xs={4} spacing={4}>
                <DatePicker  
                  selected={values.InvoiceDate}
                  showIcon
                  required
                  dateFormat="yyyy-MM-dd   HH:mm:ss"
                  popperClassName="date-popper"
                  onChange={(date) => setValues({...values, InvoiceDate: date})}
                  className="dt-picker-grid4"
                  name='DeliveryDate'
                  // minDate={new Date()}
                  showDisabledMonthNavigation
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  placeholderText="Delivery Date"
                />
              </Grid>
       
              <Grid item xs={8} spacing={4}>
                <FormControl required sx={{ width: "100%" }}>
                  <InputLabel id="demo-controlled-open-select-label">Billing Address</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={values.BilliingAdrId}
                    label="Billing Address"
                    name='BilliingAdrId'
                    onChange={handleChange}
                    MenuProps={MenuProps}
                  >
                    {(customerAddress || []).map((a, i) => {
                      return <MenuItem value={a.AddressId} key={i}>{a.AddressName + ", " + a.Address + ", " + a.Landmark + ", " + a.Area + ", " + a.City + ", " + a.State + ", " + a.Pincode}</MenuItem >
                    })}
                  </Select>
                </FormControl>
            </Grid>

            </Grid>
          </Grid>

        : null} 

        {(orderItemList && orderItemList != "") ?
          <div>
            <Divider />
                <div style={{textAlign: "center", padding: 16}}>
                {(errorMsgOnAdd && errorMsgOnAdd != null) ?    
                  <div style={{textAlign: "center", padding: 16}}>
                    {(errorMsgOnAdd && errorMsgOnAdd !== "") && (
                      <Typography mb={2} variant="body2" color='error' >{errorMsgOnAdd}</Typography>
                    )}
                  </div> 
                :null}
                  <Button variant='contained' color='secondary' onClick={handleAddInvoice} >Add Invoice</Button>
                </div>
          </div>
        : null}
      </Card>
    </div>
  );
}







