import { CALL_API } from '../api';

/* Quotation Actions:---- */
export const ALL_QUOT_REQUEST = "ALL_QUOT_REQUEST";
export const ALL_QUOT_SUCCESS = "ALL_QUOT_SUCCESS";
export const ALL_QUOT_FAILURE = "ALL_QUOT_FAILURE";

export const CUST_QUOTDETAIL_REQUEST = "CUST_QUOTDETAIL_REQUEST";
export const CUST_QUOTDETAIL_SUCCESS = "CUST_QUOTDETAIL_SUCCESS";
export const CUST_QUOTDETAIL_FAILURE = "CUST_QUOTDETAIL_FAILURE";

export const ADD_QUOTATION_COMMENT_REQUEST = "ADD_QUOTATION_COMMENT_REQUEST";
export const ADD_QUOTATION_COMMENT_SUCCESS = "ADD_QUOTATION_COMMENT_SUCCESS";
export const ADD_QUOTATION_COMMENT_FAILURE = "ADD_QUOTATION_COMMENT_FAILURE";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

export const UPDATE_QUOTATION_REQUEST = "UPDATE_QUOTATION_REQUEST";
export const UPDATE_QUOTATION_SUCCESS = "UPDATE_QUOTATION_SUCCESS";
export const UPDATE_QUOTATION_FAILURE = "UPDATE_QUOTATION_FAILURE";

export const QUOTATION_STATUS_REQUEST = "QUOTATION_STATUS_REQUEST";
export const QUOTATION_STATUS_SUCCESS = "QUOTATION_STATUS_SUCCESS";
export const QUOTATION_STATUS_FAILURE = "QUOTATION_STATUS_FAILURE";

export const DELETE_QUOTATION_ITEM_REQUEST = "DELETE_QUOTATION_ITEM_REQUEST";
export const DELETE_QUOTATION_ITEM_SUCCESS = "DELETE_QUOTATION_ITEM_SUCCESS";
export const DELETE_QUOTATION_ITEM_FAILURE = "DELETE_QUOTATION_ITEM_FAILURE";

export const DELETE_QUOTATION_REQUEST = "DELETE_QUOTATION_REQUEST";
export const DELETE_QUOTATION_SUCCESS = "DELETE_QUOTATION_SUCCESS";
export const DELETE_QUOTATION_FAILURE = "DELETE_QUOTATION_FAILURE";

/* Order Actions:---- */
export const CONVERT_ORDER_REQUEST = "CONVERT_ORDER_REQUEST";
export const CONVERT_ORDER_SUCCESS = "CONVERT_ORDER_SUCCESS";
export const CONVERT_ORDER_FAILURE = "CONVERT_ORDER_FAILURE";

export const ALL_ORDER_REQUEST = "ALL_ORDER_REQUEST";
export const ALL_ORDER_SUCCESS = "ALL_ORDER_SUCCESS";
export const ALL_ORDER_FAILURE = "ALL_ORDER_FAILURE";

export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST"; 
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS";
export const ORDER_DETAIL_FAILURE = "ORDER_DETAIL_FAILURE";

export const ORDER_STATUS_REQUEST = "ORDER_STATUS_REQUEST";
export const ORDER_STATUS_SUCCESS = "ORDER_STATUS_SUCCESS";
export const ORDER_STATUS_FAILURE = "ORDER_STATUS_FAILURE";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAILURE = "DELETE_ORDER_FAILURE";

export const ORDER_ITEM_DETAIL_REQUEST = "ORDER_ITEM_DETAIL_REQUEST";
export const ORDER_ITEM_DETAIL_SUCCESS = "ORDER_ITEM_DETAIL_SUCCESS";
export const ORDER_ITEM_DETAIL_FAILURE = "ORDER_ITEM_DETAIL_FAILURE";

export const ORDER_ITEM_STATUS_REQUEST = "ORDER_ITEM_STATUS_REQUEST";
export const ORDER_ITEM_STATUS_SUCCESS = "ORDER_ITEM_STATUS_SUCCESS";
export const ORDER_ITEM_STATUS_FAILURE = "ORDER_ITEM_STATUS_FAILURE";

export const DELETE_ORDER_ITEM_REQUEST = "DELETE_ORDER_ITEM_REQUEST";
export const DELETE_ORDER_ITEM_SUCCESS = "DELETE_ORDER_ITEM_SUCCESS";
export const DELETE_ORDER_ITEM_FAILURE = "DELETE_ORDER_ITEM_FAILURE";

export const ADD_ORDER_ITEM_REQUEST = "ADD_ORDER_ITEM_REQUEST";
export const ADD_ORDER_ITEM_SUCCESS = "ADD_ORDER_ITEM_SUCCESS";
export const ADD_ORDER_ITEM_FAILURE = "ADD_ORDER_ITEM_FAILURE";

export const SUB_ORDERITEM_DETAIL_REQUEST = "SUB_ORDERITEM_DETAIL_REQUEST";
export const SUB_ORDERITEM_DETAIL_SUCCESS = "SUB_ORDERITEM_DETAIL_SUCCESS";
export const SUB_ORDERITEM_DETAIL_FAILURE = "SUB_ORDERITEM_DETAIL_FAILURE";

export const UPDATE_ORDER_ITEM_REQUEST = "UPDATE_ORDER_ITEM_REQUEST";
export const UPDATE_ORDER_ITEM_SUCCESS = "UPDATE_ORDER_ITEM_SUCCESS";
export const UPDATE_ORDER_ITEM_FAILURE = "UPDATE_ORDER_ITEM_FAILURE";

/* Payment Methods */

export const PAYMENT_METHOD_REQUEST = "PAYMENT_METHOD_REQUEST";
export const PAYMENT_METHOD_SUCCESS = "PAYMENT_METHOD_SUCCESS";
export const PAYMENT_METHOD_FAILURE = "PAYMENT_METHOD_FAILURE";

/* Quotation Actions:---- */
export const fetchAllQuotations = (body) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [ALL_QUOT_REQUEST, ALL_QUOT_SUCCESS, ALL_QUOT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'quotation/getquotations'
      }
    });
};
  
export const fetchQuotationDetail = (id) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
        types: [CUST_QUOTDETAIL_REQUEST, CUST_QUOTDETAIL_SUCCESS, CUST_QUOTDETAIL_FAILURE],
        method: 'GET',
        endpoint: 'quotation/getquotationdetail/' + id
        }
    });
};

export const addQuotationComment = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
        types: [ADD_QUOTATION_COMMENT_REQUEST, ADD_QUOTATION_COMMENT_SUCCESS, ADD_QUOTATION_COMMENT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'quotation/addquotationcomment'
        }
    });
};

export const deleteComment = (id) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [DELETE_COMMENT_REQUEST, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAILURE],
      method: 'GET',
      endpoint: 'quotation/deletecomment/' + id
      }
  });
};

export const updateQuotation = (body) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
        types: [UPDATE_QUOTATION_REQUEST, UPDATE_QUOTATION_SUCCESS, UPDATE_QUOTATION_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'quotation/updatequotation'
        }
    });
};

export const fetchQuotationStatus = () => ({
    [CALL_API]: {
        types: [QUOTATION_STATUS_REQUEST, QUOTATION_STATUS_SUCCESS, QUOTATION_STATUS_FAILURE],
        method: 'GET',
        endpoint: 'quotation/getquotationstatus'
    }
});

export const deleteQuotationItem = (id) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
        types: [DELETE_QUOTATION_ITEM_REQUEST, DELETE_QUOTATION_ITEM_SUCCESS, DELETE_QUOTATION_ITEM_FAILURE],
        method: 'GET',
        endpoint: 'quotation/deletequotationitem/' + id
        }
    });
};

export const deleteQuotation = (id) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [DELETE_QUOTATION_REQUEST, DELETE_QUOTATION_SUCCESS, DELETE_QUOTATION_FAILURE],
      method: 'GET',
      endpoint: 'quotation/delete/' + id
      }
  });
};


/* Order Actions:---- */
export const convertIntoOrder = (quotationId, custId) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
          types: [CONVERT_ORDER_REQUEST, CONVERT_ORDER_SUCCESS, CONVERT_ORDER_FAILURE],
          method: 'GET',
          endpoint: 'order/convertorder/' + quotationId + '/' + custId 
      }
    });
};

export const fetchAllOrders = (body) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [ALL_ORDER_REQUEST, ALL_ORDER_SUCCESS, ALL_ORDER_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'order/getorders'
      }
    });
};

export const fetchOrderDetail = (id) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [ORDER_DETAIL_REQUEST, ORDER_DETAIL_SUCCESS, ORDER_DETAIL_FAILURE],
        method: 'GET',
        endpoint: 'order/getorderdetail/' + id 
      }
    });
};

export const fetchOrderStatus = () => ({
    [CALL_API]: {
        types: [ORDER_STATUS_REQUEST, ORDER_STATUS_SUCCESS, ORDER_STATUS_FAILURE],
        method: 'GET',
        endpoint: 'order/getorderstatus'
    }
});

export const updateOrder = (body) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'order/update'
      }
  });
};

export const deleteOrder = (id) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [DELETE_ORDER_REQUEST, DELETE_ORDER_SUCCESS, DELETE_ORDER_FAILURE],
      method: 'GET',
      endpoint: 'order/delete/' + id
      }
  });
};

export const fetchOrderItemDetail = (orderId, quotationItemId) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [ORDER_ITEM_DETAIL_REQUEST, ORDER_ITEM_DETAIL_SUCCESS, ORDER_ITEM_DETAIL_FAILURE],
        method: 'GET',
        endpoint: 'order/getorderitemdetail/' + orderId + '/' +  quotationItemId
      }
    });
};

export const fetchOrderItemStatus = () => ({
    [CALL_API]: {
        types: [ORDER_ITEM_STATUS_REQUEST, ORDER_ITEM_STATUS_SUCCESS, ORDER_ITEM_STATUS_FAILURE],
        method: 'GET',
        endpoint: 'order/getitemstatus'
    }
});

export const AddOrderItem = (body) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [ADD_ORDER_ITEM_REQUEST, ADD_ORDER_ITEM_SUCCESS, ADD_ORDER_ITEM_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'order/addorderitem'
      }
  });
};

export const deleteOrderItem = (quotationItemId) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [DELETE_ORDER_ITEM_REQUEST, DELETE_ORDER_ITEM_SUCCESS, DELETE_ORDER_ITEM_FAILURE],
      method: 'GET',
      endpoint: 'order/cancelitem/' + quotationItemId
      }
  });
};

export const fetchSubOrderDetail = (id) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [SUB_ORDERITEM_DETAIL_REQUEST, SUB_ORDERITEM_DETAIL_SUCCESS, SUB_ORDERITEM_DETAIL_FAILURE],
      method: 'GET',
      endpoint: 'order/getdispatchitemdetail/' + id
      }
  });
};

export const updateSubOrderitem = (body) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [UPDATE_ORDER_ITEM_REQUEST, UPDATE_ORDER_ITEM_SUCCESS, UPDATE_ORDER_ITEM_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'order/updateitem'
      }
  });
};


/* Payment Options */

export const fetchPaymentMethods = () => ({
  [CALL_API]: {
      types: [PAYMENT_METHOD_REQUEST, PAYMENT_METHOD_SUCCESS, PAYMENT_METHOD_FAILURE],
      method: 'GET',
      endpoint: 'base/getpaymentmethod'
  }
});