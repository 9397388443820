import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getFormatedCurrency } from '../components/util';
import { format, parseISO } from 'date-fns';
import { fetchAllQuotations, deleteQuotation } from '../store/actions/quotation'; 
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
// import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Tooltip from '@mui/material/Tooltip';


export default function QuotationsPage(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteQuotId, setdeleteQuotId] = useState("");
  const quotations = useSelector(state => ( state.quotation.allQuotation || []));
  const isQuotFetching = useSelector(state => (state.quotation.isQuotFetching ));

  const mount = () => {
    dispatch(fetchAllQuotations());
  }
  useEffect(mount, []);

  const handleEditClick = (id) => {
    var fltr_quot = Object.assign({}, ...quotations.filter(q => { if(q.Id === id) return q; }));
    navigate("/editQuotation" , { state: { quotation: fltr_quot }} );
  };

  const handleDeleteClick = (id) => {
    // console.log("id clicked to delete: ");
    // console.log(id);
    dispatch(deleteQuotation(id)).then(r => dispatch(fetchAllQuotations()));
    setdialog(false);
  };

  const handleCellClick = (params) => {
    if(params.field !== "actions"){
      navigate("/viewQuotation", { state: { quotation: params.id }} );
    }
  };

  const columns = [
    { field: 'id', headerName: 'Id', align: "left", flex: 0.3 },
    { field: 'ponumber', headerName: 'PONumber', align: "left", flex: 0.5 },
    { field: 'totalitems', headerName: 'Total Items', align: "left", flex: 0.5 },
    { field: 'status', headerName: 'Status', align: "left", flex: 0.5 },
    { field: 'quotdate', headerName: 'Quotation Date', align: "left", flex: 0.8 },
    { field: 'totalamt', headerName: 'Total Amount', align: "left", flex: 0.5 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: (params) => {
        // (params && params.row && params.row.status && params.row.status !== "Confirmed") ?
        return [
          // <Tooltip title="View">
          //   <GridActionsCellItem
          //     icon={<RemoveRedEyeOutlinedIcon />}
          //     label="View"
          //     className="textPrimary"
          //     onClick={() => handleEditClick(params.id)}
          //     color="inherit"
          //   />
          // </Tooltip>,
          (params && params.row && params.row.status && params.row.status !== "Confirmed") ?
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(params.id)}
              color="inherit"
            />
          </Tooltip> : <span/>,
          (params && params.row && params.row.status && params.row.status !== "Confirmed") ?
          <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  setdeleteQuotId(params.id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"0.5" }} onClose={() => setdialog(false)} >
              <DialogTitle >
                {"Delete Quotation"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  Are you sure you wish to delete this Quotation ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteQuotId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div> : <span/>,
        ];
      }
    }
  ];

  const rows = quotations.map(q => {
    // console.log("quotation valuesssssss");
    // console.log(q);
    return ({ 
      id: q.Id,
      ponumber: q.PONumber,
      totalitems: q.TotalItems,
      status : q.Status,
      quotdate : format(parseISO(q.QuotationDate), "dd MMM yyyy 'at' hh:mm a"),
      totalamt : getFormatedCurrency(q.TotalAmount)
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Quotations" />
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addQuotation") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Quotation</Typography>
          </Button>
        </div>
        
        <Divider />
        
        {quotations && (
          <CardContent>
            <DataGrid
              style={{cursor:'pointer'}}
              rows={rows}
              columns={columns}
              loading={isQuotFetching ? true : false}
              autoHeight={18}
              pageSize={pageSize}
              onCellClick={handleCellClick}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}        
      
      </Card>
    </div>
  );
}