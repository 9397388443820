import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
// import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { deleteCategory, fetchCategories } from '../store/actions/services'; 

import { useNavigate } from 'react-router-dom';


import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
/* import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'; */
import Tooltip from '@mui/material/Tooltip';

export default function CategoriesPage(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteCatId, setdeleteCatId] = useState("");
  const categories = useSelector(state => (state.services.categories || []));
  const isCATFetching = useSelector(state => (state.services.isCATFetching ));

  const mount = () => {
    dispatch(fetchCategories());
  }
  // eslint-disable-next-line
  useEffect(mount, []);

  /* const handleViewClick = (id) => () => {
    navigate("/viewCategory", { state: { categoryId: id }});
  }; */

  const handleEditClick = (id) => {
    var fltr_cat = Object.assign({}, ...categories.filter(c => { if(c.Id === id) return c; }));
    navigate("/editCategory", { state: { category: fltr_cat }} );
  };

  const handleDeleteClick = (id) => {
    //console.log("clicked id");
    //console.log(id);
    dispatch(deleteCategory(id)).then(r => dispatch(fetchCategories()));
    setdialog(false);
  };

  const columns = [
    { field: 'id', headerName: 'Id', align: "left", flex: 0.5},
    { field: 'image', headerName: 'Image', align: "left", flex: 1,
     renderCell: (params)=>{
      return (
        <div>
          {/* <Avatar src={params.row.image} alt='CategoryImg' sx={{ width: 42, height: 42 }} /> */}
          <img src={params.row.image} alt='CategoryImg' width="42" height="42" style={{ borderRadius: "50%", objectFit:"contain"}} />
        </div>
      )}
    },
    { field: 'name', headerName: 'Name', align: "left", flex:1 },
    { field: 'isservice', headerName: 'Service', align: "left", flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  setdeleteCatId(id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"0.2" }} onClose={() => setdialog(false)} >
              <DialogTitle >
                {"Delete Category"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  Are you sure you wish to delete this Category ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteCatId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>,
        ];
      }
    }
  ];

  const rows = categories.map(c => {
    return ({ 
      id: c.Id,
      image: c.Image,
      name: c.Name, 
      isservice: (c.IsService === true) ? "Yes" : "No",
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Categories" />
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addCategory") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Category</Typography>
          </Button>
        </div>
        
        <Divider />
        
        {categories && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={isCATFetching ? true : false}
              autoHeight={18}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}
        
      </Card>
    </div>
  );
}