import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { fetchAllInvoice} from '../store/actions/invoice';

export default function InvoicePage(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const allInvoice = useSelector(state => (state.invoice.allInvoice || []));
  const isAllInvoiceFetching = useSelector(state => (state.invoice.isAllInvoiceFetching ));

  const mount = () => {
    dispatch(fetchAllInvoice());
  }
  // eslint-disable-next-line
  useEffect(mount, []);

  const handleViewClick = (id) => () => {
    var fltr_invoice = Object.assign({}, ...allInvoice.filter(c => c.Id === id));
    navigate("/invoiceDetail", { state: { invoiceId: fltr_invoice.Id }} );
  };

  const columns = [

    { field: 'id', headerName: 'Id', align: "left", flex: 0.3 },
    { field: 'customername', headerName: 'Customer Name', align: "left",flex:  1.1},
    { field: 'invoiceno', headerName: 'Invoice No', align: "left",flex: 1.2 },
    { field: 'status', headerName: 'Status', align: "left",flex: 0.9 },
    { field: 'totalamount', headerName: 'Total Amount', align: "left", flex: 0.5},
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="View">
            <GridActionsCellItem
              icon={<RemoveRedEyeOutlinedIcon />}
              label="View"
              className="textPrimary"
              onClick={handleViewClick(id)}
              color="inherit"
            />
          </Tooltip>
       ];
      }
    }
  ];

  const rows = allInvoice.map(i => {
    return ({ 
      id: i.Id,
      customername: i.CustomerFirstName + " " + i.CustomerLastName,
      invoiceno: i.InvoiceNo,
      status: i.Status,
      totalamount: i.TotalAmount,
    })
  });

return (    
  <div>
    <Card>
      <div style={{ display:"flex", flexDirection:"row" }}>
        <CardHeader title="Invoice" />
          {/* <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}>
            <Button variant='contained' color="error">Back</Button>
          </div> */}
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addInvoice") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Invoice</Typography>
          </Button>
      </div>
      <Divider />
      
      {allInvoice && (
        <CardContent>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={ isAllInvoiceFetching ? true : false }
            autoHeight={18}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
          />
        </CardContent>
      )}
 
    </Card>
  </div>
);
}









  
  