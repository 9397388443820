import {
  PRODUCTS_REQUEST, PRODUCTS_SUCCESS, PRODUCTS_FAILURE,
  PRODUCT_DETAIL_REQUEST, PRODUCT_DETAIL_SUCCESS, PRODUCT_DETAIL_FAILURE,
  ADDPRODUCT_REQUEST, ADDPRODUCT_SUCCESS, ADDPRODUCT_FAILURE,
  UPRODUCT_REQUEST, UPRODUCT_SUCCESS, UPRODUCT_FAILURE,
  DELBANIMG_REQUEST, DELBANIMG_SUCCESS, DELBANIMG_FAILURE,
  DELSMLIMG_REQUEST, DELSMLIMG_SUCCESS, DELSMLIMG_FAILURE,
  DELPRODUCT_REQUEST, DELPRODUCT_SUCCESS, DELPRODUCT_FAILURE,
  PRODUCTPRICE_REQUEST, PRODUCTPRICE_SUCCESS, PRODUCTPRICE_FAILURE,
  ADDPRODTPRICE_REQUEST, ADDPRODTPRICE_SUCCESS, ADDPRODTPRICE_FAILURE,
  UPDTPRODUCTPRICE_REQUEST, UPDTPRODUCTPRICE_SUCCESS, UPDTPRODUCTPRICE_FAILURE,
  DELPRODUCTPRICE_REQUEST, DELPRODUCTPRICE_SUCCESS, DELPRODUCTPRICE_FAILURE,

} from '../actions/product';

// eslint-disable-next-line
export default (state = {
  error: undefined,
  isPRFetching: false,
  isPRDDFetching: false,
  isPRDAdding: false,
  isPRUpdating: false,
  isBANIMGDeleting: false,
  isSMLIMGDeleting: false,
  isPRDeleting: false,
  isPRDPRICEFetching: false,
  isPRDPRICEAdding: false,
  isPRDPRICEUpdating: false,
  isPRDPRICEDeleting: false,
  products: [],
  currentProduct: {},
  productOffers: [],
  relatedProducts: [],
  productPrice: [],


}, action) => {
  switch (action.type) {
    case PRODUCTS_REQUEST:
      return {
        ...state,
        isPRFetching: true,
        error: undefined,
      };
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        isPRFetching: false,
        products: action.response,
      };
    case PRODUCTS_FAILURE:
      return {
        ...state,
        isPRFetching: false,
        error: action.error,
      };
    case PRODUCT_DETAIL_REQUEST:
      return {
        ...state,
        isPRDDFetching: true,
        // currentProduct: {},
        // productOffers: [],
        // relatedProducts: []
      };
    case PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        isPRDDFetching: false,
        currentProduct: action.response,
        error: undefined,
      };
    case PRODUCT_DETAIL_FAILURE:
      return {
        ...state,
        isPRDDFetching: false,
        error: action.error,
      };
    case ADDPRODUCT_REQUEST:
      return {
        ...state,
        isPRDAdding: true,
      };
    case ADDPRODUCT_SUCCESS:
      // console.log("add product reducer");
      // console.log(action);
      return {
        ...state,
        isPRDAdding: false,
        error: undefined,
      };
    case ADDPRODUCT_FAILURE:
      return {
        ...state,
        isPRDAdding: false,
        error: action.error,
      };
    case UPRODUCT_REQUEST:
      return {
        ...state,
        isPRUpdating: true
      };
    case UPRODUCT_SUCCESS:
      return {
        ...state,
        isPRUpdating: false,
        error: undefined
      };
    case UPRODUCT_FAILURE:
      return {
        ...state,
        isPRUpdating: false,
        error: action.error
      };
    case DELBANIMG_REQUEST:
      return {
        ...state,
        isBANIMGDeleting: true
      };
    case DELBANIMG_SUCCESS:
      return {
        ...state,
        isBANIMGDeleting: false,
        error: undefined
      };
    case DELBANIMG_FAILURE:
      return {
        ...state,
        isBANIMGDeleting: false,
        error: action.error
      }
    case DELSMLIMG_REQUEST:
      return {
        ...state,
        isSMLIMGDeleting: true
      };
    case DELSMLIMG_SUCCESS:
      return {
        ...state,
        isSMLIMGDeleting: false,
        error: undefined
      };
    case DELSMLIMG_FAILURE:
      return {
        ...state,
        isSMLIMGDeleting: false,
        error: action.error
      };
    case DELPRODUCT_REQUEST:
      return {
        ...state,
        isPRDeleting: true
      };
    case DELPRODUCT_SUCCESS:
      return {
        ...state,
        isPRDeleting: false,
        error: undefined
      };
    case DELPRODUCT_FAILURE:
      return {
        ...state,
        isPRDeleting: false,
        error: action.error
      };
    // Product Price 
    case PRODUCTPRICE_REQUEST:
      return {
        ...state,
        isPRDPRICEFetching: true,
      };
    case PRODUCTPRICE_SUCCESS:
      return {
        ...state,
        isPRDPRICEFetching: false,
        productPrice: action.response,
        error: undefined
      };
    case PRODUCTPRICE_FAILURE:
      return {
        ...state,
        isPRDPRICEFetching: false,
        error: action.error
      };
    case ADDPRODTPRICE_REQUEST:
      console.log("adding price request");
      return {
        ...state,
        isPRDPRICEAdding: true,
      };
    case ADDPRODTPRICE_SUCCESS:
      console.log("adding price success");
      return {
        ...state,
        isPRDPRICEAdding: false,
        error: undefined
      };
    case ADDPRODTPRICE_FAILURE:
      console.log("adding price failure");
      return {
        ...state,
        isPRDPRICEAdding: false,
        error: action.error
      };
    case UPDTPRODUCTPRICE_REQUEST:
      return {
        ...state,
        isPRDPRICEUpdating: true,
      };
    case UPDTPRODUCTPRICE_SUCCESS:
      return {
        ...state,
        isPRDPRICEUpdating: false,
        error: undefined
      };
    case UPDTPRODUCTPRICE_FAILURE:
      return {
        ...state,
        isPRDPRICEUpdating: false,
        error: action.error
      };
    case DELPRODUCTPRICE_REQUEST:
      return {
        ...state,
        isPRDPRICEDeleting: true,
      };
    case DELPRODUCTPRICE_SUCCESS:
      return {
        ...state,
        isPRDPRICEDeleting: false,
        error: undefined
      };
    case DELPRODUCTPRICE_FAILURE:
      return {
        ...state,
        isPRDPRICEDeleting: false,
        error: action.error
      };
    default:
      return state;
  }
}
