import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import configureStore from './store';
import { refreshAuthToken } from './store/actions/session';
import { Provider } from 'react-redux';
import Cookies from 'universal-cookie';
import './css/index.css';

import 'semantic-ui-css/semantic.min.css';

// Check for BaseAPI Env Variable
if (process.env.REACT_APP_BaseAPI !== undefined) {
  window.sessionStorage.setItem("BaseAPI", process.env.REACT_APP_BaseAPI);
}
else {
  window.sessionStorage.setItem("BaseAPI", "https://ecsappapi.greenstoneent.com/api/ecsapp/");
}
window.sessionStorage.setItem("RootAPI", window.sessionStorage.getItem("BaseAPI") + "");

const initialState = {};
const store = configureStore(initialState);

const cookies = new Cookies();
const authToken = cookies.get('authDetails');
if (authToken) {
    store.dispatch(refreshAuthToken());
}


const theme = createTheme({
  palette: {
		primary: {
			main: '#232323'
		},
		secondary: {
			main: '#F7D200'
		},
	},
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
        <CssBaseline />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
