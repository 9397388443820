import React ,{ useState, useEffect, } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { addServiceLocation, fetchServiceLocations, GetStateList, GetCityList } from '../store/actions/addresses';

export default function AddServiceLocationPage(props) {

  const [values, setValues] = useState({
    Area: '',
    CityId:'',
    IsDefault: false, 

  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const isCityListFatching = useSelector(state => (state.addresses.isCityListFatching || false));
  const CityList = useSelector(state => (state.addresses.CityList || []));


  const mount = () => {
    dispatch(GetCityList());
    dispatch(GetStateList());
  }
  // eslint-disable-next-line
  useEffect(mount, []);


  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const handlechangedropdown = (selected, state) => {
    setValues({ ...values, [state]: selected !== null ? selected.value : '' });
    setErrorMsg(null);
  }

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";

    if(values.Area === ""){
      isInputValid = false;
      errorMsg = "Please Enter Area Name";
    } else if(values.CityId === ""){
      isInputValid = false;
      errorMsg = "Please Select City Name";
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  }

  const handleSubmit = () => {
    if(isInputValid()){

      var AddServiceLocParams = {
        Area: values.Area,
        CityId: '' + values.CityId,
        // IsDefault: values.IsDefault,
      };

      console.log("submit values", AddServiceLocParams);
      dispatch(addServiceLocation(AddServiceLocParams)).then(r => dispatch(fetchServiceLocations()));
      navigate("/serviceLocations");
      
    }
  }

  const CitiesArray = CityList.map((c, i) => {
    return ({
      label: c.Name,
      value: c.Id
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Add Service Location" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/serviceLocations") }}>Cancel</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <div>
            <Card>
              <CardContent sx={{p: 5}}>
                <Grid container spacing={3}>
                  
                <Grid item xs={6} >
                    <TextField /* required */ fullWidth label="Area Name" value={values.Area} name="Area" onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select City"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        isLoading={isCityListFatching}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "CityId")}
                        options={CitiesArray}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} style={{marginTop:5}} >
                    <FormGroup aria-label="position" row >
                      <FormControlLabel control={<Checkbox defaultChecked={false} checked={values.IsDefault} onChange={(e) => { setValues({...values, IsDefault: e.target.checked}) }} />} 
                        labelPlacement="start" 
                        label="Check to set it as Default"
                      />
                    </FormGroup>
                  </Grid>

                </Grid>
              </CardContent>
              
              <Divider />
              
              <div style={{textAlign: "center", padding: 16}}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                <Button variant='contained' color='secondary' onClick={handleSubmit} >Add</Button>
              </div>
            </Card>
          </div>
        </CardContent>       
      </Card>
    </div>
  );
}