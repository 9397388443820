import { 
    ALL_INVOICE_LIST_REQUEST, ALL_INVOICE_LIST_SUCCESS, ALL_INVOICE_LIST_FAILURE,
    ODRITEM_LISTFOR_INVOICE_REQUEST, ODRITEM_LISTFOR_INVOICE_SUCCESS, ODRITEM_LISTFOR_INVOICE_FAILURE,
    LISTOUT_INVOICE_REQUEST, LISTOUT_INVOICE_SUCCESS, LISTOUT_INVOICE_FAILURE,
    INVOICE_DETAIL_REQUEST, INVOICE_DETAIL_SUCCESS, INVOICE_DETAIL_FAILURE,
    INVOICE_STATUS_REQUEST, INVOICE_STATUS_SUCCESS, INVOICE_STATUS_FAILURE,
    UPDATE_INVOICE_REQUEST, UPDATE_INVOICE_SUCCESS, UPDATE_INVOICE_FAILURE,
    GENERATE_INVOICE_REQUEST, GENERATE_INVOICE_SUCCESS, GENERATE_INVOICE_FAILURE

} from '../actions/invoice';

export default (
    state = {
        error: undefined,
        isAllInvoiceFetching: false,
        isOdrItemListFetching: false,
        isOrderItemListFetched : false,
        isInviceListingOut: false,
        isInvoiceDetailFetching: false,
        isInvoiceGenerating: false,
        allInvoice: [],
        orderItemList: [],
        invoiceDetail: [],
        isInvoiceUpdating: false,
        isInvoiceStatusFetching: false,
        invoiceStatus:[]



      
    },
    action
) => {
    switch (action.type) {

        /* All Invoice List */
        case ALL_INVOICE_LIST_REQUEST:
            return{
                ...state,
                isAllInvoiceFetching: true,
            };
        case ALL_INVOICE_LIST_SUCCESS:
            return{
                ...state,
                isAllInvoiceFetching: false,
                allInvoice: action.response,
                error: undefined
            };
        case ALL_INVOICE_LIST_FAILURE:
            return{
                ...state,
                isAllInvoiceFetching: false,
                error: action.error
            };

        /* Order Item List for Invoice */
        case ODRITEM_LISTFOR_INVOICE_REQUEST:
            return{
                ...state,
                isOdrItemListFetching: true,
            };
        case ODRITEM_LISTFOR_INVOICE_SUCCESS:
            return{
                ...state,
                isOdrItemListFetching: false,
                isOrderItemListFetched: true,
                orderItemList: action.response,
                error: undefined
            };
        case ODRITEM_LISTFOR_INVOICE_FAILURE:
            return{
                ...state,
                isOdrItemListFetching: false,
                error: action.error
            };

        /* List out invoice */
        case LISTOUT_INVOICE_REQUEST:
            return{
                ...state,
                isInviceListingOut: true,
            };
        case LISTOUT_INVOICE_SUCCESS:
            return{
                ...state,
                isInviceListingOut: false,
                error: undefined
            };
        case LISTOUT_INVOICE_FAILURE:
            return{
                ...state,
                isInviceListingOut: false,
                error: action.error
            };

        /* Invoice Detail */
        case INVOICE_DETAIL_REQUEST:
            return{
                ...state,
                isInvoiceDetailFetching: true,
            };
        case INVOICE_DETAIL_SUCCESS:
            return{
                ...state,
                isInvoiceDetailFetching: false,
                invoiceDetail: action.response,
                error: undefined
            };
        case INVOICE_DETAIL_FAILURE:
            return{
                ...state,
                isInvoiceDetailFetching: false,
                error: action.error
            };

         /* Invoice Status */
         case INVOICE_STATUS_REQUEST:
            return{
                ...state,
                isInvoiceStatusFetching: true,
            };
        case INVOICE_STATUS_SUCCESS:
            return{
                ...state,
                isInvoiceStatusFetching: false,
                invoiceStatus: action.response,
                error: undefined
            };
        case INVOICE_STATUS_FAILURE:
            return{
                ...state,
                isInvoiceStatusFetching: false,
                error: action.error
            };

        /* Invoice Update */
        case UPDATE_INVOICE_REQUEST:
            return{
                ...state,
                isInvoiceUpdating: true
            };
        case UPDATE_INVOICE_SUCCESS:
            return {
                ...state,
                isInvoiceUpdating: false,
                error: undefined
            };
        case UPDATE_INVOICE_FAILURE:
            return {
                ...state,
                isInvoiceUpdating: false,
                error: action.error
            };
        
        /* Invoice Generate */
        case GENERATE_INVOICE_REQUEST:
            return{
                ...state,
                isInvoiceGenerating: true,
            };
        case GENERATE_INVOICE_SUCCESS:
            return{
                ...state,
                isInvoiceGenerating: false,
                error: undefined
            };
        case GENERATE_INVOICE_FAILURE:
            return{
                ...state,
                isInvoiceGenerating: false,
                error: action.error
            };

            default:
            return state;
        }
    }