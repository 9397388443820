import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { fetchProductPrice, deleteProductPrice } from '../store/actions/product';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Tooltip from '@mui/material/Tooltip';
/* import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'; */

export default function ProductPricePage(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteProductPriceId, setdeleteProductPriceId] = useState("");

  const productsPrice = useSelector(state => (state.product.productPrice || []));
  const isPRDPRICEFetching = useSelector(state => (state.product.isPRDPRICEFetching));

  const mount = () => {
    dispatch(fetchProductPrice());

  }
  // eslint-disable-next-line
  useEffect(mount, []);

  const handleEditClick = (id) => () => {
    var fltr_prdPrice = Object.assign({}, ...productsPrice.filter(c => { if(c.Id === id) return c; }));
    navigate("/EditProductPrice", { state: { productPrice: fltr_prdPrice }} );
  };

  const handleDeleteClick = (id) => {
    dispatch(deleteProductPrice(id)).then(r => dispatch(fetchProductPrice({})));
    setdialog(false);
  };

  const columns = [
    { field: 'id', headerName: 'Id', align: "left", flex: 0.2 },
    { field: 'products', headerName: 'Products', align: "left", flex: 0.8 },
    { field: 'location', headerName: 'Locations', align: "left", flex: 0.8 },
    // { field: 'postcode', headerName: 'Postal Code', align: "center", flex: 0.3 },
    { field: 'originalPrice', headerName: 'Original Price', align: "center", flex: 0.3 },
    { field: 'listPrice', headerName: 'List Price', align: "center", flex: 0.3 },
    { field: 'displayPrice', headerName: 'Display Price', align: "center", flex: 0.3 },
    { field: 'deliveryCharge', headerName: 'Delivery Charge', align: "center", flex: 0.3 },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      // width: "100%",
      flex: 0.3,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  setdeleteProductPriceId(id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"0.15" }} onClose={() => setdialog(false)} >
              <DialogTitle >
                {"Delete Product Price"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  Are you sure you wish to delete this Product Price ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteProductPriceId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>,
        ];
      }
    }
  ];

  const rows = productsPrice.map(a => {
    const location = a.CityId !== 0 ? "City:-  " + a.City : (a.StateId ? "State:-  " + a.State : (a.PostcodeId ? "Postal Code:-  " + a.Postcode : (a.ServiceLocationId ? "Area:-  " + a.ServiceLocation : null )));
    return ({ 
      id: a.Id,
      products: (a.BrandName + " - " + a.ProductName + " (" + a.Size + ") "),
      location: location,
      // postcode: a.Postcode,
      originalPrice: a.OriginalPrice,
      listPrice: a.ListPrice,
      displayPrice: a.DisplayPrice,
      deliveryCharge: a.DeliveryCharge
    })

  });
  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Products Price" />
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/AddProductPrice") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Product Price</Typography>
          </Button>
        </div>
        <Divider />
        
        {productsPrice && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={ isPRDPRICEFetching ? true : false }
              autoHeight={18}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}
   
      </Card>
    </div>
  );
}