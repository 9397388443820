import { CALL_API } from '../api';

/* Invoice List */
export const ALL_INVOICE_LIST_REQUEST = "ALL_INVOICE_LIST_REQUEST";
export const ALL_INVOICE_LIST_SUCCESS = "ALL_INVOICE_LIST_SUCCESS";
export const ALL_INVOICE_LIST_FAILURE = "ALL_INVOICE_LIST_FAILURE";

/* Order Item list for Invoice */
export const ODRITEM_LISTFOR_INVOICE_REQUEST = "ODRITEM_LISTFOR_INVOICE_REQUEST";
export const ODRITEM_LISTFOR_INVOICE_SUCCESS = "ODRITEM_LISTFOR_INVOICE_SUCCESS";
export const ODRITEM_LISTFOR_INVOICE_FAILURE = "ODRITEM_LISTFOR_INVOICE_FAILURE";

/* List out Invoice */
export const LISTOUT_INVOICE_REQUEST = "LISTOUT_INVOICE_REQUEST";
export const LISTOUT_INVOICE_SUCCESS = "LISTOUT_INVOICE_SUCCESS";
export const LISTOUT_INVOICE_FAILURE = "LISTOUT_INVOICE_FAILURE";

/* Invoice Detail */
export const INVOICE_DETAIL_REQUEST = "INVOICE_DETAIL_REQUEST";
export const INVOICE_DETAIL_SUCCESS = "INVOICE_DETAIL_SUCCESS";
export const INVOICE_DETAIL_FAILURE = "INVOICE_DETAIL_FAILURE";

/* Invoice Status */
export const INVOICE_STATUS_REQUEST = "INVOICE_STATUS_REQUEST";
export const INVOICE_STATUS_SUCCESS = "INVOICE_STATUS_SUCCESS";
export const INVOICE_STATUS_FAILURE = "INVOICE_STATUS_FAILURE";

/* Invoice Update */
export const UPDATE_INVOICE_REQUEST = "UPDATE_INVOICE_REQUEST";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAILURE = "UPDATE_INVOICE_FAILURE";

/* Generate Invoice */
export const GENERATE_INVOICE_REQUEST = "GENERATE_INVOICE_REQUEST";
export const GENERATE_INVOICE_SUCCESS = "GENERATE_INVOICE_SUCCESS";
export const GENERATE_INVOICE_FAILURE = "GENERATE_INVOICE_FAILURE";


/* Invoice List */
export const fetchAllInvoice = (body) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [ALL_INVOICE_LIST_REQUEST, ALL_INVOICE_LIST_SUCCESS, ALL_INVOICE_LIST_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'invoice/getinvoicelist'
      }
    });
};

/* Order Item list for Invoice */
export const fetchOdrItemListForInvoice = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [ODRITEM_LISTFOR_INVOICE_REQUEST, ODRITEM_LISTFOR_INVOICE_SUCCESS, ODRITEM_LISTFOR_INVOICE_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'invoice/getorders'
    }
  });
};

/* List out Invoice */
export const ListOutInvoice = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
      types: [LISTOUT_INVOICE_REQUEST, LISTOUT_INVOICE_SUCCESS, LISTOUT_INVOICE_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'invoice/save'
    }
  });
};

/* Invoice Detail */
export const fetchInvoiceDetail = (invoiceId) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [INVOICE_DETAIL_REQUEST, INVOICE_DETAIL_SUCCESS, INVOICE_DETAIL_FAILURE],
      method: 'GET',
      endpoint: 'invoice/getinvoicedetail/' + invoiceId
      }
  });
};

/* Invoice Status */
export const fetchInvoiceStatus = (invoiceId) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [INVOICE_STATUS_REQUEST, INVOICE_STATUS_SUCCESS, INVOICE_STATUS_FAILURE],
      method: 'GET',
      endpoint: 'invoice/getinvoicestatus'
      }
  });
};

/* Invoice Update */
export const updateInvoice = (body) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [UPDATE_INVOICE_REQUEST, UPDATE_INVOICE_SUCCESS, UPDATE_INVOICE_FAILURE],
      method: 'POST',
      body: body,
      endpoint: 'invoice/update'
      }
  });
};

/* Generate Invoice */
export const DownloadInvoice = (invoiceId) => (dispatch, getState) => {
  return dispatch({
      [CALL_API]: {
      types: [GENERATE_INVOICE_REQUEST, GENERATE_INVOICE_SUCCESS, GENERATE_INVOICE_FAILURE],
      method: 'GET',
      endpoint: 'invoice/generateinvoice/' + invoiceId
      }
  });
};