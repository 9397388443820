import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import { RupeeSymbol } from '../components/util';
import { fetchVendors } from '../store/actions/vendor';
import { AddOrderItem, fetchOrderItemStatus } from '../store/actions/quotation';
import { fetchDeliveryReqItemDetail, updateDlryReqItemStatus } from '../store/actions/deliveryChallan';


export default function EditDeliveryRequestItemPage(props) {

  const [values, setValues] = useState({
    DlryReqItemDetail: {Product:{}, DeliveryOrPickupAddress:{}}
  });

  const dlryReqItemDetail = useSelector(state => (state.deliveryChallan.deliveryRequestItemDetail || []));
  const vendorList = useSelector(state => (state.vendor.vendors || []));
  const orderItemStatus = useSelector(state => (state.quotation.orderItemStatus || []));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const handleItemsValueChange = (event) => {
    var odr = values.DlryReqItemDetail;
    // console.log("before oder");
    // console.log(JSON.stringify(odr));
    odr[event.target.name] = event.target.value;
    // console.log("updated values of oder");
    // console.log(JSON.stringify(odr));
    setValues({ ...values, DlryReqItemDetail: odr });
  };

  const handleItemsAddressChange = (fieldname, event) => {

    var odr = values.DlryReqItemDetail;

    odr[event.target.name] = event.target.value;

    if(fieldname === "Transportation"){
      odr.DeliveryDateTime = null;
      odr.PickupDateTime = null;
      odr.DeliveryAddressId = 0;
      odr.PickupAddressId = 0;
      odr.DeliveryOrPickupAddress.AddressId = 0;
      odr.DeliveryOrPickupAddress.AddressName = "";
      odr.DeliveryOrPickupAddress.Address = "";
      odr.DeliveryOrPickupAddress.Landmark = "";
      odr.DeliveryOrPickupAddress.Area = "";
      odr.DeliveryOrPickupAddress.City = "";
      odr.DeliveryOrPickupAddress.State = "";
      odr.DeliveryOrPickupAddress.Pincode = "";
    }
    var Sltd_Add = {};
    if(fieldname === "Delivery"){
      Sltd_Add = Object.assign({}, ...odr.CustomerAddressBook.filter(a => { if(a.AddressId === odr.DeliveryAddressId) return a; }))
    }
    if(fieldname === "PickUp"){
      Sltd_Add = Object.assign({}, ...odr.PickupAddress.filter(a => { if(a.AddressId === odr.PickupAddressId) return a; }))
    }

    if(Sltd_Add && Sltd_Add.AddressId){
      odr.DeliveryOrPickupAddress.AddressId = Sltd_Add.AddressId;
      odr.DeliveryOrPickupAddress.AddressName = Sltd_Add.AddressName;
      odr.DeliveryOrPickupAddress.Address = Sltd_Add.Address;
      odr.DeliveryOrPickupAddress.Landmark = Sltd_Add.LandMark;
      odr.DeliveryOrPickupAddress.Area = Sltd_Add.Area;
      odr.DeliveryOrPickupAddress.City = Sltd_Add.City;
      odr.DeliveryOrPickupAddress.State = Sltd_Add.State;
      odr.DeliveryOrPickupAddress.Pincode = Sltd_Add.Pincode;
    }

    setValues({...values, DlryReqItemDetail: odr});
    // setErrorMsg(null);
  };

  const handleDateChange = (dt) => {
    var odr = values.DlryReqItemDetail;
    
    // console.log("before oder  dt");
    // console.log(JSON.stringify(odr));
    
    if(odr && odr.Transportation === "No"){
      odr.PickupDateTime = dt;
    }
    else if(odr){
      odr.DeliveryDateTime = dt;
    }
    // console.log("updated values of oder  dt");
    // console.log(JSON.stringify(odr));
    setValues({...values, DlryReqItemDetail: odr});
    // setErrorMsg(null);
  };


  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };

  const transportation = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];

  const mount = () => {
    dispatch(fetchDeliveryReqItemDetail(location.state.DeliveryReqItemId));
    dispatch(fetchOrderItemStatus());
    dispatch(updateDlryReqItemStatus());
    dispatch(fetchVendors());

  }
  useLayoutEffect(mount, []);


  useEffect(() => {

    //All this info is there in quotDetail

    // console.log("order detail updated");
    setValues({
      ...values,
      DlryReqItemDetail: { ...dlryReqItemDetail }
    });

  }, [dlryReqItemDetail]);

 

  const handleSaveOrderItem = () => {
      dispatch(AddOrderItem(values.DlryReqItemDetail));
      navigate("/editDeliveryRequest", { state: { dlryReq: dlryReqItemDetail.DeliveryRequestId}});
    // }
  }

  // const handleUpdateItemStatus = () => {
  //   var DlryReqestStatus = {
  //     DeliveryRequestId: values.DlryReqItemDetail.DeliveryRequestId,
  //     DeliveryRequestItemId: values.DlryReqItemDetail.DeliveryRequestItemId,
  //     Status: values.DlryReqItemDetail.Status
  //   };
  //   dispatch(updateDlryReqItemStatus(DlryReqestStatus)).then(r => dispatch(fetchDeliveryReqItemDetail(location.state.DeliveryReqItemId)));
  // }

  const Prod = values.DlryReqItemDetail.Product;
  const DeliveryAddress = values.DlryReqItemDetail.DeliveryOrPickupAddress;

  // console.log("initially values of delivery request status");
  // console.log(DeliveryRequestStatus);  

  return (
    <div>
      <Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardHeader title="Edit Delivery Request Item" />
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: 18 }}>
            <Button variant='contained' color="error" onClick={() => { navigate("/editDeliveryRequest", { state: { dlryReq: dlryReqItemDetail.DeliveryRequestId  } }) }}>Back</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <Grid container>
            <div style={{ borderRadius: 5, backgroundColor: "#D9D9D9", width: "100%", marginRight: 3, paddingInline: 15 }}>
              <div
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ fontSize: 22, fontWeight: "bold", marginLeft: -10 }}>
                  <CardHeader title={(Prod) ? Prod.BrandName + " - " + Prod.ProductName + " (" + Prod.ProductSize + ") " : ""} />
                </div>
                <div style={{ justifyContent: "flex-end" }}>
                  {/* {!values.showproduct ?
                    <ExpandMoreIcon style={{height:25, width:25}}/>
                    : <ExpandLessIcon style={{height:25, width:25}}/>
                  } */}
                  <ExpandLessIcon style={{ height: 25, width: 25 }} />
                </div>
              </div>

              {/* {values.showproduct ? */}
              <div>

                <Card style={{ width: "100%", backgroundColor: "white", marginBottom: 15, borderRadius: 5, }}>
                  <div style={{ padding: 20 }}>
                    <Grid container spacing={2} display="flex">
                      <Grid item xs={3} container >
                        <img src={Prod ? Prod.ProductImage : null} alt={"Product img"} width="100%" height="100%" style={{ borderRadius: 5, borderStyle: 'outset', borderWidth: 1, borderColor: "#C4C4C4", }} />
                      </Grid>

                      <Grid item xs={9} spacing={2} container style={{ marginTop: 5 }}>
                        <Grid item xs={4}>
                          <TextField
                            required
                            InputProps={{
                              readOnly: true,
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Quotation Price" type="number" value={values.DlryReqItemDetail.QuotationPrice} name="QuotationPrice" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            InputProps={{
                              // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Our Price" type="number" value={values.DlryReqItemDetail.OrderItemPrice} name="OrderItemPrice" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            InputProps={{
                              // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Vendor Price" type="number" value={values.DlryReqItemDetail.VendorPrice} name="VendorPrice" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl required sx={{ width: "100%" }}>
                            <InputLabel id="demo-controlled-open-select-label">Vendor Name</InputLabel>
                            <Select
                              InputProps={{
                                // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              // disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={values && values.DlryReqItemDetail && values.DlryReqItemDetail.VendorId ? values.DlryReqItemDetail.VendorId : ""}
                              label="Vendor Name"
                              name='VendorId'
                              onChange={(e) => handleItemsValueChange(e)}
                              MenuProps={MenuProps}
                            >
                              <MenuItem value={null}> <em>None</em> </MenuItem>
                              {vendorList.map((v, i) => {
                                return <MenuItem value={v.Id} key={i}>{v.FullName}</MenuItem >
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="Pending Quantity" value={values.DlryReqItemDetail.Quantity - values.DlryReqItemDetail.DispatchQuantity} name="Quantity" />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            // disabled = {values.QuotDetail.Status === "Confirmed" ? true : false}
                            fullWidth label="Process Quantity" type="number" value={values && values.DlryReqItemDetail && values.DlryReqItemDetail.OrderQuantity ? values.DlryReqItemDetail.OrderQuantity : ""} name="OrderQuantity" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>

                        <Grid item xs={4}>
                          <DatePicker
                            selected={(values && values.DlryReqItemDetail && values.DlryReqItemDetail.Transportation === "No" && values.DlryReqItemDetail.PickupDateTime) ? values.DlryReqItemDetail.PickupDateTime : ((values && values.DlryReqItemDetail && values.DlryReqItemDetail.DeliveryDateTime) ? values.DlryReqItemDetail.DeliveryDateTime : null)}
                            showIcon
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            popperClassName="date-popper"
                            onChange={handleDateChange}
                            className="dt-picker"
                            name='dateandtime'
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            placeholderText="Expected Delivery Time"
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl required sx={{ width: "100%" }}>
                            <InputLabel id="demo-controlled-open-select-label">Status</InputLabel>
                            <Select
                              InputProps={{
                                // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={values.DlryReqItemDetail.Status}
                              label="Status"
                              name='Status'
                              // onChange={handleSelectChange("Status")}
                              onChange={(e) => handleItemsValueChange(e)}
                              MenuProps={MenuProps}
                            >
                              {orderItemStatus.map((s, i) => {
                                return <MenuItem value={s.Name} key={i}>{s.Name}</MenuItem >
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            required
                            InputProps={{
                              // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Discount" type="number" value={values.DlryReqItemDetail.Discount} name="Discount" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>

                      </Grid>

                      <Grid item xs={12} spacing={2} container>

                        <Grid item xs={3}>
                          <TextField
                            required
                            InputProps={{
                              // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Service Charge" type="number" value={values.DlryReqItemDetail.ServiceCharge} name="ServiceCharge" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            required
                            InputProps={{
                              // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                            }}
                            fullWidth label="Delivery Charges" type="number" value={values.DlryReqItemDetail.DeliveryCharge} name="DeliveryCharge" onChange={(e) => handleItemsValueChange(e)} />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl required sx={{ width: "100%" }}>
                            <InputLabel id="demo-controlled-open-select-label">Transportation</InputLabel>
                            <Select
                              InputProps={{
                                // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={values && values.DlryReqItemDetail && values.DlryReqItemDetail.Transportation ? values.DlryReqItemDetail.Transportation : ""}
                              label="Transportation"
                              name='Transportation'
                              onChange={(e) => handleItemsAddressChange("Transportation", e)}
                              MenuProps={MenuProps}
                            >
                              {transportation.map((t, i) => {
                                return <MenuItem value={t.value} key={i}>{t.label}</MenuItem >
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        {(values.DlryReqItemDetail.Transportation === "Yes") ?
                          <Grid item xs={3}>
                            <FormControl required sx={{ width: "100%" }}>
                              <InputLabel id="demo-controlled-open-select-label">Delivery Address</InputLabel>
                              <Select
                                InputProps={{
                                  // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                                }}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={values && values.DlryReqItemDetail && values.DlryReqItemDetail.DeliveryAddressId ? values.DlryReqItemDetail.DeliveryAddressId : ""}
                                label="Delivery Address"
                                name='DeliveryAddressId'
                                onChange={(e) => handleItemsAddressChange("Delivery", e)}
                                MenuProps={MenuProps}
                              >
                                {(values && values.DlryReqItemDetail && values.DlryReqItemDetail.CustomerAddressBook || []).map((a, i) => {
                                  return <MenuItem value={a.AddressId} key={i}>{a.AddressName}</MenuItem >
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          :
                          <Grid item xs={3}>
                            <FormControl required sx={{ width: "100%" }}>
                              <InputLabel id="demo-controlled-open-select-label">PickUp Address</InputLabel>
                              <Select
                                InputProps={{
                                  // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                                }}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={values && values.DlryReqItemDetail && values.DlryReqItemDetail.PickupAddressId ? values.DlryReqItemDetail.PickupAddressId : ""}
                                label="PickUp Address"
                                name='PickupAddressId'
                                onChange={(e) => handleItemsAddressChange("PickUp", e)}
                                MenuProps={MenuProps}
                              >
                                {(values && values.DlryReqItemDetail && values.DlryReqItemDetail.PickupAddress || []).map((p, i) => {
                                  return <MenuItem value={p.AddressId} key={i}>{p.AddressName}</MenuItem >
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                         } 

                        <Grid item xs={3}>
                          <TextField
                            // required
                            // disabled 
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="Address" value={DeliveryAddress && DeliveryAddress.Address ? DeliveryAddress.Address : ""} name="Address" /* onChange={ (e) => handleItemsValueChange(e,i)} */ />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            // required 
                            // disabled
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="Area" value={DeliveryAddress && DeliveryAddress.Area ? DeliveryAddress.Area : ""} name="Area" /* onChange={ (e) => handleItemsValueChange(e,i)} */ />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            // required 
                            // disabled
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="City & State" value={DeliveryAddress ? DeliveryAddress.City + ", " + DeliveryAddress.State : ""} name="City" /* onChange={ (e) => handleItemsValueChange(e,i)} */ />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            // required
                            // disabled 
                            inputProps={
                              { readOnly: true, }
                            }
                            fullWidth label="Pin Code" type="number" value={DeliveryAddress && DeliveryAddress.Pincode ? DeliveryAddress.Pincode : ""} />
                        </Grid>
                        {(values.DlryReqItemDetail.Transportation === "Yes") ?
                          <Grid item xs={3}>
                            <TextField
                              required
                              InputProps={{
                                // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              fullWidth label="Driver Name" value={values.DlryReqItemDetail.DriverName} name="DriverName" onChange={(e) => handleItemsValueChange(e)} />
                          </Grid>
                       : null
                        } 
                        {(values.DlryReqItemDetail.Transportation === "Yes") ?
                          <Grid item xs={3}>
                            <TextField
                              required
                              InputProps={{
                                // maxLength: 10,
                                // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                              }}
                              fullWidth label="Driver Contact Number" type="number" value={values.DlryReqItemDetail.DriverContact} name="DriverContact" onChange={(e) => handleItemsValueChange(e)} />
                          </Grid>
                         : null 
                       } 
                        {(values.DlryReqItemDetail.Transportation === "Yes") ?
                          
                          <Grid item xs={3}>
                            <TextField
                              required
                              InputProps={{
                                // readOnly: (values.OrderItemDetail.OrderItemStatus === "CANLD" ? true : false),
                              }}
                              fullWidth label="Vehicle Number" value={values.DlryReqItemDetail.VehicleNumber} name="VehicleNumber" onChange={(e) => handleItemsValueChange(e)} />
                          </Grid>
                          : null 
                         } 
                      </Grid>
                    </Grid>

                  </div>
                </Card>


              </div>
            </div>

          </Grid>
        </CardContent>
        <Divider />
        <div style={{ display: "flex", padding: 16, flexDirection: "row", justifyContent: "center" }}>
          <Button variant='contained' color='secondary' onClick={handleSaveOrderItem}>Generate Order Item</Button>
          {/* <Button variant='contained' color='secondary' onClick={handleUpdateItemStatus}>Update Item Status</Button> */}
        </div>

      </Card>
    </div>
  );
}