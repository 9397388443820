import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import { useNavigate } from 'react-router-dom';

import { addPickupAddress, fetchPickupAddress } from '../store/actions/addresses';



export default function AddPickupLocationPage(props) {
  const [values, setValues] = useState({
    Name: '', 
    Address: '',
    Landmark: '',
    Area: '',
    City: '',
    State: '',
    Pincode: '',
    IsDefault: false,
    //Status: false,
  });
  const [errorMsg, setErrorMsg] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mount = () => {
    
  };
  // eslint-disable-next-line
  useEffect(mount, []);

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";

    if(values.Name === "" || values.Address === "" || values.Landmark === "" || values.Area === "" || values.City === "" || values.State === "" ||
      values.Pincode === "" ){
      isInputValid = false;
      errorMsg = "Please fill all the * marked fields.";
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  };

  const handleSubmit = () => {
    if(isInputValid()){
      var pickuploc = {     
      Name: values.Name,
      Address: values.Address,
      LandMark: values.Landmark,
      Area: values.Area,
      City: values.City,
      State: values.State,
      PinCode: values.Pincode,
      IsDefault: values.IsDefault,
      //Status: values.Status === true ? "Active" : "Inactive",
      };

      //console.log("Pickup Location");
      //console.log(pickuploc);
      dispatch(addPickupAddress(pickuploc)).then(r => dispatch(fetchPickupAddress()));
      navigate('/pickupLocations');

    }
  };

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Add Pickup Location" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/pickupLocations") }}>Back</Button>
          </div>
        </div>
        
        <Divider />
        
        <CardContent sx={{p: 5}}>
          <Grid container spacing={3}>
            
            <Grid item xs={6} >
              <TextField required fullWidth label="Name" value={values.Name} name="Name" onChange={handleChange} />
            </Grid>

            <Grid item xs={6} >
              <TextField required multiline fullWidth label="Address" value={values.Address} name="Address" onChange={handleChange} />
            </Grid>

            <Grid item xs={6} >
              <TextField required fullWidth label="Landmark" value={values.Landmark} name="Landmark" onChange={handleChange} />
            </Grid>

            <Grid item xs={6} >
              <TextField required fullWidth label="Area" value={values.Area} name="Area" onChange={handleChange} />
            </Grid>

            <Grid item xs={6} >
              <TextField required fullWidth label="City" value={values.City} name="City" onChange={handleChange} />
            </Grid>

            <Grid item xs={6} >
              <TextField required fullWidth label="State" value={values.State} name="State" onChange={handleChange} />
            </Grid>

            <Grid item xs={6} >
              <TextField required fullWidth label="Pincode" type="number" inputProps={{minlength:0, maxLength: 6}} value={values.Pincode} name="Pincode" onChange={handleChange} />
            </Grid>

            <Grid item xs={6} >
              <FormGroup aria-label="position" row >
                <FormControlLabel control={<Checkbox defaultChecked={false} checked={values.IsDefault} onChange={(e) => { setValues({...values, IsDefault: e.target.checked}) }} />} 
                  labelPlacement="start" 
                  label="Check to set it as Default"
                />
              </FormGroup>
            </Grid>

            {/* <Grid item xs={6} >
              <FormGroup aria-label="position" row >
                <FormControlLabel control={<Checkbox defaultChecked={false} checked={values.Status} onChange={(e) => {  setValues({...values, Status: e.target.checked }) }} />} 
                  labelPlacement="start" 
                  label="Check to make it's status Active"
                />
              </FormGroup>
            </Grid> */}
          </Grid>

        </CardContent>

        <Divider />

        <div style={{textAlign: "center", padding: 16}}>
          {(errorMsg && errorMsg !== "") && (
            <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
          )}
          {/* (isPLOCAdding) ?
            <Button variant='contained' color='secondary' >Adding...</Button> : */
            <Button variant='contained' color='secondary' onClick={handleSubmit}>Add</Button>
          }
        </div>
        
      </Card>
    </div>
  );
}