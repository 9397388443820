import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import LogoutButton from './LogoutButton';


function Navbar() {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Typography variant="h6" noWrap component="div" style={{flexGrow: 1, paddingTop: 6}}>
          <Link to='/'>
            <img src={Logo} alt="ECS" />	
          </Link>
        </Typography>
        <LogoutButton />
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;