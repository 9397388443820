import { 
    FETCHCUSTOMER_REQUEST, FETCHCUSTOMER_SUCCESS, FETCHCUSTOMER_FAILURE,
    DELETECUSTOMER_REQUEST, DELETECUSTOMER_SUCCESS, DELETECUSTOMER_FAILURE,
    CUSTOMER_ADDRESS_REQUEST, CUSTOMER_ADDRESS_SUCCESS, CUSTOMER_ADDRESS_FAILURE

} from '../actions/customer';

export default (
    state = {
        error: undefined,
        isCustomerFetching: false,
        customers: [],
        isCustomerDeleting: false,
        isCustomerAdrsFerching : false,
        customerAddress: []
    },
    action
) => {
    switch (action.type) {
        /* Get Customer List */
        case FETCHCUSTOMER_REQUEST:
            return{
                ...state,
                isCustomerFetching: true
            };
        case FETCHCUSTOMER_SUCCESS:
            return{
                ...state,
                isCustomerFetching: false,
                customers: action.response,
                error: undefined
            };
        case FETCHCUSTOMER_FAILURE:
            return{
                ...state,
                isCustomerFetching: false,
                error: action.error
            };

        /* Delete Customer */
        case DELETECUSTOMER_REQUEST:
            return{
                ...state,
                isCustomerDeleting: true
            };
        case DELETECUSTOMER_SUCCESS:
            return{
                ...state,
                isCustomerDeleting: false,
                error: undefined
            };
        case DELETECUSTOMER_FAILURE:
            return{
                ...state,
                isCustomerDeleting: false,
                error: action.error
            };

        /* Get Customer Address */
        case CUSTOMER_ADDRESS_REQUEST:
            return{
                ...state,
                isCustomerAdrsFerching: true
            };
        case CUSTOMER_ADDRESS_SUCCESS:
            return{
                ...state,
                isCustomerAdrsFerching: false,
                customerAddress: action.response,
                error: undefined
            };
        case CUSTOMER_ADDRESS_FAILURE:
            return{
                ...state,
                isCustomerAdrsFerching: false,
                error: action.error
            };

        default:
            return state;
    }
}
          
        