import React ,{ useState, useEffect, useLayoutEffect  } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { GetCityList, UpdateCity, GetStateList} from '../store/actions/addresses';
import { useLocation, useNavigate } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';


export default function EditCityPage(props) {
  
  const [values, setValues] = useState({
    Id:'',
    CityName:'',
    StateId: '',

  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);

  const isStateListFatching = useSelector(state => (state.addresses.isStateListFatching || false));
  const StateList = useSelector(state => (state.addresses.StateList || []));

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const handlechangedropdown = (selected, state) => {   
    setValues({ ...values, [state]: selected !== null ? selected.value : '' });
    setErrorMsg(null);
  }


  const location = useLocation();

  const mount = () => {

    dispatch(GetStateList());
  }
  // eslint-disable-next-line
  useEffect(mount, []);

  useEffect( () =>{
    
    if(location.state && location.state.CityDetail){
      setValues({
        Id: (location.state.CityDetail && location.state.CityDetail.Id) || "",
        CityName: (location.state.CityDetail && location.state.CityDetail.Name) || "",
        StateId: (location.state.CityDetail && location.state.CityDetail.StateId) || "",

      });
    }
  }, [location]);

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";

    if(values.CityName === ""){
      isInputValid = false;
      errorMsg = "Please Enter the City Name.";
    } else if (values.StateId === ""){
        isInputValid = false;
        errorMsg = "Please Select the State Name."
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  }

  const handleSubmit = () => {
    
    if(isInputValid()){
      var UpdateCityParams = {
        Id: values.Id,
        Name: values.CityName,
        StateId: values.StateId,
      };
      console.log("submit value", UpdateCityParams);
      dispatch(UpdateCity(UpdateCityParams)).then(r => dispatch(GetCityList()));
      navigate("/cityList");
    }
  }

  const StateArray = StateList.map((s, i) => {
    return ({
      // key: i,
      label: s.Name,
      value: s.Id
    })
  });


  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Edit City" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/cityList") }}>Cancel</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <div>
            <Card>
              <CardContent sx={{p: 5}}  style={{minHeight:200}}>
                <Grid container spacing={3}>                
                  
                  <Grid item xs={6} >
                    <TextField fullWidth label="City Name" value={values.CityName} name="CityName" onChange={handleChange} />
                  </Grid>

                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select State"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                          menuList:(base) => ({
                            ...base, maxHeight:150
                          }),
                        }}
                        value = {StateArray.filter(s => s.value === values.StateId)}
                        isLoading={isStateListFatching}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "StateId")}
                        options={StateArray}
                      />
                    </FormControl>
                  </Grid>
                  
                </Grid>
              </CardContent>
              
              <Divider />
              
              <div style={{textAlign: "center", padding: 16}}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                <Button variant='contained' color='secondary' onClick={handleSubmit} >Update</Button>
              </div>
            </Card>
          </div>
        </CardContent>       
      </Card>
    </div>
  );
}