import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useSelector } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Navbar from '../components/Navbar';
import SideMenu from '../components/SideMenu';
import DashboardPage from './DashboardPage';
import CategoriesPage from './CategoriesPage';
import ProductsPage from './ProductsPage';
import LoginPage from './LoginPage';
import OrdersPage from './OrdersPage';
import QuotationsPage from './QuotationsPage';
import VendorsPage from './VendorsPage';
import CustomersPage from './CustomersPage';
import InvoicePage from './InvoicePage';
import AddProductPage from './AddProductPage';
import EditProductPage from './EditProductPage';
import ViewProductPage from './ViewProductPage';
import BrandsPage from './BrandsPage';
import ProductTypesPage from './ProductTypesPage';
import ProductSizePage from './ProductSizePage';
import PickupLocationsPage from './PickupLocationsPage';
import AddCategoryPage from './AddCategoryPage';
import EditCategoryPage from './EditCategoryPage';
import AddBrandPage from './AddBrandPage';
import EditBrandPage from './EditBrandPage';
import AddProductTypePage from './AddProductTypePage';
import EditProductTypePage from './EditProductTypePage';
import AddPickupLocationPage from './AddPickupLocationPage';
import EditPickupLocationPage from './EditPickupLocationPage';
import ServiceLocationsPage from './ServiceLocationPage';
import AddServiceLocationPage from './AddServiceLocationPage';
import EditServiceLocationPage from './EditServiceLocationPage';
import ProductPricePage from './ProductPricePage';
import AddProductPricePage from './AddProductPricePage';
import EditProductPrice from './EditProductPricePage';
import EditQuotationPage from './EditQuotationPage';
import ViewQuotationPage from './ViewQuotationPage';
import ViewCustomerPage from './ViewCustomerPage';
import ViewVendorPage from './ViewVendorPage';
import AddQuotationPage from './AddQuotationPage';
import EditOrderPage from  './EditOrderPage';
import EditOrderItemPage from './EditOrderItemPage';
import EditSubOrderPage from './EditSubOrderPage';
import DeliveryChallanPage from './DeliveryChallanPage';
import NotificationPage from './NotificationPage';
import AddDeliveryChallanPage from './AddDeliveryChallan';
import AddInvoicePage from './AddInvoice';
import DeliveryChallanDetailPage from './DeliveryChallanDetailPage';
import InvoiceDetailPage from './InvoiceDetailPage';
import DeliveryRequestPage from './DeliveryRequestPage';
import EditDeliveryRequestPage from './EditDeliveryRequestPage';
import EditDeliveryRequestItemPage from './EditDeliveryRequestItemPage';
import PostalCodePage from './PostalCodePage';
import AddPostalCodePage from './AddPostalCodePage';
import EditPostalCodePage from './EditPostalCodePage';

import CityListPage from './CityListPage';
import AddCityPage from './AddCityPage';
import EditCityPage from './EditCityPage';

import StateListPage from './StateListPage';
import AddStatePage from './AddStatePage';
import EditStatePage from './EditState';

import OurClientsPage from './OurClientsPage';
import AddOurClientPage from './AddOurClientpage';
import EditOurClientPage from './EditOurClientPage';

import PopularBrandsPage from './PopularBrandsPage';
import AddPopularBrandPage from './AddPopularBrandsPage';
import EditPopularBrandPage from './EditPopularBrandsPage';

import PopularCategoriesPage from './PopularCategoriesPage';
import AddPopularCategoryPage from './AddPopularCategoriesPage';
import EditPopularCategoriesPage from './EditPopularCategoriesPage';

import RecommendedProductsPage from './RecommendedProductsPage';
import AddRecommendedProductPage from './AddRecommendedProductPage';
import EditRecommendedProductPage from './EditRecommendedProductPage';

export default function App(props) {
  const isAuthenticated = useSelector(state => (state.session.isAuthenticated || false));

  if(!isAuthenticated){
    return (<LoginPage />);
  }
  return (
    <div className="App">
      <Router>
        <Box sx={{display: 'flex', backgroundColor: "#fafafa"}}>
          <Navbar />
          <SideMenu />
          <Box component="main" sx={{flexGrow: 1, p: 3}}>
              <Toolbar />
              <div>
                <Routes>
                  <Route path="/" element={<DashboardPage />} />
                  {/* Drawer Menu */}
                  <Route path="/categories" element={<CategoriesPage />} />
                  <Route path="/products" element={<ProductsPage />} />
                  <Route path="/orders" element={<OrdersPage />} />
                  <Route path="/quotations" element={<QuotationsPage />} />
                  <Route path="/invoice" element={<InvoicePage />} />
                  <Route path="/vendors" element={<VendorsPage />} />
                  <Route path="/customers" element={<CustomersPage />} />
                  <Route path="/productTypes" element={<ProductTypesPage />} />
                  <Route path="/brands" element={<BrandsPage />} />
                  <Route path="/serviceLocations" element={<ServiceLocationsPage />} />
                  <Route path="/productsPrice" element={<ProductPricePage />} />
                  <Route path="/postalCodes" element={<PostalCodePage />} />

                  {/* <Route path="/productSize" element={<ProductSizePage />} /> */}
                  <Route path="/pickupLocations" element={<PickupLocationsPage />} />

                  {/* Other Screens */}
                  <Route path="/addCategory" element={<AddCategoryPage />} />
                  <Route path="/editCategory" element={<EditCategoryPage />} />
                  
                  <Route path="/addProduct" element={<AddProductPage />} />
                  <Route path="/viewProduct" element={<ViewProductPage />} />
                  <Route path="/editProduct" element={<EditProductPage />} />
                  
                  <Route path="/addBrand" element={<AddBrandPage />} />
                  <Route path="/editBrand" element={<EditBrandPage />} />

                  <Route path="/addProductType" element={<AddProductTypePage />} />
                  <Route path="/editProductType" element={<EditProductTypePage />} />

                  <Route path="/addServiceLocation" element={<AddServiceLocationPage/>} />
                  <Route path="/editServiceLocation" element={<EditServiceLocationPage/>} />
                  
                  {/* <Route path="/addProductSize" element={<AddProductSizePage />} />
                  <Route path="/editProductSize" element={<EditProductSizePage />} /> */}
                  
                  <Route path="/addPickupLocation" element={<AddPickupLocationPage />} />
                  <Route path="/editPickupLocation" element={<EditPickupLocationPage />} /> 

                  <Route path="/addProductPrice" element={<AddProductPricePage />} /> 
                  <Route path="/editProductPrice" element={<EditProductPrice />} />

                  <Route path="/addQuotation" element={<AddQuotationPage />} />
                  {/* <Route path="/editQuotation/:Id" element={<EditQuotationPage />} /> */}

                  <Route path="/editQuotation" element={<EditQuotationPage />} />
                  <Route path="/viewQuotation" element={<ViewQuotationPage />} />

                  <Route path="/viewCustomer" element={<ViewCustomerPage />} />

                  <Route path="/viewVendor" element={<ViewVendorPage />} />

                  <Route path="/editOrder" element={<EditOrderPage />} />
                  <Route path="/editOrderItem" element={<EditOrderItemPage />} />

                  <Route path="/editSubOrderItem" element={<EditSubOrderPage />} />

                  <Route path="/deliveryChallan" element={<DeliveryChallanPage />} />
                  <Route path="/notification" element={<NotificationPage />} />

                  <Route path="/addDeliveryChallan" element={<AddDeliveryChallanPage />} />
                  <Route path="/addInvoice" element={<AddInvoicePage />} />
                  <Route path="/deliveryChallanDetail" element={<DeliveryChallanDetailPage />} />
                  <Route path="/invoiceDetail" element={<InvoiceDetailPage />} />
                  <Route path="/deliveryRequest" element={<DeliveryRequestPage />} />
                  <Route path="/editDeliveryRequest" element={<EditDeliveryRequestPage />} />
                  <Route path="/editDeliveryRequestItem" element={<EditDeliveryRequestItemPage />} />
                  <Route path="/addPostalCode" element={<AddPostalCodePage />} />
                  <Route path="/editPostalCode" element={<EditPostalCodePage />} />
                  
                  <Route path="/cityList" element={<CityListPage />} />
                  <Route path="/addCity" element={<AddCityPage />} />
                  <Route path="/editCity" element={<EditCityPage />} />
                  
                  <Route path="/stateList" element={<StateListPage />} />
                  <Route path="/addState" element={<AddStatePage />} />
                  <Route path="/editState" element={<EditStatePage />} />

                  <Route path="/ourClients" element={<OurClientsPage />} />
                  <Route path="/addOurClient" element={<AddOurClientPage />} />
                  <Route path="/editOurClient" element={<EditOurClientPage />} />

                  <Route path="/popularBrands" element={<PopularBrandsPage />} />
                  <Route path="/addPopularBrand" element={<AddPopularBrandPage />} />
                  <Route path="/editPopularBrand" element={<EditPopularBrandPage />} />

                  <Route path="/popularCategories" element={<PopularCategoriesPage />} />
                  <Route path="/addPopularCategory" element={<AddPopularCategoryPage />} />
                  <Route path="/editPopularCategory" element={<EditPopularCategoriesPage />} />

                  <Route path="/recommendedProducts" element={<RecommendedProductsPage />} />
                  <Route path="/addRecommendedProduct" element={<AddRecommendedProductPage />} />
                  <Route path="/editRecommendedProduct" element={<EditRecommendedProductPage />} />


                </Routes>
              </div>
          </Box>
        </Box>
      </Router>
    </div>
  );
}