import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { fetchCustomers, deleteCustomer } from '../store/actions/customer'; 
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';


export default function BrandsPage(props) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteCustomerId, setdeleteCustomerId] = useState("");
  const customers = useSelector(state => (state.customer.customers || []));
  const isCustomerFetching = useSelector(state => (state.customer.isCustomerFetching ));
  
  const mount = () => {
    dispatch(fetchCustomers());

  }
  useEffect(mount, []);

  // const handleEditClick = (id) => {
  //   var fltr_customer = Object.assign({}, ...customers.filter(c => { if(c.Id === id) return c; }));
  //   navigate("/editBrand", { state: { customer: fltr_customer }} );
  // };

  const handleDeleteClick = (id) => {
    dispatch(deleteCustomer(id)).then(r => dispatch(fetchCustomers()));
    setdialog(false);
  };

  const handleCellClick = (params) => {
    if(params.field !== "actions"){
      navigate("/viewCustomer", { state: { customer: params.id }} );
    }
  };

  const columns = [
    { field: 'id', headerName: 'S.No', align: "left", flex:0.5 },
    { field: 'image', headerName: 'Image', align: "left", flex:0.7, 
     renderCell: (params)=>{
      return (
        <div>
          <img src={params.row.image} alt='CustomerImg' width="42" height="42" style={{ borderRadius: "50%", objectFit:"contain"}} />
        </div>
      )}
    },
    { field: 'fullname', headerName: 'Full Name', align: "left", flex:1.1 },
    { field: 'email', headerName: 'Email', align: "left", flex:1.3 },
    { field: 'mobile', headerName: 'Mobile', align: "left", flex:0.7 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              // onClick={() => handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  setdeleteCustomerId(id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"0.2" }} onClose={() => setdialog(false)} > 
              <DialogTitle >
                {"Delete Customer"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  Are you sure you wish to delete this Customer ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteCustomerId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>,
        ];
      }
    }
  ];

  const rows = customers.map(c => {
    return ({ 
      id: c.RowId,
      image: c.ProfilePhoto, 
      fullname: c.FullName,
      email: c.Email,
      mobile: c.Mobile
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Customers" />
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            // onClick={() => { navigate("/addBrand") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Customer</Typography>
          </Button>
        </div>
        
        <Divider />
        
        {customers && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={ isCustomerFetching ? true : false }
              autoHeight={18}
              pageSize={pageSize}
              onCellClick={handleCellClick}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}
        
      </Card>
    </div>
  );
}