import { CALL_API } from '../api';

export const FETCHPICKUPADDRESS_REQUEST = "FETCHPICKUPADDRESS_REQUEST";
export const FETCHPICKUPADDRESS_SUCCESS = "FETCHPICKUPADDRESS_SUCCESS";
export const FETCHPICKUPADDRESS_FAILURE = "FETCHPICKUPADDRESS_FAILURE";

export const SERVICELOC_REQUEST = "SERVICELOC_REQUEST";
export const SERVICELOC_SUCCESS = "SERVICELOC_SUCCESS";
export const SERVICELOC_FAILURE = "SERVICELOC_FAILURE";

export const ADDPLOC_REQUEST = "ADDPLOC_REQUEST";
export const ADDPLOC_SUCCESS = "ADDPLOC_SUCCESS";
export const ADDPLOC_FAILURE = "ADDPLOC_FAILURE";

export const UPLOC_REQUEST = "UPLOC_REQUEST";
export const UPLOC_SUCCESS = "UPLOC_SUCCESS";
export const UPLOC_FAILURE = "UPLOC_FAILURE";

export const DELPLOC_REQUEST = "DELPLOC_REQUEST";
export const DELPLOC_SUCCESS = "DELPLOC_SUCCESS";
export const DELPLOC_FAILURE = "DELPLOC_FAILURE";

export const ADDSERLOCATION_REQUEST = "ADDSERLOCATION_REQUEST"; 
export const ADDSERLOCATION_SUCCESS = "ADDSERLOCATION_SUCCESS";
export const ADDSERLOCATION_FAILURE = "ADDSERLOCATION_FAILURE";

export const UPDTSERLOCATION_REQUEST = "UPDTSERLOCATION_REQUEST";
export const UPDTSERLOCATION_SUCCESS = "UPDTSERLOCATION_SUCCESS";
export const UPDTSERLOCATION_FAILURE = "UPDTSERLOCATION_FAILURE";

export const DELSERLOCATION_REQUEST = "DELSERLOCATION_REQUEST"; 
export const DELSERLOCATION_SUCCESS = "DELSERLOCATION_SUCCESS";
export const DELSERLOCATION_FAILURE = "DELSERLOCATION_FAILURE";

/* Get City list */
export const CITY_LIST_REQUEST = "CITY_LIST_REQUEST"; 
export const CITY_LIST_SUCCESS = "CITY_LIST_SUCCESS";
export const CITY_LIST_FAILURE = "CITY_LIST_FAILURE";

/* Add City */
export const ADD_CITY_REQUEST = "ADD_CITY_REQUEST"; 
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_FAILURE = "ADD_CITY_FAILURE";

/* Update City */
export const UPDATE_CITY_REQUEST = "UPDATE_CITY_REQUEST"; 
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAILURE = "UPDATE_CITY_FAILURE";

/* Delete City */
export const DELETE_CITY_REQUEST = "DELETE_CITY_REQUEST"; 
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_FAILURE = "DELETE_CITY_FAILURE";

/* Get State list */
export const STATE_LIST_REQUEST = "STATE_LIST_REQUEST"; 
export const STATE_LIST_SUCCESS = "STATE_LIST_SUCCESS";
export const STATE_LIST_FAILURE = "STATE_LIST_FAILURE";

/* Add State */
export const ADD_STATE_REQUEST = "ADD_STATE_REQUEST"; 
export const ADD_STATE_SUCCESS = "ADD_STATE_SUCCESS";
export const ADD_STATE_FAILURE = "ADD_STATE_FAILURE";

/* Update State */
export const UPDATE_STATE_REQUEST = "UPDATE_STATE_REQUEST"; 
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS";
export const UPDATE_STATE_FAILURE = "UPDATE_STATE_FAILURE";

/* Delete State */
export const DELETE_STATE_REQUEST = "DELETE_STATE_REQUEST"; 
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS";
export const DELETE_STATE_FAILURE = "DELETE_STATE_FAILURE";

/* Postal Code list */
export const POSTCODE_LIST_REQUEST = "POSTCODE_LIST_REQUEST";
export const POSTCODE_LIST_SUCCESS = "POSTCODE_LIST_SUCCESS";
export const POSTCODE_LIST_FAILURE = "POSTCODE_LIST_FAILURE";

/* Add Postal Code */
export const ADD_POSTCODE_REQUEST = "ADDPOSTCODE_REQUEST";
export const ADD_POSTCODE_SUCCESS = "ADDPOSTCODE_SUCCESS";
export const ADD_POSTCODE_FAILURE = "ADDPOSTCODE_FAILURE";

/* Update Postal Code */
export const UPDATE_POSTCODE_REQUEST = "UPDATE_POSTCODE_REQUEST";
export const UPDATE_POSTCODE_SUCCESS = "UPDATE_POSTCODE_SUCCESS";
export const UPDATE_POSTCODE_FAILURE = "UPDATE_POSTCODE_FAILURE";

/* Delete Postal Code */
export const DELETE_POSTCODE_REQUEST = "DELETE_POSTCODE_REQUEST";
export const DELETE_POSTCODE_SUCCESS = "DELETE_POSTCODE_SUCCESS";
export const DELETE_POSTCODE_FAILURE = "DELETE_POSTCODE_FAILURE";

export const SELECTED_ADDRESSID_SUCCESS = "SELECTED_ADDRESSID_SUCCESS";

export const fetchPickupAddress = () => ({
    [CALL_API]: {
        types: [FETCHPICKUPADDRESS_REQUEST, FETCHPICKUPADDRESS_SUCCESS, FETCHPICKUPADDRESS_FAILURE],
        method: 'GET',
        endpoint: 'base/getpickuplist'
    }
});

export const addPickupAddress = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADDPLOC_REQUEST, ADDPLOC_SUCCESS, ADDPLOC_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'addpickuplocation'
    }
  });
};

export const updatePickupAddress = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPLOC_REQUEST, UPLOC_SUCCESS, UPLOC_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'updatepickuplocation'
    }
  });
};

export const deletePickupAddress = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELPLOC_REQUEST, DELPLOC_SUCCESS, DELPLOC_FAILURE],
        method: 'GET',
        endpoint: 'deletepickuplocation/' + id
    }
  });
};

export const fetchServiceLocations = () => ({
  [CALL_API]: {
      types: [SERVICELOC_REQUEST, SERVICELOC_SUCCESS, SERVICELOC_FAILURE],
      method: 'GET',
      endpoint: 'base/getservicelocationlist'
  }
});

export const addServiceLocation = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADDSERLOCATION_REQUEST, ADDSERLOCATION_SUCCESS, ADDSERLOCATION_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'addservicelocation'
    }
  });
};
  
export const updateServiceLocation = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPDTSERLOCATION_REQUEST, UPDTSERLOCATION_SUCCESS, UPDTSERLOCATION_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'updateservicelocation'
    }
  });
};

export const deleteServiceLocation = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELSERLOCATION_REQUEST, DELSERLOCATION_SUCCESS, DELSERLOCATION_FAILURE],
        method: 'GET',
        endpoint: 'deleteservicelocation/' + id
    }
  });
};

export const selectedSerLocId = (selected_Add_Id) => (dispatch, getState) => {
  return dispatch({
    type: SELECTED_ADDRESSID_SUCCESS,
    SelectedAddress_Id : selected_Add_Id
  });
};

/* Get City List */
export const GetCityList = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [CITY_LIST_REQUEST, CITY_LIST_SUCCESS, CITY_LIST_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'postcode/getcity'
    }
  });
};

/* Add City */
export const AddCity = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADD_CITY_REQUEST, ADD_CITY_SUCCESS, ADD_CITY_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'postcode/addcity'
    }
  });
};

/* Update City */
export const UpdateCity = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPDATE_CITY_REQUEST, UPDATE_CITY_SUCCESS, UPDATE_CITY_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'postcode/updatecity'
    }
  });
};

/* Delete City */
export const DeleteCity = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_CITY_REQUEST, DELETE_CITY_SUCCESS, DELETE_CITY_FAILURE],
        method: 'GET',
        endpoint: 'postcode/deletecity/' + id
    }
  });
};

/* Get State List */
export const GetStateList = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [STATE_LIST_REQUEST, STATE_LIST_SUCCESS, STATE_LIST_FAILURE],
        method: 'GET',
        endpoint: 'postcode/getstate'
    }
  });
};

/* Add State */
export const AddState = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADD_STATE_REQUEST, ADD_STATE_SUCCESS, ADD_STATE_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'postcode/addstate'
    }
  });
};

/* Update State */
export const UpdateState = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPDATE_STATE_REQUEST, UPDATE_STATE_SUCCESS, UPDATE_STATE_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'postcode/updatestate'
    }
  });
};

/* Delete State */
export const DeleteState = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_STATE_REQUEST, DELETE_STATE_SUCCESS, DELETE_STATE_FAILURE],
        method: 'GET',
        endpoint: 'postcode/deletestate/' + id
    }
  });
};

/* Postal Code List */
export const fetchPostalCode = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [POSTCODE_LIST_REQUEST, POSTCODE_LIST_SUCCESS, POSTCODE_LIST_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'postcode/getpostcodelist'
    }
  });
};

/* Add Postal Code */
export const addPostalCode = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADD_POSTCODE_REQUEST,ADD_POSTCODE_SUCCESS,ADD_POSTCODE_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'postcode/addpostcode'
    }
  });
};

/* Update Postal Code */
export const updatePostalCode = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPDATE_POSTCODE_REQUEST,UPDATE_POSTCODE_SUCCESS,UPDATE_POSTCODE_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'postcode/updatepostcode'
    }
  });
};

/* Delete Postal Code */
export const deletePostalCode = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELETE_POSTCODE_REQUEST, DELETE_POSTCODE_SUCCESS, DELETE_POSTCODE_FAILURE],
        method: 'GET',
        endpoint: 'postcode/deletepostcode/' + id
    }
  });
};
