import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import { useNavigate } from 'react-router-dom';

import { fetchCategories, fetchProductTypes, addProductType } from '../store/actions/services';
import { fetchServiceLocations } from '../store/actions/addresses';


export default function AddProductTypePage(props) {
  const [values, setValues] = useState({
    PrdtTypeimg: '',
    Name: '', 
    CatId: '',
    ParentPrdtTypeId: '',
    ServiceLocId: [],
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const categories = useSelector(state => (state.services.categories || []));
  const serviceLocations = useSelector(state => (state.addresses.serviceLocations || []));
  const productTypes = useSelector(state => (state.services.productTypes || []));
  const isPRDTTYPEAdding = useSelector(state => (state.services.isPRDTTYPEAdding));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //This is to set maxHeight for Select(dropdown) element's Menu
  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };

  const mount = () => {
    dispatch(fetchCategories());
    dispatch(fetchServiceLocations());
    dispatch(fetchProductTypes());
  };
  // eslint-disable-next-line
  useEffect(mount, []);

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const hiddenFileInput = React.useRef(null);

  const handleUploadImgBtn = () => {
    //method to Link FileInput element
    hiddenFileInput.current.click();
  };

  const handleAddImg = (event) =>{
    
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setValues({ ...values, PrdtTypeimg: e.target.result });
    }  
  };

  const handleSelectChange = (fieldname) => (event) => {
    if(fieldname === "CatId"){
      setValues({ ...values, CatId: event.target.value });
    }
    if(fieldname === "ServiceLocId"){
      setValues({ ...values, ServiceLocId: event.target.value });
    }
    if(fieldname === "ParentPrdtTypeId"){
      setValues({ ...values, ParentPrdtTypeId: event.target.value });
    }
  };

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";

    if(values.PrdtTypeimg === "" || values.Name === "" /*|| values.ServiceLocId.length == 0*/ || values.CatId === "" ){
      isInputValid = false;
      errorMsg = "Please fill all the * marked fields.";
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  };

  const handleSubmit = () => {
    if(isInputValid()){
      var prdtType = {
        ImageObj: values.PrdtTypeimg,
        Name: values.Name,
        CategoryId: values.CatId,
        ParentProductTypeId: values.ParentPrdtTypeId,
        ServiceLocationId: values.ServiceLocId,
        Image: ""
      };

      //console.log("prdtType");
      //console.log(prdtType);
      dispatch(addProductType(prdtType)).then(r => dispatch(fetchProductTypes()));
      navigate('/productTypes');
      
    }
  }

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Add Product Type" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/productTypes") }}>Cancel</Button>
          </div>
        </div>
        
        <Divider />
        
        <CardContent sx={{p: 5}}>
          <Grid container spacing={3}>
            <Grid container xs={12} mb={6} alignItems="center" justifyContent="center" flexDirection="column" >
              <Typography variant="h5" >Product Type Image*</Typography>
              <Typography variant="subtitle1" >Image should be (512 x 512px)</Typography>
              
              {(values.PrdtTypeimg) && (
                <div style={{ position:"relative", marginTop: "12px"}}>
                  <IconButton
                    style={{ position:"absolute", right: 0 }}
                    sx={{ backgroundColor:"white" }}
                    onClick={() => setValues({ ...values, PrdtTypeimg:'' })}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                  <img src={values.PrdtTypeimg} width={175} height={175} />
                </div>
              )}

              {/* This is masked Button for hiddenFileInput */}
              {(!values.PrdtTypeimg) && (
                <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn()}>
                  Upload Image
                </Button>
              )}
              <input ref={hiddenFileInput} style={{ display:'none' }} className="choosefile-input" type="file" onChange={handleAddImg} />
            </Grid>

            <Grid item xs={6} >
              <TextField required fullWidth label="Name" value={values.Name} name="Name" onChange={handleChange} />
            </Grid>
            
            <Grid item xs={6} >
              <FormControl required sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={values.CatId}
                  label="Category"
                  onChange={handleSelectChange("CatId")}
                  MenuProps={MenuProps}
                >
                  {categories.map((c, i) => {
                    return <MenuItem  value={c.Id} key={i}>{c.Name}</MenuItem >
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} >
              <FormControl required sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">Service Locations</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={values.ServiceLocId}
                  label="Service Locations"
                  defaultValue=""
                  onChange={handleSelectChange("ServiceLocId")}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        var fltr_sl = Object.assign({}, ...serviceLocations.filter(sl => {if(value == sl.Id) return sl;}));
                        var Area = (fltr_sl.Area) ? fltr_sl.Area+", " : "";
                        var City = (fltr_sl.City) ? fltr_sl.City+", " : "";
                        var State = (fltr_sl.State) ? fltr_sl.State : "";
                        var listitem = Area + City + State;    
                        return (<Chip key={value} label={listitem} />);
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {serviceLocations.map((sl, i) => {
                    var Area = (sl.Area) ? sl.Area+", " : "";
                    var City = (sl.City) ? sl.City+", " : "";
                    var State = (sl.State) ? sl.State : "";
                    var listitem = Area + City + State;
                    return (
                      <MenuItem value={sl.Id} key={i}>
                        <Checkbox checked={values.ServiceLocId.indexOf(sl.Id) > -1 } />
                        <ListItemText primary={listitem} />
                      </MenuItem > 
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} >
            <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">Parent Product Type</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={values.ParentPrdtTypeId}
                  label="Parent Product Type"
                  onChange={handleSelectChange("ParentPrdtTypeId")}
                  MenuProps={MenuProps}
                >
                  {productTypes.map((pt, i) => {
                    return <MenuItem  value={pt.Id} key={i}>{pt.Name}</MenuItem >
                  })}
                </Select>
              </FormControl>
            </Grid>
            
          </Grid>

        </CardContent>

        <Divider />

        <div style={{textAlign: "center", padding: 16}}>
          {(errorMsg && errorMsg !== "") && (
            <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
          )}
          {(isPRDTTYPEAdding) ?
            <Button variant='contained' color='secondary' >Adding...</Button> :
            <Button variant='contained' color='secondary' onClick={handleSubmit}>Add</Button>
          }
        </div>

      </Card>
    </div>
  );
}