import React ,{ useState, useEffect, useLayoutEffect  } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useSelector, useDispatch } from 'react-redux';
import { GetStateList, AddState } from '../store/actions/addresses';
import { useLocation, useNavigate } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddStatePage(props) {
  
  const [values, setValues] = useState({
    StateName: '',

  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };


  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";

    if( values.StateName === "" ){
      isInputValid = false;
      errorMsg = "Please Enter State Name.";
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  }

  const handleSubmit = () => {
    
    if(isInputValid()){
      var AddStateParams = {
        Name: values.StateName,
      };

      console.log("state submit values", AddStateParams);
  
      dispatch(AddState(AddStateParams)).then(r => dispatch(GetStateList()));
      navigate("/stateList");
    }
  }

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Add State" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/stateList") }}>Cancel</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <div>
            <Card>
              <CardContent sx={{p: 5}}>
                <Grid container spacing={3}>

                <Grid item xs={3} >
                  </Grid>
                                    
                  <Grid item xs={6} >
                    <TextField fullWidth label="State Name" value={values.StateName} name="StateName" onChange={handleChange} />
                  </Grid>

                  <Grid item xs={3} >
                  </Grid>

                </Grid>
              </CardContent>
              
              <Divider />
              
              <div style={{textAlign: "center", padding: 16}}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                <Button variant='contained' color='secondary' onClick={handleSubmit} >Add</Button>
              </div>
            </Card>
          </div>
        </CardContent>       
      </Card>
    </div>
  );
}