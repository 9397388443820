//import { CALL_API } from '../store/api';
import Cookies from 'universal-cookie';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const refreshAuthToken = id => (dispatch, getState) => {
    const cookies = new Cookies();
    const authToken = cookies.get('authDetails');

    if (!authToken) return dispatch(logout());

    return dispatch({
        type: AUTH_LOGIN_SUCCESS
    });
};

export const login = (body) => (dispatch, getState) => {

    if(process.env.REACT_APP_UserName && process.env.REACT_APP_UserName !== "" && 
        process.env.REACT_APP_Password && process.env.REACT_APP_Password !== "") {
        if (body.Username === process.env.REACT_APP_UserName && 
            body.Password === process.env.REACT_APP_Password) {
            return dispatch({
                type: AUTH_LOGIN_SUCCESS
            });
        }
    }
    else if (body.Username === "admin" && body.Password === "Pass12345") {
        return dispatch({
            type: AUTH_LOGIN_SUCCESS
        });
    }    

    return dispatch({
        type: AUTH_FAILURE,
        error: "Invalid Username or Password"
    });
};

export const logout = () => (dispatch, getState) => {
    return dispatch({
        type: AUTH_LOGOUT
    });
};
