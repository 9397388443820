import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { format, parseISO } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFormatedCurrency } from '../components/util';
import { fetchDChallanDetail, DownloadDChallan } from '../store/actions/deliveryChallan';



export default function DeliveryChallanDetailPage(props) {

  // const isDChallanDetailFetching = useSelector(state => (state.deliveryChallan.isDChallanDetailFetching || []));
  const dChallanDetail = useSelector(state => (state.deliveryChallan.dChallanDetail || []));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const customer = dChallanDetail && dChallanDetail.CustomerDetails ? dChallanDetail.CustomerDetails : {}; 

  const mount = () => {
    dispatch(fetchDChallanDetail(location.state.deliveryChallanId));
  }
  // eslint-disable-next-line
  useLayoutEffect(mount, []);
  
  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Delivery Challan Detail" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/deliveryChallan") }}>Back</Button>
          </div>
        </div>
        <Divider />
  
        <CardContent>
         
        <Grid container style={{marginBottom:20, display:"flex", justifyContent:"space-around"}}>
          <Grid item xs={5.5} spacing={4}>
            <div style={{ fontSize: 22, fontWeight:"bold", color:"#F7D200"}}>
              <CardHeader title="Customer Detail" />
            </div>
            <Grid item xs={12} spacing={4}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Name: </Typography>
                <Typography variant="subtitle1" ml={2}>{customer.FirstName +" "+ customer.LastName}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Email: </Typography>
                <Typography variant="subtitle1" ml={2}>{customer.Email}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Customer Mobile: </Typography>
                <Typography variant="subtitle1" ml={2}>{customer.Mobile}</Typography>
              </div>
            </Grid>
          </Grid>

          <Divider orientation="vertical" flexItem />

          <Grid item xs={5.5} spacing={4}>              
            <div style={{ fontSize: 22, fontWeight:"bold", color:"#F7D200"}}>
              <CardHeader title="Delivery Challan Detail" />
            </div>
            <Grid item xs={12} spacing={4}>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Delivery Challan No.: </Typography>
                <Typography variant="subtitle1" ml={2}>{dChallanDetail.DeliveryChallanNo}</Typography>
              </div>                   
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Delivery Challan Date and Time: </Typography>
                <Typography variant="subtitle1" ml={2}>{(dChallanDetail.DeliveryChallanDate) ? format(parseISO(dChallanDetail.DeliveryChallanDate), "dd MMM yyyy 'at' hh:mm a") : null}</Typography>
              </div> 
                <div className="div-infocontainer">
                <Typography variant="subtitle2">Status: </Typography>
                <Typography variant="subtitle1" ml={2}>{dChallanDetail.Status}</Typography>
              </div>
              <div className="div-infocontainer">
                <Typography variant="subtitle2">Total: </Typography>
                <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(dChallanDetail.TotalAmount)}</Typography>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} spacing={4} style={{ paddingInline:15, marginTop:20}}>
            <div className="div-infocontainer">
              <Typography variant="subtitle2">Billing Address: </Typography>
              <Typography variant="subtitle1" ml={2}>{dChallanDetail.BillingAddress}</Typography>
            </div>
            <div className="div-infocontainer">
              <Typography variant="subtitle2">Delivery Address: </Typography>
              <Typography variant="subtitle1" ml={2}>{dChallanDetail.DeliveryAddress}</Typography>
            </div>
          </Grid>
        </Grid>

          {(dChallanDetail && dChallanDetail.DeliveryChallanItems && dChallanDetail.DeliveryChallanItems.length > 0) ?
            <div>
              <Divider />

              <div style={{ fontSize: 22, fontWeight:"bold", color:"#F7D200", paddingLeft:0}}>
                <CardHeader title="Delivery Challan Items" />
              </div>
            </div>
           : null}

          <Grid container>
            {((dChallanDetail && dChallanDetail.DeliveryChallanItems) || []) .map((d, i) => {
              return  (                   
                <div key={i} style={{display:"flex", alignItems:"center", justifyContent:"space-between", paddingTop:11, paddingBottom:11, borderRadius:5 ,backgroundColor:"#D9D9D9", width:"100%", paddingInline:15, borderWidth:1, borderColor:"Gray", borderStyle:'solid', marginTop:10}} >
                  <Grid item xs={4} style={{fontSize: 16, fontWeight:"bold", paddingTop:5, paddingBottom: 5}}>
                      <Typography variant="subtitle">{d.Product.ProductName + " - " + d.Product.BrandName + " (" + d.Product.ProductSize + ")"} </Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={2.7} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Item Price: {getFormatedCurrency(d.OrderItemPrice)}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={2.7} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Item Quantity: {d.OrderQuantity}</Typography>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={2.7} style={{textAlign:"center"}}>
                      <Typography variant="subtitle2">Total Amount: {getFormatedCurrency(d.ItemTotal)}</Typography>
                    </Grid>
                </div>
              )
              })}
          </Grid>
        </CardContent>
        <Divider />
        {/* <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"center"}}>
          <Button variant='contained' color='secondary' onClick={downloadDChallan} >Download Delivery Challan</Button>
        </div> */}
        <div style={{ display:"flex", padding: 16, flexDirection:"row", justifyContent:"center"}}>
          <a href={'https://ecsappapi.greenstoneent.com/api/ecsapp/deliverychallan/generatedeliverychallan/' + dChallanDetail.Id}>
            <Button variant='contained' color='secondary'>Download Delivery Challan</Button>
          </a>
        </div>
      </Card>
    </div>
  );
}







