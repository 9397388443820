/*// import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
// import CardContent from '@mui/material/CardContent';
// import { fetchProducts } from '../store/actions/product';



// export default function OrdersPage(props) {

//   const dispatch = useDispatch();
//   //const products = useSelector(state => (state.product.products || []));

//   const mount = () => {
//     //dispatch(fetchProducts({}));
//   }
//   // eslint-disable-next-line
//   useEffect(mount, []);

  
//   return (    
//     <div>
//       <Card>
//         <CardHeader title="Orders" />
//         <Divider />
//         <CardContent>
//           <Typography variant="body2">
//             Page Content
//           </Typography>
//         </CardContent>        
//       </Card>
//     </div>
//   );
// }

  */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';
import { fetchAllOrders, deleteOrder } from '../store/actions/quotation'; 


export default function OrdersPage(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteOrderId, setdeleteOrderId] = useState("");
  const orders = useSelector(state => ( state.quotation.allOrders || []));
  const isAllOrderFetching = useSelector(state => (state.quotation.isAllOrderFetching ));

  // console.log("all orders");
  // console.log(orders);

  const mount = () => {
    dispatch(fetchAllOrders());
  }
  useEffect(mount, []);

  const handleEditClick = (id) => {
    var fltr_Order = Object.assign({}, ...orders.filter(q => { if(q.OrderId === id) return q; }));
    console.log("clicked..............");
    console.log(fltr_Order);
    navigate("/editOrder", { state: { orders: fltr_Order }} );
  };

  const handleDeleteClick = (id) => {
    dispatch(deleteOrder(id)).then(r => dispatch(fetchAllOrders()));
    setdialog(false);
  };

  /* const handleCellClick = (params) => {
    if(params.field !== "actions"){
      navigate("/viewQuotation", { state: { quotation: params.id }} );
    }
  };
 */
  
  const columns = [
    { field: 'id', headerName: 'Id', align: "left", flex: 0.3 },
    { field: 'ponumber', headerName: 'PONumber', align: "left", flex: 0.5 },
    { field: 'customername', headerName: 'Customer Name', align: "left", flex: 0.5 },
    { field: 'status', headerName: 'Status', align: "left", flex: 0.5 },
    { field: 'orderDate', headerName: 'Order Date', align: "left", flex: 1 },
    { field: 'totalItems', headerName: 'Total Items', align: "left", flex: 0.5 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  setdeleteOrderId(id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"0.2" }} onClose={() => setdialog(false)} >
              <DialogTitle >
                {"Delete Order"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you wish to delete this Order ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteOrderId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>No</Button>
              </DialogActions>
            </Dialog>
          </div>,
        ];
      }
    }
  ];

  const rows = orders.map(o => {
    return ({ 
      id: o.OrderId,
      ponumber: o.PONumber,
      customername: (o.CustomerFirstName + ", " + o.CustomerLastName),
      status : o.Status,
      orderDate : format(parseISO(o.OrderDate), "dd MMM yyyy 'at' hh:mm a"),
      // totalItems : getFormatedCurrency(q.TotalItems)
      totalItems : o.TotalItems
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Orders" />
          {/* <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addQuotation") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Order</Typography>
          </Button> */}
        </div>
        
        <Divider />
        
        {orders && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={isAllOrderFetching ? true : false}
              autoHeight={18}
              pageSize={pageSize}
              // onCellClick={handleCellClick}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}        
      
      </Card>
    </div>
  );
}