import React, {useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useSelector, useDispatch } from 'react-redux';
import '../css/viewproductpage.css';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { getFormatedCurrency } from "../components/util";

import { useNavigate, useLocation} from 'react-router-dom';

import { fetchProductDetail } from '../store/actions/product';


export default function ViewProductPage(props) {

  const [values, setValues] = useState({
    sltd_Loc: 0
  });

  const dispatch = useDispatch();
  const currentProduct = useSelector(state => (state.product.currentProduct || {}));
  const location = useLocation();
  var smallimgs = [];
  var bannerimgs = [];

  console.log("current product in view page");
  console.log(currentProduct);

  const navigate = useNavigate();

  //console.log("passed product id")
  //console.log(location.state.productId)

  const mount = () => {
    // dispatch(fetchProductDetail(location.state.productId));
    dispatch(fetchProductDetail({Id: location.state.productId, ServiceLocationId: values.sltd_Loc}))

  }
  // eslint-disable-next-line
  useEffect(mount, []);

  //console.log("product Detail");
  //console.log(currentProduct.ImageURL);
  if(currentProduct && currentProduct.ImageURL && currentProduct.ImageURL.SmallImage && currentProduct.ImageURL.SmallImage.length){
    smallimgs = currentProduct.ImageURL.SmallImage;
  }
  if(currentProduct && currentProduct.ImageURL && currentProduct.ImageURL.BannerImage && currentProduct.ImageURL.BannerImage.length){
    bannerimgs = currentProduct.ImageURL.BannerImage;
  }

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="View Product Page" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="primary" onClick={() => { navigate("/products") }}>Back</Button>
          </div>
        </div>
        
        <Divider />
        
        <CardContent>
          {currentProduct && currentProduct.ImageURL && (              
              <Card>
                <CardContent sx={{p: 4}}>
                  <Grid container spacing={2} >
                    
                    <Grid container xs={6} alignItems="center" justifyContent="center" flexDirection="column" >
                      <ImageList sx={{ width: 500 }} cols={2} rowHeight={164}>
                        {(bannerimgs && bannerimgs.length) ? 
                          bannerimgs.map((b_img, i) => (
                            <ImageListItem key={i}>
                              <img src={b_img.ImageURL}
                                alt={"Product"}
                                width={300} 
                                height={225}
                                style={{ objectFit: "contain"}}
                                loading="lazy"
                              />
                            </ImageListItem>
                            )) 
                          : <img alt={'Product'} width={135} height={175}  /> 
                        }
                      </ImageList>
                      <Typography variant="body1" >Banner Image</Typography>
                    </Grid>
                    
                    <Grid container xs={6} alignItems="center" justifyContent="center" flexDirection="column" >
                      <img src={smallimgs[0]} alt={"Prodcutimg"} width="170px" height="170px" />
                      <Typography variant="body1" >Small Image</Typography>
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
            )}
        </CardContent>

        {currentProduct && (
          <Card >
            <CardContent>
              <Grid container >
                
                <Grid item xs={6} spacing={4}>
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">Name: </Typography>
                    <Typography variant="subtitle1" ml={2}>{currentProduct.Name}</Typography>
                  </div>
                  
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">Brand Name: </Typography>
                    <Typography variant="subtitle1" ml={2}>{currentProduct.Brand && currentProduct.Brand.Name}</Typography>
                  </div>
                  
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">ProductType Name: </Typography>
                    <Typography variant="subtitle1" ml={2}>{currentProduct.ProductType && currentProduct.ProductType.Name}</Typography>
                  </div>

                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">MinimumOrderQuantity: </Typography>
                    <Typography variant="subtitle1" ml={2}>{currentProduct.MinOrderQuantity}</Typography>
                  </div>
                </Grid>

                <Grid item xs={6} spacing={4}>
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">Original Price: </Typography>
                    <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(currentProduct.OriginalPrice)}</Typography>
                  </div>
                  
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">Listing Price: </Typography>
                    <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(currentProduct.ListPrice)}</Typography>
                  </div>
                  
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">Size: </Typography>
                    <Typography variant="subtitle1" ml={2}>{currentProduct.Size}</Typography>
                  </div>

                  <div className="div-infocontainer">
                    <Typography variant="subtitle2">MinimumBulkOrderQuantity: </Typography>
                    <Typography variant="subtitle1" ml={2}>{currentProduct.MinBulkOrderQuantity}</Typography>
                  </div>
                </Grid>
              </Grid>
              
              <Grid item xs={12} spacing={4}>
                {currentProduct.Description && currentProduct.Description !== "" && (
                  <div className="div-infocontainer" >
                    <Typography variant="subtitle2" alignSelf="flex-start">Description: </Typography>
                    <Typography variant="subtitle1" ml={2} dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + currentProduct.Description + "</div>"}} />
                  </div>
                )}
                
                {currentProduct.DeliveryInstruction && currentProduct.DeliveryInstruction !== "" && (
                  <div className="div-infocontainer" style={{ marginTop: 22}}>
                    <Typography variant="subtitle2" alignSelf="flex-start" style={{ width: "22%" }} >Delivery Instruction: </Typography>
                    <Typography variant="subtitle1" ml={2} dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + currentProduct.DeliveryInstruction + "</div>"}} />
                  </div>
                )}
                
                {currentProduct.Information && currentProduct.Information !== "" && (
                  <div className="div-infocontainer" >
                    <Typography variant="subtitle2">Information: </Typography>
                    <Typography variant="subtitle1" ml={2} /* dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + currentProduct.Information + "</div>"}} */ >{currentProduct.Information}</Typography>
                  </div>
                )}
                
                {currentProduct.Features && currentProduct.Features !== "" && (
                  <div className="div-infocontainer" >
                    <Typography variant="subtitle2">Features: </Typography>
                    <Typography variant="subtitle1" ml={2} /* dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + currentProduct.Features + "</div>"}} */ >{currentProduct.Features}</Typography>
                  </div>
                )}
                
                {currentProduct.Disclaimer && currentProduct.Disclaimer !== "" && (
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2" >Disclaimer: </Typography>
                    <Typography variant="subtitle1" ml={2} /* dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + currentProduct.Disclaimer + "</div>"}} */ >{currentProduct.Disclaimer}</Typography>
                  </div>
                )}
                
                {currentProduct.OfferText && currentProduct.OfferText !== "" && (
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2" >Offer Text: </Typography>
                    <Typography variant="subtitle1" ml={2}>{currentProduct.OfferText}</Typography>
                  </div>
                )}
                
                {/* {currentProduct.TaxAmount && ( */}
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2" >Tax Amount: </Typography>
                    <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(currentProduct.TaxAmount)}</Typography>
                  </div>
                {/* )} */}
                
                {/* {currentProduct.PackingCharge && ( */}
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2" >Packing Charge: </Typography>
                    <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(currentProduct.PackingCharge)}</Typography>
                  </div>
                {/* )} */}
                
                {/* {currentProduct.DeliveryCharge && ( */}
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2" >Delivery Charge: </Typography>
                    <Typography variant="subtitle1" ml={2}>{getFormatedCurrency(currentProduct.DeliveryCharge)}</Typography>
                  </div>
                {/* )} */}
                
                {/* {currentProduct.DeliveryDays && ( */}
                  <div className="div-infocontainer">
                    <Typography variant="subtitle2" >Delivery Days: </Typography>
                    <Typography variant="subtitle1" ml={2}>{currentProduct.DeliveryDays}</Typography>
                  </div>
                {/* )} */}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Card>
    </div>
  );
}