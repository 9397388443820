import { CALL_API } from '../api';

export const FETCHVENDOR_REQUEST = "FETCHCUSTOMER_REQUEST";
export const FETCHVENDOR_SUCCESS = "FETCHCUSTOMER_SUCCESS";
export const FETCHVENDOR_FAILURE = "FETCHCUSTOMER_FAILURE";

export const DELETEVENDOR_REQUEST = "DELETEVENDOR_REQUEST";
export const DELETEVENDOR_SUCCESS = "DELETEVENDOR_SUCCESS";
export const DELETEVENDOR_FAILURE = "DELETEVENDOR_FAILURE";

// export const fetchVendors = () => ({
//     [CALL_API]: {
//         types: [FETCHVENDOR_REQUEST, FETCHVENDOR_SUCCESS, FETCHVENDOR_FAILURE],
//         method: 'GET',
//         endpoint: 'vendor/getvendorlist'
//     }
// });

export const fetchVendors = (body) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
          types: [FETCHVENDOR_REQUEST, FETCHVENDOR_SUCCESS, FETCHVENDOR_FAILURE],
          method: 'POST',
          body: body,
          endpoint: 'vendor/getvendorlist'
      }
    });
  };

export const deleteVendor = (id) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
          types: [DELETEVENDOR_REQUEST, DELETEVENDOR_SUCCESS, DELETEVENDOR_FAILURE],
          method: 'GET',
          endpoint: 'vendor/deletevendor/' + id
      }
    });
};