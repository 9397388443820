import { 
    ALL_QUOT_REQUEST, ALL_QUOT_SUCCESS, ALL_QUOT_FAILURE,
    CUST_QUOTDETAIL_REQUEST, CUST_QUOTDETAIL_SUCCESS, CUST_QUOTDETAIL_FAILURE,
    ADD_QUOTATION_COMMENT_REQUEST, ADD_QUOTATION_COMMENT_SUCCESS, ADD_QUOTATION_COMMENT_FAILURE,
    DELETE_COMMENT_REQUEST, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAILURE,
    UPDATE_QUOTATION_REQUEST,UPDATE_QUOTATION_SUCCESS,UPDATE_QUOTATION_FAILURE,
    QUOTATION_STATUS_REQUEST, QUOTATION_STATUS_SUCCESS, QUOTATION_STATUS_FAILURE,
    DELETE_QUOTATION_ITEM_REQUEST, DELETE_QUOTATION_ITEM_SUCCESS, DELETE_QUOTATION_ITEM_FAILURE,
    DELETE_QUOTATION_REQUEST, DELETE_QUOTATION_SUCCESS, DELETE_QUOTATION_FAILURE,
    CONVERT_ORDER_REQUEST, CONVERT_ORDER_SUCCESS, CONVERT_ORDER_FAILURE,

    ALL_ORDER_REQUEST, ALL_ORDER_SUCCESS, ALL_ORDER_FAILURE,
    ORDER_DETAIL_REQUEST, ORDER_DETAIL_SUCCESS, ORDER_DETAIL_FAILURE,
    ORDER_STATUS_REQUEST, ORDER_STATUS_SUCCESS, ORDER_STATUS_FAILURE,
    UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE,
    DELETE_ORDER_REQUEST, DELETE_ORDER_SUCCESS, DELETE_ORDER_FAILURE,
    ORDER_ITEM_DETAIL_REQUEST, ORDER_ITEM_DETAIL_SUCCESS, ORDER_ITEM_DETAIL_FAILURE,
    ORDER_ITEM_STATUS_REQUEST, ORDER_ITEM_STATUS_SUCCESS, ORDER_ITEM_STATUS_FAILURE,
    DELETE_ORDER_ITEM_REQUEST, DELETE_ORDER_ITEM_SUCCESS, DELETE_ORDER_ITEM_FAILURE,
    ADD_ORDER_ITEM_REQUEST, ADD_ORDER_ITEM_SUCCESS, ADD_ORDER_ITEM_FAILURE,
    SUB_ORDERITEM_DETAIL_REQUEST, SUB_ORDERITEM_DETAIL_SUCCESS, SUB_ORDERITEM_DETAIL_FAILURE,
    UPDATE_ORDER_ITEM_REQUEST, UPDATE_ORDER_ITEM_SUCCESS, UPDATE_ORDER_ITEM_FAILURE,

    PAYMENT_METHOD_REQUEST, PAYMENT_METHOD_SUCCESS, PAYMENT_METHOD_FAILURE
} from '../actions/quotation';

export default (
    state = {
        error: undefined,
        isAllQuotFetching: false,
        isQuotDetailFetching: false,
        isQuotCommentAdding: false,
        isCommentDeliting: false,
        isQuotationUpdating: false,
        isQuotStatusFetching: false,
        isQuotItemDeleting: false,
        isQuotationDeleting: false,
        isAllOrderFetching: false,
        isQuotConvertingIntoOrder: false,
        isOrderDetailFetching: false,
        isOrderItemDetailFetching: false,
        isOrderStatusFetching: false,
        isOrderITemStatusFetching: false,
        isOrderUpdating: false,
        isOrderDeleting: false,
        isOrderItemDeleting: false,
        isOrderAdding: false,
        isSubOrderItemDetailFetching: false,
        isOrderItemUpdating: false,
        isPaymentMethodFetching:false,
        allQuotation: [],
        quotDetail: [],
        quotationStatus: [],
        allOrders: [],
        orderDetail: [],
        orderItemDetail: [],
        orderStatus: [],
        orderItemStatus: [],
        subOrderItemDetail: [],
        paymentMethods: []
    },
    action
) => {
    switch (action.type) {
        /* All Quotation Fetching */
        case ALL_QUOT_REQUEST:
            return{
                ...state,
                isAllQuotFetching: true,
            };
        case ALL_QUOT_SUCCESS:
            return{
                ...state,
                isAllQuotFetching: false,
                allQuotation: action.response,
                error: undefined
            };
        case ALL_QUOT_FAILURE:
            return{
                ...state,
                isAllQuotFetching: false,
                error: action.error
            };

        /* Quotation Detail */
        case CUST_QUOTDETAIL_REQUEST:
            return{
                ...state,
                isQuotDetailFetching: true,
            };
        case CUST_QUOTDETAIL_SUCCESS:
            return{
                ...state,
                isQuotDetailFetching: false,
                quotDetail: action.response,
                error: undefined
            };
        case CUST_QUOTDETAIL_FAILURE:
            return{
                ...state,
                isQuotDetailFetching: false,
                error: action.error
            };

        /* Add Quotation Comment */
        case ADD_QUOTATION_COMMENT_REQUEST:
            return{
                ...state,
                isQuotCommentAdding: true
            };
        case ADD_QUOTATION_COMMENT_SUCCESS:
            return {
                ...state,
                isQuotCommentAdding: false,
                error: undefined
            };
        case ADD_QUOTATION_COMMENT_FAILURE:
            return {
                ...state,
                isQuotCommentAdding: false,
                error: action.error
            };


        /* Delete Quotation Comment */
        case DELETE_COMMENT_REQUEST:
            return{
                ...state,
                isCommentDeliting: true,
            };
        case DELETE_COMMENT_SUCCESS:
            return{
                ...state,
                isCommentDeliting: false,
                error: undefined
            };
        case DELETE_COMMENT_FAILURE:
            return{
                ...state,
                isCommentDeliting: false,
                error: action.error
            };

        /* Update Quotation */
        case UPDATE_QUOTATION_REQUEST:
            return{
                ...state,
                isQuotationUpdating: true
            };
        case UPDATE_QUOTATION_SUCCESS:
            return {
                ...state,
                isQuotationUpdating: false,
                error: undefined
            };
        case UPDATE_QUOTATION_FAILURE:
            return {
                ...state,
                isQuotationUpdating: false,
                error: action.error
            };

        /* Quotatioin Status */
        case QUOTATION_STATUS_REQUEST:
            return{
                ...state,
                isQuotStatusFetching: true,
            };
        case QUOTATION_STATUS_SUCCESS:
            return{
                ...state,
                isQuotStatusFetching: false,
                quotationStatus: action.response,
                error: undefined
            };
        case QUOTATION_STATUS_FAILURE:
            return{
                ...state,
                isQuotStatusFetching: false,
                error: action.error
            };

        /* Quotation Item Delete */
        case DELETE_QUOTATION_ITEM_REQUEST:
            return{
                ...state,
                isQuotItemDeleting: true,
            };
        case DELETE_QUOTATION_ITEM_SUCCESS:
            // console.log("delete item sucess");
            // console.log(action.response);
            return{
                ...state,
                isQuotItemDeleting: false,
                error: undefined
            };
        case DELETE_QUOTATION_ITEM_FAILURE:
            return{
                ...state,
                isQuotItemDeleting: false,
                error: action.error
            };

        /* Delete Quotation */
        case DELETE_QUOTATION_REQUEST:
            return{
                ...state,
                isQuotationDeleting: true,
            };
        case DELETE_QUOTATION_SUCCESS:
            return{
                ...state,
                isQuotationDeleting: false,
                error: undefined
            };
        case DELETE_QUOTATION_FAILURE:
            return{
                ...state,
                isQuotationDeleting: false,
                error: action.error
            };

        /* All Orders Fetching */
        case ALL_ORDER_REQUEST:
            return{
                ...state,
                isAllOrderFetching: true,
            };
        case ALL_ORDER_SUCCESS:
            return{
                ...state,
                isAllOrderFetching: false,
                allOrders: action.response,
                error: undefined
            };
        case ALL_ORDER_FAILURE:
            return{
                ...state,
                isAllOrderFetching: false,
                error: action.error
            };

        /* Convert Into Order */
        case CONVERT_ORDER_REQUEST:
            return{
                ...state,
                isQuotConvertingIntoOrder: true,
            };
        case CONVERT_ORDER_SUCCESS:
            return{
                ...state,
                isQuotConvertingIntoOrder: false,
                error: undefined
            };
        case CONVERT_ORDER_FAILURE:
            return{
                ...state,
                isQuotConvertingIntoOrder: false,
                error: action.error
            };

        /* Order Detail */
        case ORDER_DETAIL_REQUEST:
            return{
                ...state,
                isOrderDetailFetching: true,
            };
        case ORDER_DETAIL_SUCCESS:
            return{
                ...state,
                isOrderDetailFetching: false,
                orderDetail: action.response,
                error: undefined
            };
        case ORDER_DETAIL_FAILURE:
            return{
                ...state,
                isOrderDetailFetching: false,
                error: action.error
            };
        
        /* Order Item Detail */
        case ORDER_ITEM_DETAIL_REQUEST:
            return{
                ...state,
                isOrderItemDetailFetching: true,
            };
        case ORDER_ITEM_DETAIL_SUCCESS:
            return{
                ...state,
                isOrderItemDetailFetching: false,
                orderItemDetail: action.response,
                error: undefined
            };
        case ORDER_ITEM_DETAIL_FAILURE:
            return{
                ...state,
                isOrderItemDetailFetching: false,
                error: action.error
            };

        /* Order Status */
        case ORDER_STATUS_REQUEST:
            return{
                ...state,
                isOrderStatusFetching: true,
            };
        case ORDER_STATUS_SUCCESS:
            return{
                ...state,
                isOrderStatusFetching: false,
                orderStatus: action.response,
                error: undefined
            };
        case ORDER_STATUS_FAILURE:
            return{
                ...state,
                isOrderStatusFetching: false,
                error: action.error
            };

        /* Update Order */
        case UPDATE_ORDER_REQUEST:
            return{
                ...state,
                isOrderUpdating: true
            };
        case UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                isOrderUpdating: false,
                error: undefined
            };
        case UPDATE_ORDER_FAILURE:
            return {
                ...state,
                isOrderUpdating: false,
                error: action.error
            };

        /* Delete Order */
        case DELETE_ORDER_REQUEST:
            return{
                ...state,
                isOrderDeleting: true,
            };
        case DELETE_ORDER_SUCCESS:         
            return{
                ...state,
                isOrderDeleting: false,
                error: undefined
            };
        case DELETE_ORDER_FAILURE:
            return{
                ...state,
                isOrderDeleting: false,
                error: action.error
            };


        /* Order Item Status */
        case ORDER_ITEM_STATUS_REQUEST:
            return{
                ...state,
                isOrderITemStatusFetching: true,
            };
        case ORDER_ITEM_STATUS_SUCCESS:
            return{
                ...state,
                isOrderITemStatusFetching: false,
                orderItemStatus: action.response,
                error: undefined
            };
        case ORDER_ITEM_STATUS_FAILURE:
            return{
                ...state,
                isOrderITemStatusFetching: false,
                error: action.error
            };

        /* Delete Order Item*/
        case DELETE_ORDER_ITEM_REQUEST:
            return{
                ...state,
                isOrderItemDeleting: true,
            };
        case DELETE_ORDER_ITEM_SUCCESS:         
            return{
                ...state,
                isOrderItemDeleting: false,
                error: undefined
            };
        case DELETE_ORDER_ITEM_FAILURE:
            return{
                ...state,
                isOrderItemDeleting: false,
                error: action.error
            };

        /* Adding into Order */ 
        case ADD_ORDER_ITEM_REQUEST:
            return{
                ...state,
                isOrderAdding: true,
            };
        case ADD_ORDER_ITEM_SUCCESS:
            return{
                ...state,
                isOrderAdding: false,
                error: undefined
            };
        case ADD_ORDER_ITEM_FAILURE:
            return{
                ...state,
                isOrderAdding: false,
                error: action.error
            };

        /* Sub Order Item Detail */
        case SUB_ORDERITEM_DETAIL_REQUEST:
            return{
                ...state,
                isSubOrderItemDetailFetching: true,
            };
        case SUB_ORDERITEM_DETAIL_SUCCESS:
            return{
                ...state,
                isSubOrderItemDetailFetching: false,
                subOrderItemDetail: action.response,
                error: undefined
            };
        case SUB_ORDERITEM_DETAIL_FAILURE:
            return{
                ...state,
                isSubOrderItemDetailFetching: false,
                error: action.error
            };

        /* Order Item Update */
        case UPDATE_ORDER_ITEM_REQUEST:
            return{
                ...state,
                isOrderItemUpdating: true
            };
        case UPDATE_ORDER_ITEM_SUCCESS:
            return {
                ...state,
                isOrderItemUpdating: false,
                error: undefined
            };
        case UPDATE_ORDER_ITEM_FAILURE:
            return {
                ...state,
                isOrderItemUpdating: false,
                error: action.error
            };

        /* Payment Methods */
        case PAYMENT_METHOD_REQUEST:
            return{
                ...state,
                isPaymentMethodFetching: true,
            };
        case PAYMENT_METHOD_SUCCESS:
            return{
                ...state,
                isPaymentMethodFetching: false,
                paymentMethods: action.response,
                error: undefined
            };
        case PAYMENT_METHOD_FAILURE:
            return{
                ...state,
                isPaymentMethodFetching: false,
                error: action.error
            };

    default:
        return state;
    }
}
          
        