import { 
    /* Delivery Challan */
    ALL_DCHALLAN_LIST_REQUEST, ALL_DCHALLAN_LIST_SUCCESS, ALL_DCHALLAN_LIST_FAILURE,
    ODRITEM_LISTFOR_DCHALLAN_REQUEST, ODRITEM_LISTFOR_DCHALLAN_SUCCESS, ODRITEM_LISTFOR_DCHALLAN_FAILURE,
    LISTOUT_DCHALLAN_REQUEST, LISTOUT_DCHALLAN_SUCCESS, LISTOUT_DCHALLAN_FAILURE,
    DCHALLAN_DETAIL_REQUEST, DCHALLAN_DETAIL_SUCCESS, DCHALLAN_DETAIL_FAILURE,
    GENERATE_DCHALLAN_REQUEST, GENERATE_DCHALLAN_SUCCESS, GENERATE_DCHALLAN_FAILURE,

    /* Delivery Request */
    DELIVERY_REQUEST_LIST_REQUEST, DELIVERY_REQUEST_LIST_SUCCESS, DELIVERY_REQUEST_LIST_FAILURE,
    DELIVERY_REQUEST_DETAIL_REQUEST, DELIVERY_REQUEST_DETAIL_SUCCESS, DELIVERY_REQUEST_DETAIL_FAILURE,
    DELIVERY_REQUEST_ITEM_DETAIL_REQUEST, DELIVERY_REQUEST_ITEM_DETAIL_SUCCESS, DELIVERY_REQUEST_ITEM_DETAIL_FAILURE,
    UPDATE_DELIVERY_REQUEST_STATUS_REQUEST, UPDATE_DELIVERY_REQUEST_STATUS_SUCCESS, UPDATE_DELIVERY_REQUEST_STATUS_FAILURE,
    UPDATE_DELIVERY_REQUEST_ITEM_STATUS_REQUEST, UPDATE_DELIVERY_REQUEST_ITEM_STATUS_SUCCESS, UPDATE_DELIVERY_REQUEST_ITEM_STATUS_FAILURE,
    DELIVERY_REQUEST_STATUS_REQUEST, DELIVERY_REQUEST_STATUS_SUCCESS, DELIVERY_REQUEST_STATUS_FAILURE

} from '../actions/deliveryChallan';

export default (
    state = {
        error: undefined,
        isAllDChallanFetching: false,
        isOdrItemListFetching: false,
        isOrderItemListFetched : false,
        isDChallanListingOut: false,
        isDChallanDetailFetching: false,
        isDeliveryChallanGenerating: false,
        allDChallan: [],
        orderItemList: [],
        dChallanDetail: [],

        /* Delivery Request */
        isDeliveryReqListFetching : false,
        deliveryRequestList: [],
        isDeliveryReqDetailFetching : false,
        deliveryRequestDetail: [],
        isDeliveryReqItemDetailFetching : false,
        deliveryRequestItemDetail: [],
        isDeliveryReqStatusUpdating: false,
        isDeliveryReqItemStatusUpdating: false,
        isDeliveryRequestStatusFetching: false,
        DeliveryRequestStatus:[]
      
    },
    action
) => {
    switch (action.type) {

        /* .................................Delivery Challan.............................. */

        /* All Delivery Challan list */
        case ALL_DCHALLAN_LIST_REQUEST:
            return{
                ...state,
                isAllDChallanFetching: true,
            };
        case ALL_DCHALLAN_LIST_SUCCESS:
            return{
                ...state,
                isAllDChallanFetching: false,
                allDChallan: action.response,
                error: undefined
            };
        case ALL_DCHALLAN_LIST_FAILURE:
            return{
                ...state,
                isAllDChallanFetching: false,
                error: action.error
            };

        /* Order Item List for Delivery Challan */
        case ODRITEM_LISTFOR_DCHALLAN_REQUEST:
            return{
                ...state,
                isOdrItemListFetching: true,
            };
        case ODRITEM_LISTFOR_DCHALLAN_SUCCESS:
            return{
                ...state,
                isOdrItemListFetching: false,
                isOrderItemListFetched: true,
                orderItemList: action.response,
                error: undefined
            };
        case ODRITEM_LISTFOR_DCHALLAN_FAILURE:
            return{
                ...state,
                isOdrItemListFetching: false,
                error: action.error
            };
    
        /* List out Delivery Challan */
        case LISTOUT_DCHALLAN_REQUEST:
            return{
                ...state,
                isDChallanListingOut: true,
            };
        case LISTOUT_DCHALLAN_SUCCESS:
            return{
                ...state,
                isDChallanListingOut: false,
                error: undefined
            };
        case LISTOUT_DCHALLAN_FAILURE:
            return{
                ...state,
                isDChallanListingOut: false,
                error: action.error
            };
    
        /* Delivery Challan Detail */
        case DCHALLAN_DETAIL_REQUEST:
            return{
                ...state,
                isDChallanDetailFetching: true,
            };
        case DCHALLAN_DETAIL_SUCCESS:
            return{
                ...state,
                isDChallanDetailFetching: false,
                dChallanDetail: action.response,
                error: undefined
            };
        case DCHALLAN_DETAIL_FAILURE:
            return{
                ...state,
                isDChallanDetailFetching: false,
                error: action.error
            };
            
        /* Delivery Challan Generate */
        case GENERATE_DCHALLAN_REQUEST:
            return{
                ...state,
                isDeliveryChallanGenerating: true,
            };
        case GENERATE_DCHALLAN_SUCCESS:
            return{
                ...state,
                isDeliveryChallanGenerating: false,
                error: undefined
            };
        case GENERATE_DCHALLAN_FAILURE:
            return{
                ...state,
                isDeliveryChallanGenerating: false,
                error: action.error
            };

        /* .................................Delivery Request.......................................... */
        
        /* Delivery Request List */
        case DELIVERY_REQUEST_LIST_REQUEST:
            return{
                ...state,
                isDeliveryReqListFetching: true,
            };
        case DELIVERY_REQUEST_LIST_SUCCESS:
            return{
                ...state,
                isDeliveryReqListFetching: false,
                deliveryRequestList: action.response,
                error: undefined
            };
        case DELIVERY_REQUEST_LIST_FAILURE:
            return{
                ...state,
                isDeliveryReqListFetching: false,
                error: action.error
            };

        /* Delivery Request Detail */
        case DELIVERY_REQUEST_DETAIL_REQUEST:
            return{
                ...state,
                isDeliveryReqDetailFetching: true,
            };
        case DELIVERY_REQUEST_DETAIL_SUCCESS:
            return{
                ...state,
                isDeliveryReqDetailFetching: false,
                deliveryRequestDetail: action.response,
                error: undefined
            };
        case DELIVERY_REQUEST_DETAIL_FAILURE:
            return{
                ...state,
                isDeliveryReqDetailFetching: false,
                error: action.error
            };

        /* Delivery Request Item Detail */
        case DELIVERY_REQUEST_ITEM_DETAIL_REQUEST:
            return{
                ...state,
                isDeliveryReqItemDetailFetching: true,
            };
        case DELIVERY_REQUEST_ITEM_DETAIL_SUCCESS:
            return{
                ...state,
                isDeliveryReqItemDetailFetching: false,
                deliveryRequestItemDetail: action.response,
                error: undefined
            };
        case DELIVERY_REQUEST_ITEM_DETAIL_FAILURE:
            return{
                ...state,
                isDeliveryReqItemDetailFetching: false,
                error: action.error
            };

        /* Delivery Request Status Update */
        case UPDATE_DELIVERY_REQUEST_STATUS_REQUEST:
            return{
                ...state,
                isDeliveryReqStatusUpdating: true
            };
        case UPDATE_DELIVERY_REQUEST_STATUS_SUCCESS:
            return {
                ...state,
                isDeliveryReqStatusUpdating: false,
                error: undefined
            };
        case UPDATE_DELIVERY_REQUEST_STATUS_FAILURE:
            return {
                ...state,
                isDeliveryReqStatusUpdating: false,
                error: action.error
            };

        /* Delivery Request Item Status Update */
        case UPDATE_DELIVERY_REQUEST_ITEM_STATUS_REQUEST:
            return{
                ...state,
                isDeliveryReqItemStatusUpdating: true
            };
        case UPDATE_DELIVERY_REQUEST_ITEM_STATUS_SUCCESS:
            return {
                ...state,
                isDeliveryReqItemStatusUpdating: false,
                error: undefined
            };
        case UPDATE_DELIVERY_REQUEST_ITEM_STATUS_FAILURE:
            return {
                ...state,
                isDeliveryReqItemStatusUpdating: false,
                error: action.error
            };

        /* Delivery Request Status */
        case DELIVERY_REQUEST_STATUS_REQUEST:
            return{
                ...state,
                isDeliveryRequestStatusFetching: true,
            };
        case DELIVERY_REQUEST_STATUS_SUCCESS:
            return{
                ...state,
                isDeliveryRequestStatusFetching: false,
                DeliveryRequestStatus: action.response,
                error: undefined
            };
        case DELIVERY_REQUEST_STATUS_FAILURE:
            return{
                ...state,
                isDeliveryRequestStatusFetching: false,
                error: action.error
            };

        default:
            return state;
        }
    }