import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { logout } from '../store/actions/session';

export default function LogoutButton(props) {
  const dispatch = useDispatch();
  return (
    <Button variant="inherit" onClick={() => dispatch(logout())}>Logout</Button>
  );
}