import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { deleteProduct, fetchProducts } from '../store/actions/product';
import { useNavigate } from 'react-router-dom';

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ProductsPage(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteProductId, setdeleteProductId] = useState("");
  const products = useSelector(state => (state.product.products || []));
  const isPRFetching = useSelector(state => (state.product.isPRFetching ));

  const mount = () => {
    dispatch(fetchProducts({}));
  }
  // eslint-disable-next-line
  useEffect(mount, []);
  
  /* const handleViewClick = (id) => () => {
    navigate("/viewProduct", { state: { productId: id }});
  }; */

  const handleEditClick = (id) => {
    navigate("/editProduct", { state: { productId: id }} );
  };

  const handleDeleteClick = (id) => {
    dispatch(deleteProduct(id)).then(r => dispatch(fetchProducts({})));
    setdialog(false);
  };

  const columns = [
    { field: 'id', headerName: 'Id', align: "left", flex:0.3 },
    { field: 'name', headerName: 'Name', align: "left", flex:1 },
    { field: 'brandname', headerName: 'Brand Name', align: "left", flex:1 },
    { field: 'listprice', headerName: 'List Price', align: "left", flex:0.5 },
    { field: 'originalprice', headerName: 'Original Price', align: "left", flex:0.5 },
    { field: 'producttypename', headerName: 'ProductType Name', align: "left", flex:1 },
    { field: 'size', headerName: 'Size', align: "left", flex:0.4},
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.4,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          // <Tooltip title="View" >
          //   <GridActionsCellItem
          //     icon={<VisibilityOutlinedIcon />}
          //     label="View"
          //     onClick={handleViewClick(id)}
          //     color="inherit"
          //   />
          // </Tooltip>,
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  setdeleteProductId(id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"1" }} onClose={() => setdialog(false)} >
              <DialogTitle >
                {"Delete Product"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  Are you sure you wish to delete this Product ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteProductId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>,
        ];
      }
    }
  ];

  const rows = products.map(p => {
    return ({ 
      id: p.Id, 
      name: p.Name, 
      brandname: p.BrandName, 
      listprice: p.ListPrice, 
      originalprice: p.OriginalPrice, 
      producttypename: p.ProductTypeName, 
      size: p.Size,
    })
  });

  const handleCellClick = (params) => {
    if(params.field !== "actions"){
      navigate("/viewProduct", { state: { productId: params.id }});
    }
  };

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Products" />
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addProduct") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Product</Typography>
          </Button>
        </div>
        <Divider />
        {products && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={ isPRFetching ? true : false}
              autoHeight={18}
              pageSize={pageSize}
              onCellClick={handleCellClick}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}
                
      </Card>
    </div>
  );
}