import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteBrand, fetchBrands } from '../store/actions/services'; 

import { useNavigate } from 'react-router-dom';

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
/* import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'; */
import Tooltip from '@mui/material/Tooltip';


export default function BrandsPage(props) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteBrandId, setdeleteBrandId] = useState("");
  const brands = useSelector(state => (state.services.brands || []));
  const isBRDFetching = useSelector(state => (state.services.isBRDFetching ));
  
  const mount = () => {
    dispatch(fetchBrands());
  }
  // eslint-disable-next-line
  useEffect(mount, []);

  /* const handleViewClick = (id) => () => {
    navigate("/viewBrand", { state: { brandId: id }});
  }; */

  const handleEditClick = (id) => {
    var fltr_brand = Object.assign({}, ...brands.filter(b => { if(b.Id === id) return b; }));
    navigate("/editBrand", { state: { brand: fltr_brand }} );
  };

  const handleDeleteClick = (id) => {
    //console.log("clicked id to delete");
    //console.log(id);
    dispatch(deleteBrand(id)).then(r => dispatch(fetchBrands()));
    setdialog(false);
  };

  const columns = [
    { field: 'id', headerName: 'Id', align: "left", flex:0.5  },
    { field: 'image', headerName: 'Image', align: "left", flex:1, 
     renderCell: (params)=>{
      return (
        <div>
          <img src={params.row.image} alt='BrandImg' width="42" height="42" style={{ borderRadius: "50%", objectFit:"contain"}} />
        </div>
      )}
    },
    { field: 'name', headerName: 'Name', align: "left", flex:1 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  setdeleteBrandId(id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"0.5" }} onClose={() => setdialog(false)} > 
              <DialogTitle >
                {"Delete Brand"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  Are you sure you wish to delete this Brand ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteBrandId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>,
        ];
      }
    }
  ];

  const rows = brands.map(b => {
    return ({ 
      id: b.Id,
      image: b.Image, 
      name: b.Name, 
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Brands" />
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addBrand") }}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Brand</Typography>
          </Button>
        </div>
        
        <Divider />
        
        {brands && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={ isBRDFetching ? true : false }
              autoHeight={18}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}
        
      </Card>
    </div>
  );
}