import { CALL_API } from '../api';

export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_FAILURE = "PRODUCTS_FAILURE";

export const PRODUCT_DETAIL_REQUEST = "PRODUCT_DETAIL_REQUEST";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";
export const PRODUCT_DETAIL_FAILURE = "PRODUCT_DETAIL_FAILURE";

export const ADDPRODUCT_REQUEST = "ADDPRODUCT_REQUEST";
export const ADDPRODUCT_SUCCESS = "ADDPRODUCT_SUCCESS";
export const ADDPRODUCT_FAILURE = "ADDPRODUCT_FAILURE";

export const UPRODUCT_REQUEST = "UPRODUCT_REQUEST";
export const UPRODUCT_SUCCESS = "UPRODUCT_SUCCESS";
export const UPRODUCT_FAILURE = "UPRODUCT_FAILURE";

export const DELBANIMG_REQUEST = "DELBANIMG_REQUEST";
export const DELBANIMG_SUCCESS = "DELBANIMG_SUCCESS";
export const DELBANIMG_FAILURE = "DELBANIMG_FAILURE";

export const DELSMLIMG_REQUEST = "DELSMLIMG_REQUEST";
export const DELSMLIMG_SUCCESS = "DELSMLIMG_SUCCESS";
export const DELSMLIMG_FAILURE = "DELSMLIMG_FAILURE";

export const DELPRODUCT_REQUEST = "DELPRODUCT_REQUEST";
export const DELPRODUCT_SUCCESS = "DELPRODUCT_SUCCESS";
export const DELPRODUCT_FAILURE = "DELPRODUCT_FAILURE";

export const CHNGPRODTPRICE_REQUEST = "CHNGPRODTPRICE_REQUEST";
export const CHNGPRODTPRICE_SUCCESS = "CHNGPRODTPRICE_SUCCESS";
export const CHNGPRODTPRICE_FAILURE = "CHNGPRODTPRICE_FAILURE";

export const PRODUCTPRICE_REQUEST = "PRODUCTPRICE_REQUEST"; 
export const PRODUCTPRICE_SUCCESS = "PRODUCTPRICE_SUCCESS";
export const PRODUCTPRICE_FAILURE = "PRODUCTPRICE_FAILURE";

export const ADDPRODTPRICE_REQUEST = "ADDPRODTPRICE_REQUEST"; 
export const ADDPRODTPRICE_SUCCESS = "ADDPRODTPRICE_SUCCESS";
export const ADDPRODTPRICE_FAILURE = "ADDPRODTPRICE_FAILURE";

export const UPDTPRODUCTPRICE_REQUEST = "UPDTPRODUCTPRICE_REQUEST"; 
export const UPDTPRODUCTPRICE_SUCCESS = "UPDTPRODUCTPRICE_SUCCESS";
export const UPDTPRODUCTPRICE_FAILURE = "UPDTPRODUCTPRICE_FAILURE";

export const DELPRODUCTPRICE_REQUEST = "DELPRODUCTPRICE_REQUEST"; 
export const DELPRODUCTPRICE_SUCCESS = "DELPRODUCTPRICE_SUCCESS";
export const DELPRODUCTPRICE_FAILURE = "DELPRODUCTPRICE_FAILURE";

/* export const fetchProducts = (body) => (dispatch, getState) => {
  return dispatch({
    type: PRODUCTS_SUCCESS,
  });
}; */

export const fetchProducts = (body) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
          types: [PRODUCTS_REQUEST, PRODUCTS_SUCCESS, PRODUCTS_FAILURE],
          method: 'POST',
          body: body,
          endpoint: 'search/product'
      }
    });
};

// export const fetchProductDetail = (id) => ({
//   [CALL_API]: {
//       types: [PRODUCT_DETAIL_REQUEST, PRODUCT_DETAIL_SUCCESS, PRODUCT_DETAIL_FAILURE],
//       method: 'GET',
//       endpoint: 'product/getproductdetail/' + id
//   }
// });

export const fetchProductDetail = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [PRODUCT_DETAIL_REQUEST, PRODUCT_DETAIL_SUCCESS, PRODUCT_DETAIL_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'product/getproductdetail'
    }
  });
};

export const addProduct = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADDPRODUCT_REQUEST, ADDPRODUCT_SUCCESS, ADDPRODUCT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'product/add'
    }
  });
};

export const updateProduct = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPRODUCT_REQUEST, UPRODUCT_SUCCESS, UPRODUCT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'product/update'
    }
  });
};

export const deleteBannerImage = ( productId, bannerImgId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELBANIMG_REQUEST, DELBANIMG_SUCCESS, DELBANIMG_FAILURE],
        method: 'GET',
        endpoint: 'product/deletebannerimage/'+ productId + '/' + bannerImgId
    }
  });
};

export const deleteSmallImage = (productId) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELSMLIMG_REQUEST, DELSMLIMG_SUCCESS, DELSMLIMG_FAILURE],
        method: 'GET',
        endpoint: 'product/deletesmallimage/' + productId
    }
  });
};

export const deleteProduct = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELPRODUCT_REQUEST, DELPRODUCT_SUCCESS, DELPRODUCT_FAILURE],
        method: 'GET',
        endpoint: 'product/delete/' + id
    }
  });
};

export const fetchProductPrice = () => ({
  [CALL_API]: {
      types: [PRODUCTPRICE_REQUEST, PRODUCTPRICE_SUCCESS, PRODUCTPRICE_FAILURE],
      method: 'GET',
      endpoint: 'product/getproductprice'
}
});

export const addProductPrice = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [ADDPRODTPRICE_REQUEST,ADDPRODTPRICE_SUCCESS,ADDPRODTPRICE_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'product/addproductprice'
    }
  });
};

export const updateProductPrice = (body) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [UPDTPRODUCTPRICE_REQUEST,UPDTPRODUCTPRICE_SUCCESS,UPDTPRODUCTPRICE_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'product/updateproductprice'
    }
  });
};

export const deleteProductPrice = (id) => (dispatch, getState) => {
  return dispatch({
    [CALL_API]: {
        types: [DELPRODUCTPRICE_REQUEST, DELPRODUCTPRICE_SUCCESS, DELPRODUCTPRICE_FAILURE],
        method: 'GET',
        endpoint: 'product/deleteproductprice/' + id
    }
  });
};

