import React ,{ useState, useEffect, } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RupeeSymbol } from '../components/util';
import { fetchServiceLocations, GetCityList, GetStateList, fetchPostalCode } from '../store/actions/addresses';
import { fetchProducts, updateProductPrice } from '../store/actions/product';

import "../css/addproductpage.css";

export default function EditProductPrice(props) {

  const [values, setValues] = useState({
    Id: '',
    ProductId: '',
    ServiceLocationId: '',
    CityId:'',
    StateId:'',
    OriginalPrice: '',
    ListPrice:'', 
    DisplayPrice:'',
    Postcode:'',
    DeliveryCharge:''
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(null);

  const isPRFetching = useSelector(state => (state.product.isPRFetching || false));
  const products = useSelector(state => (state.product.products || []));

  const isSERLOCFetching = useSelector(state => (state.addresses.isSERLOCFetching || false));
  const serviceLocations = useSelector(state => (state.addresses.serviceLocations || []));

  const isCityListFatching = useSelector(state => (state.addresses.isCityListFatching || false));
  const CityList = useSelector(state => (state.addresses.CityList || []));

  const isStateListFatching = useSelector(state => (state.addresses.isStateListFatching || false));
  const StateList = useSelector(state => (state.addresses.StateList || []));

  const isPostalCodelistFetching = useSelector(state => (state.addresses.isPostalCodelistFetching || false));
  const PostalCodeList = useSelector(state => (state.addresses.PostalCodeList || []));
  
  const mount = () => {
    dispatch(fetchProducts({}));
    dispatch(fetchPostalCode({}));
    dispatch(fetchServiceLocations());
    dispatch(GetCityList());
    dispatch(GetStateList());
  }
  // eslint-disable-next-line
  useEffect(mount, []);



  const handlechangedropdown = (selected, state) => {
    let pscode = [];  
    if(state === "PostalCodeList" && selected !== null){
      {selected.map((p, i) => {    
        return (
          pscode.push(p.value)
        )
      })}
      setValues({ ...values, [state]: [...pscode]});

    }
    setValues({ ...values, [state]: selected !== null ? selected.value : '' });
    console.log("drop down clicked ", selected);
    setErrorMsg(null);
  }

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const location = useLocation();

  useEffect( () =>{
    console.log("product prices", location.state.productPrice);
    if(location.state && location.state.productPrice){
      setValues({
        Id: (location.state.productPrice && location.state.productPrice.Id) || "",
        ProductId: (location.state.productPrice && location.state.productPrice.ProductId) || "",
        ServiceLocationId: (location.state.productPrice && location.state.productPrice.ServiceLocationId) || "",
        CityId: (location.state.productPrice && location.state.productPrice.CityId) || "",
        StateId: (location.state.productPrice && location.state.productPrice.StateId) || "",
        OriginalPrice: (location.state.productPrice && location.state.productPrice.OriginalPrice) || "",
        ListPrice: (location.state.productPrice && location.state.productPrice.ListPrice) || "",
        DisplayPrice: (location.state.productPrice && location.state.productPrice.DisplayPrice) || "",
        Postcode: (location.state.productPrice && location.state.productPrice.Postcode) || [],
        DeliveryCharge: (location.state.productPrice && location.state.productPrice.DeliveryCharge) || "",

      });
    }
  }, [location]);

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";    
    
    if (values.ProductId === ""){
      isInputValid = false;
      errorMsg = "Please select Product.";
    } else if (values.OriginalPrice === ""){
      isInputValid = false;
      errorMsg = "Please enter Original price.";
    } else if (values.ListPrice === ""){
      isInputValid = false;
      errorMsg = "Please enter List price.";
    } /* else if (values.DisplayPrice === ""){
      isInputValid = false;
      errorMsg = "Please enter Display price.";
    } */ else if (parseInt(values.ListPrice) > parseInt(values.OriginalPrice)){
      isInputValid = false;
      errorMsg = "List Price should not be greater than Original Price."
    }
    setErrorMsg(errorMsg);
    return isInputValid;
  }

  const handleSubmit = () => {
    if(isInputValid()){

      var updatedProductPrice = {   
        Id: values.Id,
        ProductId: values.ProductId,
        OriginalPrice: values.OriginalPrice,
        ListPrice: values.ListPrice,
        DisplayPrice: values.DisplayPrice,
        DeliveryCharge: values.DeliveryCharge
      };
      
      dispatch(updateProductPrice(updatedProductPrice)).then(r => dispatch(fetchProducts({})));
      navigate("/productsPrice");

      setValues({Product: '', ServiceLoc: '', OriginalPrice: '', ListPrice: '', DisplayPrice: '', CityId: '', StateId: '', PostcodeList: [] });
    }   
  }


  const productsArray = products.map((p, i) => {
    return ({
      key: i,
      label: p.BrandName + " - " + p.Name + " (" + p.Size + ") ",
      value: p.Id
    })
  });

  const PostalCodesArray = PostalCodeList.map((p, i) => {
    return ({
      key: i,
      label: p.Postcode,
      value: p.Postcode
    })
  });

  const CitiesArray = CityList.map((c, i) => {
    return ({
      key: i,
      label: c.Name,
      value: c.Id
    })
  });

  const StateArray = StateList.map((s, i) => {
    return ({
      key: i,
      label: s.Name,
      value: s.Id
    })
  });

  const ServiceLocArray = serviceLocations.map((sl, i) => {
    var Area = (sl.Area) ? sl.Area + ", " : "";
    var City = (sl.City) ? sl.City + ", " : "";
    // var State = (sl.State) ? sl.State : "";
    var listitem = Area + City
    return ({
      
      key: i,
      label: listitem,
      value: sl.Id
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Edit Product Price" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/productsPrice") }}>Cancel</Button>
          </div>
        </div>
        
        <Divider />

        <CardContent>
          <div>
            <Card>
              <CardContent sx={{p: 5}}>
                <Grid container spacing={3}>

                <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select Product"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        value = {productsArray.filter(p => p.value === values.ProductId)}
                        isLoading={isPRFetching}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "ProductId")}
                        options={productsArray}
                      />
                    </FormControl>
                  </Grid>


                  <Grid item xs={6} >
                    <TextField required
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      fullWidth
                      label="Delivery charges"
                      type="number"
                      value={values.DeliveryCharge}
                      name="DeliveryCharge"
                      onChange={handleChange}
                    />
                  </Grid>
              
                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select Service Location"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        value = {ServiceLocArray.filter(sl => sl.value === values.ServiceLocationId)}
                        isDisabled={true}
                        isLoading={isSERLOCFetching}
                        isClearable={true}
                        isSearchable={true}
                        // onChange={(selected) => handlechangedropdown(selected, "ServiceLocId")}
                        options={ServiceLocArray}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} >
                    <TextField required
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      fullWidth 
                      label="Original Price" 
                      type="number" 
                      value={values.OriginalPrice} 
                      name="OriginalPrice" 
                      onChange={handleChange} 
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select Postal Code"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        isMulti
                        value = {PostalCodesArray.filter(p => p.value === values.Postcode)}
                        isLoading={isPostalCodelistFetching}
                        isDisabled={true}
                        isClearable={true}
                        isSearchable={true}
                        // onChange={(selected) => handlechangedropdown(selected, "PostalCodeList")}
                        options={PostalCodesArray}
                      />
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={6} >
                    <TextField required 
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      fullWidth 
                      label="List Price" 
                      type="number" 
                      value={values.ListPrice} 
                      name="ListPrice" 
                      onChange={handleChange} 
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select City"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        value = {CitiesArray.filter(c => c.value === values.CityId)}
                        isDisabled={true}
                        isLoading={ isCityListFatching}
                        isClearable={true}
                        isSearchable={true}
                        // onChange={(selected) => handlechangedropdown(selected, "CityId")}
                        options={CitiesArray}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} >
                    <TextField required
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      fullWidth
                      label="Display Price"
                      type="number"
                      value={values.DisplayPrice}
                      name="DisplayPrice"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select State"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        value = {StateArray.filter(s => s.value === values.StateId)}
                        isDisabled={true}
                        isLoading={isStateListFatching}
                        isClearable={true}
                        isSearchable={true}
                        // onChange={(selected) => handlechangedropdown(selected, "StateId")}
                        options={StateArray}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              
              <Divider />
              
              <div style={{textAlign: "center", padding: 16}}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                <Button variant='contained' color='secondary' onClick={handleSubmit} >Update</Button>
              </div>
            </Card>
          </div>
        </CardContent>        
      </Card>
    </div>

  );
}