import { CALL_API } from '../api';

/* Transporters List */
export const ALL_TRANSPORTERS_LIST_REQUEST = "ALL_TRANSPORTERS_LIST_REQUEST";
export const ALL_TRANSPORTERS_LIST_SUCCESS = "ALL_TRANSPORTERS_LIST_SUCCESS";
export const ALL_TRANSPORTERS_LIST_FAILURE = "ALL_TRANSPORTERS_LIST_FAILURE";

/* Drivers List */
export const DRIVERS_LIST_REQUEST = "DRIVERS_LIST_REQUEST";
export const DRIVERS_LIST_SUCCESS = "DRIVERS_LIST_SUCCESS";
export const DRIVERS_LIST_FAILURE = "DRIVERS_LIST_FAILURE";

/* Vehicles List */
export const VEHICLE_LIST_REQUEST = "VEHICLE_LIST_REQUEST";
export const VEHICLE_LIST_SUCCESS = "VEHICLE_LIST_SUCCESS";
export const VEHICLE_LIST_FAILURE = "VEHICLE_LIST_FAILURE";



/* Transporters List */
export const fetchAllTransporters = (invoiceId) => (dispatch, getState) => {
    return dispatch({
        [CALL_API]: {
        types: [ALL_TRANSPORTERS_LIST_REQUEST, ALL_TRANSPORTERS_LIST_SUCCESS, ALL_TRANSPORTERS_LIST_FAILURE],
        method: 'GET',
        endpoint: 'transporter/gettransporterlist'
        }
    });
  };

/* Drivers list */
  export const fetchDrivers = (body) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [DRIVERS_LIST_REQUEST, DRIVERS_LIST_SUCCESS, DRIVERS_LIST_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'transporter/getdriverlist'
      }
    });
  };

  /* Vehicles list */
  export const fetchVehicles = (body) => (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: [VEHICLE_LIST_REQUEST, VEHICLE_LIST_SUCCESS, VEHICLE_LIST_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'transporter/getvehiclelist'
      }
    });
  };