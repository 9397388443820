import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { useNavigate, useLocation } from 'react-router-dom';

import { fetchCategories, updateCategory, deleteCategoryImage } from '../store/actions/services';
import { fetchServiceLocations } from '../store/actions/addresses';



export default function EditCategoryPage(props) {

  const [values, setValues] = useState({
    Id: '',
    Categoryimg: '',
    Name: '', 
    isService: false,
    Description: '',
    ParentCatId: '',
    ServiceLocId: [],
    Iconimg: '',
    img:''
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const [dialogOpen, setdialog] = useState(false);
  const categories = useSelector(state => (state.services.categories || []));
  const serviceLocations = useSelector(state => (state.addresses.serviceLocations || []));
  const isCATUpdating = useSelector(state => (state.services.isCATUpdating));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //This is to set maxHeight for Select(dropdown) element's Menu
  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };

  const mount = () => {
    dispatch(fetchCategories());
    dispatch(fetchServiceLocations());
  }
  
  useLayoutEffect(mount, []);

  useEffect( () =>{
    
    if(location.state && location.state.category){
      setValues({
        Id: (location.state.category && location.state.category.Id) || "",
        Categoryimg: (location.state.category && location.state.category.Image) || "",
        Name: (location.state.category && location.state.category.Name) || "", 
        isService: (location.state.category && location.state.category.IsService ) || false,
        Description: (location.state.category && location.state.category.Description) || "", 
        ParentCatId: (location.state.category && location.state.category.ParentCategoryId) || "", 
        ServiceLocId: (location.state.category && location.state.category.ServiceLocationId) || [] ,
        Iconimg: (location.state.category && location.state.category.IconImage) || "",
        /* img: (location.state.category && location.state.category.Image) || "", */
      });
    }
  }, [location]);

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  }
  
  const hiddenFileInput = React.useRef(null);

  const handleUploadImgBtn = () => {
    //method to Link FileInput element
    hiddenFileInput.current.click();
  };

  const handleAddImg = (event) =>{
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setValues({ ...values, Categoryimg: e.target.result });
    }  
  };

  const handleSelectChange = (fieldname) => (event) => {
    if(fieldname === "ServiceLocId"){
      setValues({ ...values, ServiceLocId: event.target.value });
    }
    if(fieldname === "ParentCatId"){
      setValues({ ...values, ParentCatId: event.target.value });
    }
  };

  const isInputValid = () => {
    // console.log("iddd", location.state.category.Id);

    let isInputValid = true;
    let errorMsg = "";

    if(values.Categoryimg === "" || values.Name === "" /* || values.ServiceLocId.length === 0 */ || 
      values.ParentCatId === "" && values.isService === false || values.ParentCatId === location.state.category.Id ){
      isInputValid = false;
      errorMsg = "Please fill all the * marked fields.";
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  };

  const handleDeleteimg = (catImgId) => {
    dispatch(deleteCategoryImage(catImgId)).then(r => dispatch(fetchCategories()));
    setValues({ ...values, Categoryimg: "" });
    setdialog(false);
  };

  const handleSubmit = () => {
    
    if(isInputValid()){
      var category;
      if( document.getElementById("CategoryImgFInput").files.length == 0 ){
        category = {
          Id: values.Id,
          Name: values.Name,
          ParentCategoryId: values.ParentCatId,
          Description: values.Description,
          ServiceLocationId: values.ServiceLocId,
          IsService: values.isService,
          IconImage: values.Iconimg,
          /* Image: values.img */
        };
  
        //  console.log("category");
        //  console.log(category);
        dispatch(updateCategory(category)).then(r => dispatch(fetchCategories()));
        navigate("/categories");
      }
      else{
        category = {
          Id: values.Id,
          ImageObj: values.Categoryimg,
          Name: values.Name,
          ParentCategoryId: values.ParentCatId,
          Description: values.Description,
          ServiceLocationId: values.ServiceLocId,
          IsService: values.isService,
          IconImage: values.Iconimg,
          /* Image: values.img */
        };
  
        // console.log("category");
        // console.log(category);
        dispatch(updateCategory(category)).then(r => dispatch(fetchCategories()));
        navigate("/categories");
      }
    }
  }

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Edit Category" />
          <div
            style={{ display:"flex", 
              flexDirection:"row", 
              alignItems:"center", 
              justifyContent:"center", 
              marginLeft:"auto", 
              marginRight:18 
            }} >
            <Button variant='contained' color="error" onClick={() => { navigate("/categories") }}>Cancel</Button>
          </div>
        </div>
        
        <Divider />
        
        <CardContent sx={{p: 5}}>
          
          <Grid container spacing={3}>
            <Grid container xs={12} mb={6} alignItems="center" justifyContent="center" flexDirection="column" >
              <Typography variant="h5" >Category Image*</Typography>
              <Typography variant="subtitle1" >Image should be (512 x 317px)</Typography>
              
              {/* <img src={values.Categoryimg} alt={'Category'} width={175} height={175}  /> */}
              {(values.Categoryimg) && (
                <div style={{ position:"relative", marginTop: "12px"}}>
                  <IconButton
                    style={{ position:"absolute", right: 0 }}
                    sx={{ backgroundColor:"white" }}
                    onClick={() => setdialog(true)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                  <img src={values.Categoryimg} width={175} height={175} />
                </div>
              )}
              
              {/* This is masked Button for hiddenFileInput */}
              {(!values.Categoryimg) && (
                <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn()}>
                  Upload Image
                </Button>
              )}
              <input ref={hiddenFileInput} style={{display:'none'}} id="CategoryImgFInput" className="choosefile-input" type="file" onChange={handleAddImg} />
              
              {/* {(values.Categoryimg) && (
                <Button style={{ marginTop: 14 }} variant='contained' color="error" onClick={() => setdialog(true) }>Delete Image</Button>
              )} */}
              <Dialog open={dialogOpen} onClose={() => setdialog(false)} >
                <DialogTitle >
                  {"Delete Image"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText >
                    Are you sure you wish to delete this Category Image ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleDeleteimg(values.Id)}>Yes</Button>
                  <Button onClick={() => setdialog(false)} autoFocus>
                    No
                  </Button>
                </DialogActions>
              </Dialog>

            </Grid>

            <Grid item xs={6} >
              <TextField required fullWidth label="Name" value={values.Name} name="Name" onChange={handleChange} />
            </Grid>
            
            <Grid item xs={6} >
              <FormControl /* required */ sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">Parent Category</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={values.ParentCatId}
                  label="Parent Category"
                  onChange={handleSelectChange("ParentCatId")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={""}> <em>None</em> </MenuItem>
                  {categories.map((c, i) => {
                    return <MenuItem  value={c.Id} key={i}>{c.Name}</MenuItem >
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} >
              <FormControl required sx={{ width: "100%" }}>
                <InputLabel id="demo-controlled-open-select-label">Service Locations</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={values.ServiceLocId}
                  label="Service Locations"
                  defaultValue=""
                  onChange={handleSelectChange("ServiceLocId")}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        var fltr_sl = Object.assign({}, ...serviceLocations.filter(sl => {if(value == sl.Id) return sl;}));
                        var Area = (fltr_sl.Area) ? fltr_sl.Area+", " : "";
                        var City = (fltr_sl.City) ? fltr_sl.City+", " : "";
                        var State = (fltr_sl.State) ? fltr_sl.State : "";
                        var listitem = Area + City + State; 
                        return (<Chip key={value} label={listitem} />);
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {serviceLocations.map((sl, i) => {
                    var Area = (sl.Area) ? sl.Area+", " : "";
                    var City = (sl.City) ? sl.City+", " : "";
                    var State = (sl.State) ? sl.State : "";
                    var listitem = Area + City + State;
                    return (
                      <MenuItem value={sl.Id} key={i}>
                        <Checkbox checked={values.ServiceLocId.indexOf(sl.Id) > -1 } />
                        {/* <ListItemText primary={Area + City + State} /> */}
                        {listitem}
                      </MenuItem > 
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} >
            <FormGroup aria-label="position" row >
              <FormControlLabel control={<Checkbox defaultChecked={false} checked={values.isService} onChange={(e) => { setValues({...values, isService: e.target.checked}) }} />} 
                labelPlacement="start" 
                label="Provide Service" 
              />
            </FormGroup>
            </Grid>

            <Grid item xs={12} >
              <TextField fullWidth multiline minRows="3" label="Description" value={values.Description} name="Description" onChange={handleChange} />
            </Grid>
            
          </Grid>

        </CardContent>

        <Divider />
              
        <div style={{textAlign: "center", padding: 16}}>
          {(errorMsg && errorMsg !== "") && (
            <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
          )}
          {(isCATUpdating) ?
            <Button variant='contained' color='secondary' >Updating...</Button> :
            <Button variant='contained' color='secondary' onClick={handleSubmit}>Update</Button>
          }
        </div>        
      </Card>
    </div>
  );
}