import {
    PRTYPES_REQUEST, PRTYPES_SUCCESS, PRTYPES_FAILURE,
    BRANDS_REQUEST, BRANDS_SUCCESS, BRANDS_FAILURE,
    PRSIZES_REQUEST, PRSIZES_SUCCESS, PRSIZES_FAILURE,
    CAT_REQUEST, CAT_SUCCESS, CAT_FAILURE,
    ADDCAT_REQUEST, ADDCAT_SUCCESS, ADDCAT_FAILURE,
    UCAT_REQUEST, UCAT_SUCCESS, UCAT_FAILURE,
    DELCATIMG_REQUEST, DELCATIMG_SUCCESS, DELCATIMG_FAILURE,
    DELCAT_REQUEST, DELCAT_SUCCESS, DELCAT_FAILURE,
    ADDBRAND_REQUEST, ADDBRAND_SUCCESS, ADDBRAND_FAILURE,
    UBRAND_REQUEST, UBRAND_SUCCESS, UBRAND_FAILURE,
    DELBRIMG_REQUEST, DELBRIMG_SUCCESS, DELBRIMG_FAILURE,
    DELBRAND_REQUEST, DELBRAND_SUCCESS, DELBRAND_FAILURE,
    ADDPRDTTYPE_REQUEST, ADDPRDTTYPE_SUCCESS, ADDPRDTTYPE_FAILURE,
    UPRDTTYPE_REQUEST, UPRDTTYPE_SUCCESS, UPRDTTYPE_FAILURE,
    DELPRDTTYPEIMG_REQUEST, DELPRDTTYPEIMG_SUCCESS, DELPRDTTYPEIMG_FAILURE,
    DELPRDTTYPE_REQUEST, DELPRDTTYPE_SUCCESS, DELPRDTTYPE_FAILURE,
    /* Our Clients */
    OUR_CLIENT_LIST_REQUEST, OUR_CLIENT_LIST_SUCCESS, OUR_CLIENT_LIST_FAILURE,
    OUR_CLIENT_DETAIL_REQUEST, OUR_CLIENT_DETAIL_SUCCESS, OUR_CLIENT_DETAIL_FAILURE,
    ADD_OUR_CLIENT_REQUEST, ADD_OUR_CLIENT_SUCCESS, ADD_OUR_CLIENT_FAILURE,
    UPDATE_OUR_CLIENT_REQUEST, UPDATE_OUR_CLIENT_SUCCESS, UPDATE_OUR_CLIENT_FAILURE,
    DELETE_OUR_CLIENT_REQUEST, DELETE_OUR_CLIENT_SUCCESS, DELETE_OUR_CLIENT_FAILURE,
    DELETE_OUR_CLIENT_IMAGE_REQUEST, DELETE_OUR_CLIENT_IMAGE_SUCCESS, DELETE_OUR_CLIENT_IMAGE_FAILURE,
    /* Shop by Categories */
    SHOP_CATEGORY_LIST_REQUEST, SHOP_CATEGORY_LIST_SUCCESS, SHOP_CATEGORY_LIST_FAILURE,
    ADD_SHOP_BY_CAT_REQUEST, ADD_SHOP_BY_CAT_SUCCESS, ADD_SHOP_BY_CAT_FAILURE,
    UPDATE_SHOP_BY_CAT_REQUEST, UPDATE_SHOP_BY_CAT_SUCCESS, UPDATE_SHOP_BY_CAT_FAILURE,
    DELETE_SHOP_BY_CAT_REQUEST, DELETE_SHOP_BY_CAT_SUCCESS, DELETE_SHOP_BY_CAT_FAILURE,
    DELETE_SHOP_BY_CAT_IMAGE_REQUEST, DELETE_SHOP_BY_CAT_IMAGE_SUCCESS, DELETE_SHOP_BY_CAT_IMAGE_FAILURE,
    /* shop by brands */
    SHOP_BRAND_LIST_REQUEST, SHOP_BRAND_LIST_SUCCESS, SHOP_BRAND_LIST_FAILURE,
    ADD_SHOP_BY_BRAND_REQUEST, ADD_SHOP_BY_BRAND_SUCCESS, ADD_SHOP_BY_BRAND_FAILURE,
    UPDATE_SHOP_BY_BRAND_REQUEST, UPDATE_SHOP_BY_BRAND_SUCCESS, UPDATE_SHOP_BY_BRAND_FAILURE,
    DELETE_SHOP_BY_BRAND_REQUEST, DELETE_SHOP_BY_BRAND_SUCCESS, DELETE_SHOP_BY_BRAND_FAILURE,
    DELETE_SHOP_BY_BRAND_IMAGE_REQUEST, DELETE_SHOP_BY_BRAND_IMAGE_SUCCESS, DELETE_SHOP_BY_BRAND_IMAGE_FAILURE,
    /* Recommended Products */
    RECOMMENDED_PROD_LIST_REQUEST, RECOMMENDED_PROD_LIST_SUCCESS, RECOMMENDED_PROD_LIST_FAILURE,
    ADD_RECOMMENDED_PROD_REQUEST, ADD_RECOMMENDED_PROD_SUCCESS, ADD_RECOMMENDED_PROD_FAILURE,
    DELETE_RECOMMENDED_PROD_REQUEST, DELETE_RECOMMENDED_PROD_SUCCESS, DELETE_RECOMMENDED_PROD_FAILURE,
    DELETE_RECOMMENDED_PROD_IMAGE_REQUEST, DELETE_RECOMMENDED_PROD_IMAGE_SUCCESS, DELETE_RECOMMENDED_PROD_IMAGE_FAILURE





} from "../actions/services";

export default (
    state = {
        error: undefined,
        isPRTFetching: false,
        isBRDFetching: false,
        isPRSFetching: false,
        isCATFetching: false,
        isCATAdding: false,
        isCATUpdating: false,
        isSERLOCFetching: false,
        isCATIMGDeleting: false,
        isCATDeleting: false,
        isBRAdding: false,
        isBRUpdating: false,
        isBRIMGDeleting: false,
        isBRANDDeleting: false,
        isPRDTTYPEAdding: false,
        isPRDTTYPEUpdating: false,
        isPRDTTYPEIMGDeleting: false,
        isPRDTTYPEDeleting: false,
        //delCatImg: null,
        categories: [],
        productTypes: [],
        brands: [],
        productSizes: [],
        serviceLocations: [],

        /* Our Clients */
        ourClients: [],
        isOurClientListFetching: false,
        currentClient: [],
        isOurClientDetailFetching: false,
        isOurClientAdding: false,
        isOurClientAdded: false,
        isOurClientUpdating: false,
        isOurClientUpdated: false,
        isOurClientDeleting: false,
        isOurClientDeleted: false,
        isOurClientImgDeleting: false,
        isOurClientImgDeleted: false,

        /* Popular brands */
        isShopByBrandListFetching: false,
        shopByBrandList: [],
        isPopBrandAdding: false,
        isPopBrandAdded: false,
        isPopBrandUpdating: false,
        isPopBrandUpdated: false,
        isPopBrandDeleting: false,
        isPopBrandDeleted: false,
        isPopBrandImgDeleting: false,
        isPopBrandImgDeleted: false,

        /* Popular Categories */
        isShopByCatListFetching: false,
        shopByCatList: [],
        isPopCatAdding: false,
        isPopCatAdded: false,
        isPopCatUpdating: false,
        isPopCatUpdated: false,
        isPopCatDeleting: false,
        isPopCatDeleted: false,
        isPopCatImgDeleting: false,
        isPopCatImgDeleted: false,

        /* Recommended Products */
        isRecomProdListFetching: false,
        RecomProdList: [],
        isRecomProdAdding: false,
        isRecomProdAdded: false,
        isRecomProdDeleting: false,
        isRecomProdDeleted: false,
        isRecomProdImgDeleting: false,
        isRecomProdImgDeleted: false,

    },
    action
) => {
    switch (action.type) {
        case PRTYPES_REQUEST:
            return {
                ...state,
                isPRTFetching: true,
            };
        case PRTYPES_SUCCESS:
            return {
                ...state,
                isPRTFetching: false,
                productTypes: action.response,
                error: undefined,
            };
        case PRTYPES_FAILURE:
            return {
                ...state,
                isPRTFetching: false,
                error: action.error,
            };
        case BRANDS_REQUEST:
            return {
                ...state,
                isBRDFetching: true,
            };
        case BRANDS_SUCCESS:
            return {
                ...state,
                isBRDFetching: false,
                brands: action.response,
                error: undefined,
            };
        case BRANDS_FAILURE:
            return {
                ...state,
                isBRDFetching: false,
                error: action.error,
            };
        case PRSIZES_REQUEST:
            return {
                ...state,
                isPRSFetching: true,
            };
        case PRSIZES_SUCCESS:
            return {
                ...state,
                isPRSFetching: false,
                productSizes: action.response,
                error: undefined,
            };
        case PRSIZES_FAILURE:
            return {
                ...state,
                isPRSFetching: false,
                error: action.error,
            };
        case CAT_REQUEST:
            return {
                ...state,
                isCATFetching: true,
            };
        case CAT_SUCCESS:
            return {
                ...state,
                isCATFetching: false,
                categories: action.response,
                error: undefined,
            };
        case CAT_FAILURE:
            return {
                ...state,
                isCATFetching: false,
                error: action.error,
            };
        case ADDCAT_REQUEST:
            return {
                ...state,
                isCATAdding: true
            };
        case ADDCAT_SUCCESS:
            return {
                ...state,
                isCATAdding: false,
                error: undefined
            };
        case ADDCAT_FAILURE:
            return {
                ...state,
                isCATAdding: false,
                error: action.error
            };
        case UCAT_REQUEST:
            return {
                ...state,
                isCATUpdating: true
            };
        case UCAT_SUCCESS:
            return {
                ...state,
                isCATUpdating: false,
                error: undefined
            };
        case UCAT_FAILURE:
            return {
                ...state,
                isCATUpdating: false,
                error: action.error
            };
        case DELCATIMG_REQUEST:
            return {
                ...state,
                isCATIMGDeleting: true,
            };
        case DELCATIMG_SUCCESS:
            return {
                ...state,
                isCATIMGDeleting: false,
                error: undefined
            };
        case DELCATIMG_FAILURE:
            return {
                ...state,
                isCATIMGDeleting: false,
                error: action.error
            };
        case DELCAT_REQUEST:
            return {
                ...state,
                isCATDeleting: true
            };
        case DELCAT_SUCCESS:
            return {
                ...state,
                isCATDeleting: false,
                error: undefined
            };
        case DELCAT_FAILURE:
            return {
                ...state,
                isCATDeleting: false,
                error: action.error
            };
        case ADDBRAND_REQUEST:
            return {
                ...state,
                isBRAdding: true
            };
        case ADDBRAND_SUCCESS:
            return {
                ...state,
                isBRAdding: false,
                error: undefined
            };
        case ADDBRAND_FAILURE:
            return {
                ...state,
                isBRAdding: false,
                error: action.error
            };
        case UBRAND_REQUEST:
            return {
                ...state,
                isBRUpdating: true
            };
        case UBRAND_SUCCESS:
            return {
                ...state,
                isBRUpdating: false,
                error: undefined
            };
        case UBRAND_FAILURE:
            return {
                ...state,
                isBRUpdating: false,
                error: action.error
            };
        case DELBRIMG_REQUEST:
            return {
                ...state,
                isBRIMGDeleting: true
            };
        case DELBRIMG_SUCCESS:
            return {
                ...state,
                isBRIMGDeleting: false,
                error: undefined
            };
        case DELBRIMG_FAILURE:
            return {
                ...state,
                isBRIMGDeleting: false,
                error: action.error
            };
        case DELBRAND_REQUEST:
            return {
                ...state,
                isBRANDDeleting: true
            };
        case DELBRAND_SUCCESS:
            return {
                ...state,
                isBRANDDeleting: false,
                error: undefined
            };
        case DELBRAND_FAILURE:
            return {
                ...state,
                isBRANDDeleting: false,
                error: action.error
            };
        case ADDPRDTTYPE_REQUEST:
            return {
                ...state,
                isPRDTTYPEAdding: true
            };
        case ADDPRDTTYPE_SUCCESS:
            return {
                ...state,
                isPRDTTYPEAdding: false,
                error: undefined
            };
        case ADDPRDTTYPE_FAILURE:
            return {
                ...state,
                isPRDTTYPEAdding: false,
                error: action.error
            };
        case UPRDTTYPE_REQUEST:
            return {
                ...state,
                isPRDTTYPEUpdating: true
            };
        case UPRDTTYPE_SUCCESS:
            return {
                ...state,
                isPRDTTYPEUpdating: false,
                error: undefined
            };
        case UPRDTTYPE_FAILURE:
            return {
                ...state,
                isPRDTTYPEUpdating: false,
                error: action.error
            };
        case DELPRDTTYPEIMG_REQUEST:
            return {
                ...state,
                isPRDTTYPEIMGDeleting: true
            };
        case DELPRDTTYPEIMG_SUCCESS:
            return {
                ...state,
                isPRDTTYPEIMGDeleting: false,
                error: undefined
            };
        case DELPRDTTYPEIMG_FAILURE:
            return {
                ...state,
                isPRDTTYPEIMGDeleting: false,
                error: action.error
            };
        case DELPRDTTYPE_REQUEST:
            return {
                ...state,
                isPRDTTYPEDeleting: true
            };
        case DELPRDTTYPE_SUCCESS:
            return {
                ...state,
                isPRDTTYPEDeleting: false,
                error: undefined
            };
        case DELPRDTTYPE_FAILURE:
            return {
                ...state,
                isPRDTTYPEDeleting: false,
                error: action.error
            };

        /* Our Clients */
        case OUR_CLIENT_LIST_REQUEST:
            return {
                ...state,
                isOurClientListFetching: true,
                error: undefined,
            };
        case OUR_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                isOurClientListFetching: false,
                ourClients: action.response,
            };
        case OUR_CLIENT_LIST_FAILURE:
            return {
                ...state,
                isOurClientListFetching: false,
                error: action.error,
            };

        case OUR_CLIENT_DETAIL_REQUEST:
            return {
                ...state,
                isOurClientDetailFetching: true,
                error: undefined,
            };
        case OUR_CLIENT_DETAIL_SUCCESS:
            return {
                ...state,
                isOurClientDetailFetching: false,
                currentClient: action.response,
                error: undefined,
            };
        case OUR_CLIENT_DETAIL_FAILURE:
            return {
                ...state,
                isOurClientDetailFetching: false,
                error: action.error,
            };

        case ADD_OUR_CLIENT_REQUEST:
            return {
                ...state,
                isOurClientAdding: true,
                isOurClientAdded: false,
            };
        case ADD_OUR_CLIENT_SUCCESS:
            return {
                ...state,
                isOurClientAdding: false,
                isOurClientAdded: action.response,
                error: undefined,
            };
        case ADD_OUR_CLIENT_FAILURE:
            return {
                ...state,
                isOurClientAdding: false,
                isOurClientAdded: false,
                error: action.error,
            };

        case UPDATE_OUR_CLIENT_REQUEST:
            return {
                ...state,
                isOurClientUpdating: true,
                isOurClientUpdated: false,
            };
        case UPDATE_OUR_CLIENT_SUCCESS:
            return {
                ...state,
                isOurClientUpdating: false,
                isOurClientUpdated: action.response,
                error: undefined
            };
        case UPDATE_OUR_CLIENT_FAILURE:
            return {
                ...state,
                isOurClientUpdating: false,
                isOurClientUpdated: false,
                error: action.error
            };

        case DELETE_OUR_CLIENT_REQUEST:
            return {
                ...state,
                isOurClientDeleting: true,
                isOurClientDeleted: false,
            };
        case DELETE_OUR_CLIENT_SUCCESS:
            return {
                ...state,
                isOurClientDeleting: false,
                isOurClientDeleted: action.response,
                error: undefined
            };
        case DELETE_OUR_CLIENT_FAILURE:
            return {
                ...state,
                isOurClientDeleting: false,
                isOurClientDeleted: false,
                error: action.error
            };
        case DELETE_OUR_CLIENT_IMAGE_REQUEST:
            return {
                ...state,
                isOurClientImgDeleting: true,
                isOurClientImgDeleted: false,
            };
        case DELETE_OUR_CLIENT_IMAGE_SUCCESS:
            return {
                ...state,
                isOurClientImgDeleting: false,
                isOurClientImgDeleted: action.response,
                error: undefined
            };
        case DELETE_OUR_CLIENT_IMAGE_FAILURE:
            return {
                ...state,
                isOurClientImgDeleting: false,
                isOurClientImgDeleted: false,
                error: action.error
            }

        /* SHOP BY CATEGORY */
        case SHOP_CATEGORY_LIST_REQUEST:
            return {
                ...state,
                isShopByCatListFetching: true,
            };
        case SHOP_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                isShopByCatListFetching: false,
                shopByCatList: action.response,
                error: undefined
            };
        case SHOP_CATEGORY_LIST_FAILURE:
            return {
                ...state,
                isShopByCatListFetching: false,
                error: action.error
            };
        case ADD_SHOP_BY_CAT_REQUEST:
            return {
                ...state,
                isPopCatAdding: true,
                isPopBrandAdded: false,
            };
        case ADD_SHOP_BY_CAT_SUCCESS:
            return {
                ...state,
                isPopCatAdding: false,
                isPopBrandAdded: action.response,
                error: undefined,
            };
        case ADD_SHOP_BY_CAT_FAILURE:
            return {
                ...state,
                isPopCatAdding: false,
                isPopBrandAdded: false,
                error: action.error,
            };
        case UPDATE_SHOP_BY_CAT_REQUEST:
            return {
                ...state,
                isPopCatUpdating: true,
                isPopCatUpdated: false,
            };
        case UPDATE_SHOP_BY_CAT_SUCCESS:
            return {
                ...state,
                isPopCatUpdating: false,
                isPopCatUpdated: action.response,
                error: undefined
            };
        case UPDATE_SHOP_BY_CAT_FAILURE:
            return {
                ...state,
                isPopCatUpdating: false,
                isPopCatUpdated: false,
                error: action.error
            };
        case DELETE_SHOP_BY_CAT_REQUEST:
            return {
                ...state,
                isPopCatDeleting: true,
                isPopCatDeleted: false,
            };
        case DELETE_SHOP_BY_CAT_SUCCESS:
            return {
                ...state,
                isPopCatDeleting: false,
                isPopCatDeleted: action.response,
                error: undefined
            };
        case DELETE_SHOP_BY_CAT_FAILURE:
            return {
                ...state,
                isPopCatDeleting: false,
                isPopCatDeleted: false,
                error: action.error
            };
        case DELETE_SHOP_BY_CAT_IMAGE_REQUEST:
            return {
                ...state,
                isPopCatImgDeleting: true,
                isPopCatImgDeleted: false,
            };
        case DELETE_SHOP_BY_CAT_IMAGE_SUCCESS:
            return {
                ...state,
                isPopCatImgDeleting: false,
                isPopCatImgDeleted: action.response,
                error: undefined
            };
        case DELETE_SHOP_BY_CAT_IMAGE_FAILURE:
            return {
                ...state,
                isPopCatImgDeleting: false,
                isPopCatImgDeleted: false,
                error: action.error
            }

        /* SHOP BY Brands */
        case SHOP_BRAND_LIST_REQUEST:
            return {
                ...state,
                isShopByBrandListFetching: true,
            };
        case SHOP_BRAND_LIST_SUCCESS:
            return {
                ...state,
                isShopByBrandListFetching: false,
                shopByBrandList: action.response,
                error: undefined
            };
        case SHOP_BRAND_LIST_FAILURE:
            return {
                ...state,
                isShopByBrandListFetching: false,
                error: action.error
            };
        case ADD_SHOP_BY_BRAND_REQUEST:
            return {
                ...state,
                isPopCatAdding: true,
                isPopBrandAdded: false,
            };
        case ADD_SHOP_BY_BRAND_SUCCESS:
            return {
                ...state,
                isPopCatAdding: false,
                isPopBrandAdded: action.response,
                error: undefined,
            };
        case ADD_SHOP_BY_BRAND_FAILURE:
            return {
                ...state,
                isPopCatAdding: false,
                isPopBrandAdded: false,
                error: action.error,
            };
        case UPDATE_SHOP_BY_BRAND_REQUEST:
            return {
                ...state,
                isPopBrandUpdating: true,
                isPopBrandUpdated: false,
            };
        case UPDATE_SHOP_BY_BRAND_SUCCESS:
            return {
                ...state,
                isPopBrandUpdating: false,
                isPopBrandUpdated: action.response,
                error: undefined
            };
        case UPDATE_SHOP_BY_BRAND_FAILURE:
            return {
                ...state,
                isPopBrandUpdating: false,
                isPopBrandUpdated: false,
                error: action.error
            };
        case DELETE_SHOP_BY_BRAND_REQUEST:
            return {
                ...state,
                isPopBrandDeleting: true,
                isPopBrandDeleted: false,
            };
        case DELETE_SHOP_BY_BRAND_SUCCESS:
            return {
                ...state,
                isPopBrandDeleting: false,
                isPopBrandDeleted: action.response,
                error: undefined
            };
        case DELETE_SHOP_BY_BRAND_FAILURE:
            return {
                ...state,
                isPopBrandDeleting: false,
                isPopBrandDeleted: false,
                error: action.error
            };
        case DELETE_SHOP_BY_BRAND_IMAGE_REQUEST:
            return {
                ...state,
                isPopBrandImgDeleting: true,
                isPopBrandImgDeleted: false,
            };
        case DELETE_SHOP_BY_BRAND_IMAGE_SUCCESS:
            return {
                ...state,
                isPopBrandImgDeleting: false,
                isPopBrandImgDeleted: action.response,
                error: undefined
            };
        case DELETE_SHOP_BY_BRAND_IMAGE_FAILURE:
            return {
                ...state,
                isPopBrandImgDeleting: false,
                isPopBrandImgDeleted: false,
                error: action.error
            }

        /* Recommended Products */
        case RECOMMENDED_PROD_LIST_REQUEST:
            return {
                ...state,
                isRecomProdListFetching: true,
            };
        case RECOMMENDED_PROD_LIST_SUCCESS:
            return {
                ...state,
                isRecomProdListFetching: false,
                RecomProdList: action.response,
                error: undefined
            };
        case RECOMMENDED_PROD_LIST_FAILURE:
            return {
                ...state,
                isRecomProdListFetching: false,
                error: action.error
            };
        case ADD_RECOMMENDED_PROD_REQUEST:
            console.log("add request");
            return {
                ...state,
                isRecomProdAdding: true,
                isRecomProdAdded: false,
            };
        case ADD_RECOMMENDED_PROD_SUCCESS:
            console.log("add success", action.response);
            return {
                ...state,
                isRecomProdAdding: false,
                isRecomProdAdded: action.response,
                error: undefined,
            };
        case ADD_RECOMMENDED_PROD_FAILURE:
            console.log("add failure", action.response);
            return {
                ...state,
                isRecomProdAdding: false,
                isRecomProdAdded: false,
                error: action.error,
            };
        case DELETE_RECOMMENDED_PROD_REQUEST:
            return {
                ...state,
                isRecomProdDeleting: true,
                isRecomProdDeleted: false,
            };
        case DELETE_RECOMMENDED_PROD_SUCCESS:
            return {
                ...state,
                isRecomProdDeleting: false,
                isRecomProdDeleted: action.response,
                error: undefined
            };
        case DELETE_RECOMMENDED_PROD_FAILURE:
            return {
                ...state,
                isRecomProdDeleting: false,
                isRecomProdDeleted: false,
                error: action.error
            };
        case DELETE_RECOMMENDED_PROD_IMAGE_REQUEST:
            return {
                ...state,
                isRecomProdImgDeleting: true,
                isRecomProdImgDeleted: false,
            };
        case DELETE_RECOMMENDED_PROD_IMAGE_SUCCESS:
            return {
                ...state,
                isRecomProdImgDeleting: false,
                isRecomProdImgDeleted: action.response,
                error: undefined
            };
        case DELETE_RECOMMENDED_PROD_IMAGE_FAILURE:
            return {
                ...state,
                isRecomProdImgDeleting: false,
                isRecomProdImgDeleted: false,
                error: action.error
            }
        default:
            return state;
    }
};


